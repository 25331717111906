import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import LegForm from "./LegForm";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchLegsByAccount } from "../../services/legServices";
import { formatPhoneNumber } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { setLegsListData } from "../../redux/leg/legReducer";
import { GRID_CONSTANTS, PROVINCE_STATE } from "../../constants";
import moment from "moment";

function Legs() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const legState = useSelector((state) => state.leg);

  const toggleFormDisplay = (isEdit = false, legId = null) => {
    if (isFormHidden) {
      setSelectedLegId(legId);
    } else {
      setSelectedLegId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Order ID",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Leg ID",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Customer",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Pick",
      minWidth: 250,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Drop",
      minWidth: 250,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Freight",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Status",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [legList, setLegList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedLeg, setSelectedLeg] = useState({});
  const [selectedLegId, setSelectedLegId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setLegList(legState.data);
  }, [legState.data]);

  useEffect(() => {
    let data = [];
    _.each(legList, (leg, index) => {
      const pickupState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return provinceState.value === leg?.pickupState;
        }
      );

      const dropoffState = _.find(
        PROVINCE_STATE,
        (provinceState) => {
          return provinceState.value === leg?.dropoffState;
        }
      );

      data.push({
        "Order ID": leg.orderId,
        "Leg ID": leg.legId,
        Customer: "-",
        Pick: `${leg?.pickupAddress1}\n${leg?.pickupCity}, ${pickupState?.label}\n${leg?.pickupPostal}\n\n${moment(leg?.pickupDateTime).format('LL')}`,
        Drop: `${leg?.dropoffAddress1}\n${leg?.dropoffCity}, ${dropoffState?.label}\n${leg?.dropoffPostal}\n\n${moment(leg?.dropoffDateTime).format('LL')}`,
        Freight: "12,000 KGs",
        Status: "Unavailable"
      });
    });
    setRowData(data);
  }, [legList]);

  useEffect(() => {
    fillSelectedLeg(selectedLegId);
  }, [selectedLegId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getLegsData();
    dispatch(setLegsListData(response));
  };

  const getLegsData = async () => {
    return await fetchLegsByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedLeg = (id) => {
    const leg = _.find(legList, { legId: id });
    setSelectedLeg(leg);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  };

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Legs"
                : isFormEdit
                  ? "Edit Leg"
                  : "New Leg"
            }
            subtitle={"legs"}
            dataCount={0}
            filterData={filterData}
            hideButton={true}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`col-9 mb-2`}>
          <div
            className={`ag-theme-alpine content-section-container color-3-grid color-3-section p-0`}
          >
            <AgGridReact
              className="no-header"
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
            ></AgGridReact>
          </div>
        </div>
        <div className={`col-3 mb-2`}>
          <div
            className={`content-section-container color-3-grid color-3-section p-0`}
          >
            <LegForm
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              toggleFormDisplay={toggleFormDisplay}
              setIsFormDirty={setIsFormDirty}
              modal={modal}
              setModal={setModal}
              toggle={toggle}
              focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legs;
