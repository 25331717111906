import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTrailers = async () => {
  return get({ path: 'Trailer' });
}

export const fetchTrailersFiltered = async () => {
  return get({ path: `Trailer/filtered` });
}

export const addTrailer = async (payload, keepAlive = false) => {
  return post({ path: 'Trailer', body: payload, keepAlive: keepAlive });
}

export const updateTrailer = async (payload, keepAlive = false) => {
  return patch({ path: `Trailer/${payload.trailerId}`, body: payload, keepAlive: keepAlive });
}

export const fetchTrailersByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchTrailersFiltered(accountId) : await fetchTrailersFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchTrailersFiltered(accountId);
  }
  
  return await fetchTrailers();
}