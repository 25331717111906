import { get, post, patch,remove } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchOrders = async () => {
  return get({ path: 'Order' });
}

export const fetchOrdersFiltered = async () => {
  return get({ path: `Order/filtered` });
}

export const fetchOrdersByQuoteId = async (state) => {
  return get({ path: `Order/quote/${state.quoteId}` });
}

export const addOrder = async (payload) => {
  return post({ path: 'Order', body: payload });
}

export const updateOrder = async (payload) => {
  return patch({ path: `Order/update/${payload.orderId}`, body: payload });
}

export const deleteOrder = async (orderId) => {
  return remove({ path: `Order/${orderId}` });
}

export const fetchOrdersByAccount = async (userType, selectedAccountId, accountId) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchOrdersFiltered(accountId) : await fetchOrdersFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchOrdersFiltered(accountId);
  }
  
  return await fetchOrders();
}