import {
  AppstoreOutlined,
  DollarOutlined,
  FileTextOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import {
  BusinessCenterOutlined,
  DockOutlined,
  ImageOutlined,
  LibraryBooksOutlined,
  LocalShippingOutlined,
  PaidOutlined,
  PaymentOutlined,
  ReceiptLongOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

// import {
//   BadgeOutlined,
//   CreditCard,
//   DashboardOutlined,
//   DescriptionOutlined,
//   PaidOutlined,
//   PaymentsOutlined,
//   PeopleOutlineOutlined,
//   PhoneOutlined,
//   RequestQuoteOutlined,
//   SettingsOutlined,
//   SwitchAccountOutlined,
//   BusinessCenterOutlined,
//   PaymentOutlined,
//   LibraryBooksOutlined,
//   ReceiptLongOutlined,
//   ImageOutlined,
//   FolderSharedOutlined,
//   LocalShippingOutlined,
//   LocalOfferOutlined,
//   DockOutlined
// } from "@mui/icons-material";

/* Theme - also under _variable.scss under the Theme comment. When updating this, update that too. */
export const THEME_COLORS = {
  COLOR_PRIMARY: "#3366FD",
  COLOR_WHITE: "#FFFFFF",
  COLOR_1: "#01E093",
  COLOR_2: "#FFEB0B",
  COLOR_3: "#3366FD",
  COLOR_4: "#683ADB",
  COLOR_5: "#FE6658",
  COLOR_6: "#A5A5A5",
  COLOR_7: "#FF9900",
  COLOR_8: "#00C6E0",
  COLOR_GREEN_1: "#01E093",
  COLOR_RED_1: "#FE6658",
};

export const GRID_CONSTANTS = {
  ROW_HEIGHT: 50,
};

export const CHART_CONSTANTS = {
  LEGEND_FONT_SIZE: "14px",
  LEGEND_LINE_HEIGHT: "24px",
  LEGEND_TEXT_COLOR: "black",
};

export const USER_TYPES = {
  SUPER_ADMIN: 1,
  NTMS_USER: 2,
  ACCOUNT_ADMIN: 3,
  ACCOUNT_USER: 4,
};

export const TAX_TYPES = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "GST",
    value: 1,
  },
  {
    label: "PST",
    value: 2,
  },
  {
    label: "HST",
    value: 3,
  },
  {
    label: "GST/PST",
    value: 4,
  },
  {
    label: "Other",
    value: 5,
  },
];

export const EMPLOYEE_TYPE = [
  {
    label: "Admin",
    value: 1,
  },
  {
    label: "Driver",
    value: 2,
  },
  {
    label: "User",
    value: 3,
  },
];

export const EMPLOYEE_LEVEL = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
];

export const DRIVER_TYPE = [
  {
    label: "Company Driver",
    value: 1,
  },
  {
    label: "Contractor",
    value: 2,
  },
];

export const EMPLOYMENT_STATUS = [
  {
    label: "Employee",
    value: 1,
  },
  {
    label: "Carrier",
    value: 2,
  },
];

export const DEFAULT_PAY_TYPE = [
  {
    label: "Mileage",
    value: 1,
  },
  {
    label: "Hourly",
    value: 2,
  },
  {
    label: "Salary",
    value: 3,
  },
  {
    label: "Flat Rate",
    value: 4,
  },
  {
    label: "Percentage",
    value: 5,
  },
];

export const GENDER = [
  {
    label: "Male",
    value: 1,
  },
  {
    label: "Female",
    value: 2,
  },
  {
    label: "Not Disclosed",
    value: 3,
  },
];

export const MARITAL_STATUS = [
  {
    label: "Single",
    value: 1,
  },
  {
    label: "Married",
    value: 2,
  },
  {
    label: "Divorced",
    value: 3,
  },
  {
    label: "Separated",
    value: 4,
  },
  {
    label: "Not Disclosed",
    value: 5,
  },
];

export const PREFERRED_LANGUAGE = [
  {
    label: "English",
    value: 1,
  },
  {
    label: "French",
    value: 2,
  },
  {
    label: "Hindi",
    value: 3,
  },
  {
    label: "Punjabi",
    value: 4,
  },
  {
    label: "Spanish",
    value: 5,
  },
];

export const EMERGENCY_CONTACT_RELATIONSHIP = [
  {
    label: "Spouse/Partner",
    value: 1,
  },
  {
    label: "Parent",
    value: 2,
  },
  {
    label: "Adult Child",
    value: 3,
  },
  {
    label: "Trusted Friend",
    value: 4,
  },
  {
    label: "Other",
    value: 5,
  },
];

export const PAYMENT_TYPES = [
  {
    label: "On Credit",
    value: 0,
  },
  {
    label: "Credit Card",
    value: 1,
  },
  {
    label: "Cheque",
    value: 2,
  },
  {
    label: "Cash",
    value: 3,
  },
];

export const SUB_MENU_KEYS_ANTD = [
  "account",
  "dispatching",
  "finance",
  "fleets",
  "accounting",
  "team",
];

export const MENU_ITEMS_ANTD = [
  {
    key: "dashboard",
    icon: <AppstoreOutlined />,
    label: "Dashboard",
    className: "no-submenu-items",
  },
  {
    key: "account",
    icon: <UsergroupDeleteOutlined />,
    label: "Account",
    className: "color-1",
    children: [
      {
        key: "accounts",
        label: "Accounts",
        className: "color-1",
      },
      {
        key: "customers",
        label: "Customers",
        className: "color-1",
      },
      {
        key: "contacts",
        label: "Contacts",
        className: "color-1",
      },
    ],
  },
  {
    key: "dispatching",
    icon: <LocalShippingOutlined />,
    label: "Dispatching",
    className: "color-3",
    children: [
      {
        key: "orders",
        label: "Orders",
        className: "color-3",
      },
      {
        key: "quotes",
        label: "Quotes",
        className: "color-3",
      },
      {
        key: "legs",
        label: "Legs",
        className: "color-3",
      },
    ],
  },
  {
    key: "finance",
    icon: <FileTextOutlined />,
    label: "Finance",
    className: "color-4",
    children: [
      {
        key: "invoices",
        label: "Invoices",
        className: "color-4",
        disabled: true,
      },
      {
        key: "payments",
        label: "Payments",
        type: "group",
        children: [
          {
            key: "payments/invoice-payments",
            label: "Invoice Payments",
            className: "color-4",
            disabled: true,
          },
          {
            key: "payments/expense-payments",
            label: "Expense Payments",
            className: "color-4",
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    key: "fleets",
    icon: <LocalShippingOutlined />,
    label: "Fleets",
    className: "color-7",
    children: [
      {
        key: "trucks",
        label: "Trucks Management",
        type: "group",
        children: [
          {
            key: "trucks/trucks-assets",
            label: "Assets",
            className: "color-7",
          },
          {
            key: "trucks/trucks-maintenance",
            label: "Maintenance",
            className: "color-7",
          },
        ],
      },
      {
        key: "trailers",
        label: "Trailers Management",
        type: "group",
        children: [
          {
            key: "trailers/trailers-assets",
            label: "Assets",
            className: "color-7",
          },
          {
            key: "trailers/trailers-maintenance",
            label: "Maintenance",
            className: "color-7",
          },
        ],
      },
      {
        key: "terminals",
        label: "Additional Assets",
        type: "group",
        children: [
          {
            key: "terminals",
            label: "Terminals",
            className: "color-7",
          },
        ],
      },
    ],
  },
  {
    key: "accounting",
    icon: <DollarOutlined />,
    label: "Accounting",
    className: "color-4",
    children: [
      {
        key: "payroll-runs",
        label: "Payroll",
        className: "color-4",
      },
      {
        key: "expenses",
        label: "Expenses",
        className: "color-4",
      },
    ],
  },
  {
    key: "team",
    icon: <FileTextOutlined />,
    label: "Team",
    className: "color-8",
    children: [
      {
        key: "team/users",
        label: "Users",
        className: "color-8",
      },
      {
        key: "team/drivers",
        label: "Drivers",
        className: "color-8",
      },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "settings",
    icon: <SettingsOutlined />,
    label: "Settings",
    className: "no-submenu-items",
  },
];

export const SETTINGS_ITEMS = [
  {
    label: "Accounting",
    // icon: 'fa-solid fa-briefcase',
    icon: <BusinessCenterOutlined />,
    link: "/settings/accounting-settings",
    authorizationCheck: true,
    color: "color-1",
  },
  {
    label: "Orders",
    icon: <LocalShippingOutlined />,
    link: "/settings/orders-settings",
    authorizationCheck: false,
    color: "color-3",
  },
  {
    label: "Payroll",
    icon: <PaidOutlined />,
    link: "/settings/payroll-settings",
    authorizationCheck: true,
    color: "color-4",
  },
  {
    label: "Expense Categories",
    icon: <PaymentOutlined />,
    link: "/settings/expense-categories",
    authorizationCheck: false,
    color: "color-3",
  },
  {
    label: "Image",
    icon: <ImageOutlined />,
    link: "/settings/image-settings",
    authorizationCheck: false,
    color: "color-7",
  },
  {
    label: "GL Codes",
    icon: <LibraryBooksOutlined />,
    link: "/settings/gl-codes",
    authorizationCheck: true,
    color: "color-5",
  },
  {
    label: "Report Templates",
    icon: <ReceiptLongOutlined />,
    link: "/settings/report-templates",
    authorizationCheck: false,
    color: "color-7",
  },
  {
    label: "Accessorials",
    icon: <DockOutlined />,
    link: "/settings/accessorials",
    authorizationCheck: false,
    color: "color-6",
  },
];

export const PAY_FREQUENCY = [
  {
    label: "Weekly",
    value: 1,
  },
  {
    label: "Bi-weekly",
    value: 2,
  },
  {
    label: "Semi-monthly",
    value: 3,
  },
  {
    label: "Monthly",
    value: 4,
  },
];

export const VACATION_TYPE = [
  {
    label: "Percent",
    value: 0,
  },
  {
    label: "Days",
    value: 1,
  },
];

export const PAY_METHOD_TYPE = [
  {
    label: "Salary",
    value: 0,
  },
  {
    label: "Hours",
    value: 1,
  },
  {
    label: "Miles",
    value: 2,
  },
  {
    label: "Other",
    value: 3,
  },
  {
    label: 'Advance',
    value: 4
  },
];

export const CURRENCY_TYPE = [
  {
    label: "Canada",
    value: 1,
  },
  {
    label: "US",
    value: 2,
  },
];

export const COUNTRY = [
  {
    label: "Canada",
    value: 1,
  },
  {
    label: "US",
    value: 2,
  },
];

export const PROVINCE = [
  {
    label: "AB",
    value: 0,
  },
  {
    label: "BC",
    value: 1,
  },
  {
    label: "MB",
    value: 2,
  },
  {
    label: "NB",
    value: 3,
  },
  {
    label: "NL",
    value: 4,
  },
  {
    label: "NT",
    value: 5,
  },
  {
    label: "NS",
    value: 6,
  },
  {
    label: "NU",
    value: 7,
  },
  {
    label: "ON",
    value: 8,
  },
  {
    label: "PE",
    value: 9,
  },
  {
    label: "QC",
    value: 10,
  },
  {
    label: "SK",
    value: 11,
  },
  {
    label: "YT",
    value: 12,
  },
];

export const PROVINCE_STATE = [
  {
    label: "Alberta",
    value: 0,
    abbreviation: "AB",
  },
  {
    label: "British Columbia",
    value: 1,
    abbreviation: "BC",
  },
  {
    label: "Manitoba",
    value: 2,
    abbreviation: "MB",
  },
  {
    label: "New Brunswick",
    value: 3,
    abbreviation: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    value: 4,
    abbreviation: "NL",
  },
  {
    label: "Northwest Territories",
    value: 5,
    abbreviation: "NT",
  },
  {
    label: "Nova Scotia",
    value: 6,
    abbreviation: "NS",
  },
  {
    label: "Nunavut",
    value: 7,
    abbreviation: "NU",
  },
  {
    label: "Ontario",
    value: 8,
    abbreviation: "ON",
  },
  {
    label: "Prince Edward Island",
    value: 9,
    abbreviation: "PE",
  },
  {
    label: "Quebec",
    value: 10,
    abbreviation: "QC",
  },
  {
    label: "Saskatchewan",
    value: 11,
    abbreviation: "SK",
  },
  {
    label: "Yukon",
    value: 12,
    abbreviation: "YT",
  },
  // U.S. States
  {
    label: "Alabama",
    value: 13,
    abbreviation: "AL",
  },
  {
    label: "Alaska",
    value: 14,
    abbreviation: "AK",
  },
  {
    label: "Arizona",
    value: 15,
    abbreviation: "AZ",
  },
  {
    label: "Arkansas",
    value: 16,
    abbreviation: "AR",
  },
  {
    label: "California",
    value: 17,
    abbreviation: "CA",
  },
  {
    label: "Colorado",
    value: 18,
    abbreviation: "CO",
  },
  {
    label: "Connecticut",
    value: 19,
    abbreviation: "CT",
  },
  {
    label: "Delaware",
    value: 20,
    abbreviation: "DE",
  },
  {
    label: "Florida",
    value: 21,
    abbreviation: "FL",
  },
  {
    label: "Georgia",
    value: 22,
    abbreviation: "GA",
  },
  {
    label: "Hawaii",
    value: 23,
    abbreviation: "HI",
  },
  {
    label: "Idaho",
    value: 24,
    abbreviation: "ID",
  },
  {
    label: "Illinois",
    value: 25,
    abbreviation: "IL",
  },
  {
    label: "Indiana",
    value: 26,
    abbreviation: "IN",
  },
  {
    label: "Iowa",
    value: 27,
    abbreviation: "IA",
  },
  {
    label: "Kansas",
    value: 28,
    abbreviation: "KS",
  },
  {
    label: "Kentucky",
    value: 29,
    abbreviation: "KY",
  },
  {
    label: "Louisiana",
    value: 30,
    abbreviation: "LA",
  },
  {
    label: "Maine",
    value: 31,
    abbreviation: "ME",
  },
  {
    label: "Maryland",
    value: 32,
    abbreviation: "MD",
  },
  {
    label: "Massachusetts",
    value: 33,
    abbreviation: "MA",
  },
  {
    label: "Michigan",
    value: 34,
    abbreviation: "MI",
  },
  {
    label: "Minnesota",
    value: 35,
    abbreviation: "MN",
  },
  {
    label: "Mississippi",
    value: 36,
    abbreviation: "MS",
  },
  {
    label: "Missouri",
    value: 37,
    abbreviation: "MO",
  },
  {
    label: "Montana",
    value: 38,
    abbreviation: "MT",
  },
  {
    label: "Nebraska",
    value: 39,
    abbreviation: "NE",
  },
  {
    label: "Nevada",
    value: 40,
    abbreviation: "NV",
  },
  {
    label: "New Hampshire",
    value: 41,
    abbreviation: "NH",
  },
  {
    label: "New Jersey",
    value: 42,
    abbreviation: "NJ",
  },
  {
    label: "New Mexico",
    value: 43,
    abbreviation: "NM",
  },
  {
    label: "New York",
    value: 44,
    abbreviation: "NY",
  },
  {
    label: "North Carolina",
    value: 45,
    abbreviation: "NC",
  },
  {
    label: "North Dakota",
    value: 46,
    abbreviation: "ND",
  },
  {
    label: "Ohio",
    value: 47,
    abbreviation: "OH",
  },
  {
    label: "Oklahoma",
    value: 48,
    abbreviation: "OK",
  },
  {
    label: "Oregon",
    value: 49,
    abbreviation: "OR",
  },
  {
    label: "Pennsylvania",
    value: 50,
    abbreviation: "PA",
  },
  {
    label: "Rhode Island",
    value: 51,
    abbreviation: "RI",
  },
  {
    label: "South Carolina",
    value: 52,
    abbreviation: "SC",
  },
  {
    label: "South Dakota",
    value: 53,
    abbreviation: "SD",
  },
  {
    label: "Tennessee",
    value: 54,
    abbreviation: "TN",
  },
  {
    label: "Texas",
    value: 55,
    abbreviation: "TX",
  },
  {
    label: "Utah",
    value: 56,
    abbreviation: "UT",
  },
  {
    label: "Vermont",
    value: 57,
    abbreviation: "VT",
  },
  {
    label: "Virginia",
    value: 58,
    abbreviation: "VA",
  },
  {
    label: "Washington",
    value: 59,
    abbreviation: "WA",
  },
  {
    label: "West Virginia",
    value: 60,
    abbreviation: "WV",
  },
  {
    label: "Wisconsin",
    value: 61,
    abbreviation: "WI",
  },
  {
    label: "Wyoming",
    value: 62,
    abbreviation: "WY",
  },
  // District of Columbia (Washington, D.C.)
  {
    label: "District of Columbia",
    value: 63,
    abbreviation: "DC",
  },
  // U.S. Territories
  {
    label: "American Samoa",
    value: 64,
    abbreviation: "AS",
  },
  {
    label: "Guam",
    value: 65,
    abbreviation: "GU",
  },
  {
    label: "Northern Mariana Islands",
    value: 66,
    abbreviation: "MP",
  },
  {
    label: "Puerto Rico",
    value: 67,
    abbreviation: "PR",
  },
  {
    label: "U.S. Virgin Islands",
    value: 68,
    abbreviation: "VI",
  },
];


export const TD1_PROVINCE = [
  {
    label: "Claim Code 0 (No claim amount)",
    value: 0,
  },
  {
    label: "Claim Code 1 (Basic claim)",
    value: 1,
  },
  {
    label: "Claim Code 2",
    value: 2,
  },
  {
    label: "Claim Code 3",
    value: 3,
  },
  {
    label: "Claim Code 4",
    value: 4,
  },
  {
    label: "Claim Code 5",
    value: 5,
  },
  {
    label: "Claim Code 6",
    value: 6,
  },
  {
    label: "Claim Code 7",
    value: 7,
  },
  {
    label: "Claim Code 8",
    value: 8,
  },
  {
    label: "Claim Code 9",
    value: 9,
  },
  {
    label: "Claim Code 10",
    value: 10,
  },
];

export const TD1_PROVINCE_NB = [
  {
    label: "Claim Code 0 (No claim amount)",
    value: 0,
  },
  {
    label: "Claim Code 1 (Basic claim)",
    value: 1,
  },
  {
    label: "Claim Code 2",
    value: 2,
  },
  {
    label: "Claim Code 3",
    value: 3,
  },
  {
    label: "Claim Code 4",
    value: 4,
  },
  {
    label: "Claim Code 5",
    value: 5,
  },
  {
    label: "Claim Code 6",
    value: 6,
  },
  {
    label: "Claim Code 7",
    value: 7,
  },
  {
    label: "Claim Code 8",
    value: 8,
  },
];

export const TD1_PROVINCE_QC = [
  {
    label: "Claim Code 0",
    value: 0,
  },
];

export const TD2_FEDERAL = [
  {
    label: "Claim Code 0 (No claim amount)",
    value: 0,
  },
  {
    label: "Claim Code 1 (Basic claim)",
    value: 1,
  },
  {
    label: "Claim Code 2",
    value: 2,
  },
  {
    label: "Claim Code 3",
    value: 3,
  },
  {
    label: "Claim Code 4",
    value: 4,
  },
  {
    label: "Claim Code 5",
    value: 5,
  },
  {
    label: "Claim Code 6",
    value: 6,
  },
  {
    label: "Claim Code 7",
    value: 7,
  },
  {
    label: "Claim Code 8",
    value: 8,
  },
  {
    label: "Claim Code 9",
    value: 9,
  },
  {
    label: "Claim Code 10",
    value: 10,
  },
];

export const REPORT_TYPES = [
  {
    label: "Quote",
    value: 1,
  },
  {
    label: "Invoices",
    value: 2,
  },
  {
    label: "Payroll Run",
    value: 3,
  },
  {
    label: "Pay Stub",
    value: 4,
  },
  {
    label: "Expense",
    value: 5,
  },
  {
    label: "Expense Run",
    value: 6,
  },
  {
    label: "Payment",
    value: 7,
  },
  {
    label: "Payment Run",
    value: 8,
  },
];

export const QUOTE_STATUS_OLD = [
  {
    label: "Draft",
    value: 0,
  },
  {
    label: "Approved",
    value: 1,
  },
  {
    label: "Archived",
    value: 2,
  },
];

export const QUOTE_REPORTS = [
  { label: "All Quotes" },
  { label: "Unsettled Quotes" },
  { label: "Approved Quotes" },
  { label: "Archived Quotes" },
];

export const INVOICE_REPORTS = [
  { label: "All Invoices" },
  { label: "Outstanding Invoices" },
  { label: "Paid Invoices" },
];

export const INCOME_REPORTS = [{ label: "Income Report" }];

export const ACCOUNTS_RECEIVABLE_REPORTS = [
  { label: "A/R Aging" },
  { label: "Detailed A/R Aging" },
];

export const ACCOUNTS_PAYABLE_REPORTS = [{ label: "A/P Aging" }];

export const PAYROLL_REPORTS = [
  { label: 'PD7A' },
  { label: 'Employee Paystubs' },
]

export const OWNERSHIP_STATUS_TYPES = [
  {
    label: "Owned",
    value: 1,
  },
  {
    label: "Leased",
    value: 2,
  },
  {
    label: "Rented",
    value: 3,
  },
];

export const VEHICLE_TYPES = [
  {
    label: "Truck",
    value: 1,
  },
  {
    label: "Sleeper Cab",
    value: 2,
  },
  {
    label: "Other",
    value: 3,
  },
];

export const VEHICLE_CLASSES = [
  {
    label: "Class 1 Light Duty",
    value: 1,
  },
  {
    label: "Class 2 Light Duty",
    value: 2,
  },
  {
    label: "Class 3 Medium Duty",
    value: 3,
  },
  {
    label: "Class 4 Medium Duty",
    value: 4,
  },
  {
    label: "Class 5 Medium Duty",
    value: 5,
  },
  {
    label: "Class 6 Medium Duty",
    value: 6,
  },
  {
    label: "Class 7 Heavy Duty",
    value: 7,
  },
  {
    label: "Class 8 Heavy Duty",
    value: 8,
  },
];

export const ENGINE_TYPES = [
  {
    label: "Diesel",
    value: 1,
  },
  {
    label: "Propane",
    value: 2,
  },
  {
    label: "Gas",
    value: 3,
  },
  {
    label: "Electric",
    value: 4,
  },
  {
    label: "Hybrid",
    value: 5,
  },
  {
    label: "Other",
    value: 6,
  },
];

export const TRANSMISSION_TYPES = [
  {
    label: "Manual",
    value: 1,
  },
  {
    label: "Automatic",
    value: 2,
  },
  {
    label: "AMTs",
    value: 3,
  },
];

export const COMPLIANCE = [
  {
    label: "TBD",
    value: 1,
  },
];

export const TRAILER_TYPES = [
  {
    label: "Dry Van",
    value: 1,
  },
  {
    label: "Refrigerated",
    value: 2,
  },
  {
    label: "Flatbed",
    value: 3,
  },
  {
    label: "Tanker",
    value: 4,
  },
  {
    label: "Other",
    value: 5,
  },
];

export const ACTIVE_STATUS = [
  {
    label: "Inactive",
    value: false,
  },
  {
    label: "Active",
    value: true,
  },
];

export const QUOTE_STATUS = [
  {
    label: "Draft",
    value: 0,
  },
  {
    label: "Approved",
    value: 1,
  },
];

export const ORDER_STATUS = [
  {
    label: "Draft",
    value: 1,
  },
  {
    label: "Quoted",
    value: 2,
  },
  {
    label: "Booked",
    value: 3,
  },
  {
    label: "Picked Up",
    value: 4,
  },
  {
    label: "In Transit",
    value: 5,
  },
  {
    label: "Delivered",
    value: 6,
  },
  {
    label: "Invoiced",
    value: 7,
  },
  {
    label: "Paid",
    value: 8,
  },
]

export const QUOTE_ITEM_TYPE = [
  {
    label: "Freight",
    value: 1,
  },
  {
    label: "Fuel",
    value: 2,
  },
  {
    label: "Other",
    value: 3,
  },
  {
    label: "Accessorial",
    value: 4,
  },
];

export const QUOTE_ITEM_CATEGORY = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "Flat Rate",
    value: 1,
  },
  {
    label: "Per Mile",
    value: 2,
  },
  {
    label: "Sur Charge",
    value: 3,
  },
];

export const DASHBOARD_CONSTANTS_1 = [
  {
    label: "Last Week",
    value: 1,
  },
];

export const DASHBOARD_CONSTANTS_2 = [
  {
    label: "Monthly",
    value: 2,
  },
];

export const WARNING_MODAL_TEXTS = {
  headerText: "Are you sure you want to leave?",
  bodyText: "You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.",
  saveBodyText: "You have unsaved changes. Are you sure you want to leave this page?",
  confirmButtonText: "Discard changes and leave this page",
  saveButtonText: "Save changes and leave this page",
  cancelButtonText: "Stay on this page",
};

export const WARNING_MODAL_QUICK_FORMS_TEXTS = {
  headerText: "Are you sure you want to close this form?",
  bodyText: "You have unsaved changes. Are you sure you want to close this form? Unsaved changes will be lost.",
  saveBodyText: "You have unsaved changes. Are you sure you want to close this form?",
  confirmButtonText: "Discard changes and close this form",
  saveButtonText: "Save changes and close this form",
  cancelButtonText: "Stay on this form",
};

export const WARNING_MODAL_EXIT_TEXTS = {
  headerText: "Are you sure you want to close this window?",
  bodyText: "You have unsaved changes. Are you sure you want to close this window? Unsaved changes will be lost.",
  saveBodyText: "You have unsaved changes. Are you sure you want to close this window?",
  confirmButtonText: "Discard changes and close this window",
  saveButtonText: "Save changes",
  cancelButtonText: "Stay on this window",
};

export const WARNING_MODAL_OVERWRITE_DETAILS_TEXTS = {
  headerText: "Warning",
  bodyTextCustomerDetails: "Are you sure you want overwrite your current data by copying from customer details?",
  bodyTextShipperDetails: "Are you sure you want overwrite your current data by copying from shipper details?",
  bodyTextReceiverDetails: "Are you sure you want overwrite your current data by copying from receiver details?",
  confirmButtonText: "Overwrite changes",
  cancelButtonText: "Cancel",
};

export const MODAL_OPTIONS = {
  top: 20,
}

export const EXPENSE_ITEM_TYPES = [
  {
    label: 'expense-item',
    value: 1
  },
  {
    label: 'product-material-item',
    value: 2
  },
  {
    label: 'labour-item',
    value: 3
  }
]

export const PACKAGING_TYPE = [
  {
    label: 'Skid',
    value: 1
  },
  {
    label: 'Crate',
    value: 2
  },
  {
    label: 'Carton',
    value: 3
  },
  {
    label: 'Container',
    value: 4
  },
  {
    label: 'Other',
    value: 5
  },
]

export const PAYROLL_RUN_TYPES = [
  {
    label: 'Periodic',
    value: 1
  },
  {
    label: 'One-Off',
    value: 2
  },
]

export const PAYROLL_RUN_STATUS = [
  {
    label: 'Committed',
    value: true,
  },
  {
    label: 'Draft',
    value: false,
  },
]
