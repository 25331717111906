import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { addCustomer, updateCustomer } from '../../services/customerServices';
import { isNTMSUSer, isAccountAdmin, isSuperAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE, PROVINCE_STATE, TAX_TYPES, COUNTRY } from '../../constants';
import { addCustomerData, setCustomerData } from '../../redux/customer/customerReducer';
import Select from 'react-select';
import { selectStyle } from '../../utils/styleUtils';
import { TimePicker } from 'antd';
import moment from 'moment';
import { setHourValue } from '../../utils/dateUtils';
import duration from 'dayjs/plugin/duration'
import PrimaryDetails from '../Legs/PrimaryDetails';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import FormTabs from '../Commons/Layouts/FormTabs';

function LegForm({
  isFormEdit = false,
  selectedCustomer = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    watch,
    control,
    trigger,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);

  const provinceStateRef = useRef("null");
  const isInitialRender = useRef(true);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!_.isEmpty(dirtyFields)) {
      dispatch(setFormPendingCompletion(true));
      setIsFormDirty(true);
    } else {
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false);
    }

  }, [formState])


  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'fax', removePhoneFormat(payload.fax));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));
    _.set(payload, 'provinceState', payload.provinceState.value)

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (payload.openingHour !== "") {
      const openingHour = moment(payload.openingHour.$d).set({ seconds: 0 }).format('HH:mm:ss');
      _.set(payload, 'openingHour', openingHour)
    }

    if (payload.closingHour !== "") {
      const closingHour = moment(payload.closingHour.$d).set({ seconds: 0 }).format('HH:mm:ss');
      _.set(payload, 'closingHour', closingHour)
    }


    if (isFormEdit) {
      _.set(payload, 'customerId', selectedCustomer.customerId);
      _.set(payload, 'accountId', selectedCustomer.accountId);
      response = await updateCustomer(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } customer.`,
        })
      );
    } else {
      _.set(payload, 'customerId', 0);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addCustomer(payload);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } customer.`,
        })
      );
    }

    if (response.ok) {
      const customerData = await response.json();
      dispatch(isFormEdit ? setCustomerData(customerData) : addCustomerData(customerData));
      // setProvinceState("");
      setValue("provinceState", "")
      provinceStateRef.current.clearValue();
      // setDefaultTax("");
      // setCountry("");
      toggleFormDisplay();

      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      setValue("openingHour", null)
      setValue("closingHour", null)
      reset()
      focusOnOpenOrCloseButton()
      dispatch(setFormPendingCompletion(false));
    }
  }

  return (
    <>
      <form id="customer-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="content-section-container">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedCustomer={selectedCustomer}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button className={`ms-auto btn btn-primary`} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </>
  )
}

export default LegForm
