import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import DriverForm from "./DriverForm";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import {
  fetchDrivers,
  fetchDriversFiltered,
  fetchUsersWithoutDrivers,
} from "../../services/driverServices";
import { formatPhoneNumber, formatDateOnly } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { setDriversListData } from "../../redux/driver/driverReducer";
import DriverModal from "../Commons/Modals/DriverModal";
import { fetchUsersFiltered } from "../../services/userServices";
import { isSuperAdmin, isNTMSUSer } from "../../utils/roleUtils";
import {
  defaultPayTypeValueGetter,
  driverTypeValueGetter,
  employmentStatusValueGetter,
} from "../../utils/driverUtils";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Drivers() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const driverState = useSelector((state) => state.driver);
  const [createDriverModal, setCreateDriverModal] = useState(false);
  const createDriverToggle = () => setCreateDriverModal(!createDriverModal);
  const [userOptions, setUserOptions] = useState([]);
  const [userCreationInformation, setUserCreationInformation] = useState(null);

  const toggleFormDisplay = (isEdit = false, driverId = null) => {
    if (isFormHidden) {
      setSelectedDriverId(driverId);
    } else {
      setSelectedDriverId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && driverList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Name",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Driver Type",
      autoHeight: true,
      cellClass: "grid-column gray",
      minWidth: 120,
      width: 150,
      filter: "agTextColumnFilter",
      valueGetter: driverTypeValueGetter,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Motor Carrier Number",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Employment Status",
      autoHeight: true,
      cellClass: "grid-column gray",
      minWidth: 180,
      width: 180,
      filter: "agTextColumnFilter",
      valueGetter: employmentStatusValueGetter,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Driver's License Number",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Driver's License Class",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Driver's License Expiry Date",
      minWidth: 200,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [driverList, setDriverList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    getUsersData();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setDriverList(driverState.data);
  }, [driverState.data]);

  useEffect(() => {
    let data = [];
    _.each(driverList, (driver, index) => {
      data.push({
        "#": index + 1,
        Name: `${driver.user.firstName} ${driver.user.lastName}`,

        "Driver Type": driver.driverType,
        "Motor Carrier Number": driver.motorCarrierNumber,
        "Employment Status": driver.employmentStatus,
        "Driver's License Number": driver.dlNumber,
        "Driver's License Class": driver.dlClass,
        "Driver's License Expiry Date": formatDateOnly(driver.dlExpiryDate),
        "Commercial Driver's License Number": driver.cdlNumber,
        "Commercial Driver's License Class": driver.cdlClass,
        "Commercial Driver's License Expiry Date": formatDateOnly(
          driver.cdlExpiryDate
        ),
        "Commercial Driver's License Medical Certificate":
          driver.cdlMedicalCertificate,
        "Commercial Driver's License Vision Test": driver.cdlVisionTest,
        Passport: driver.passport,
        "Passport Expiry Date": formatDateOnly(driver.passportExpiryDate),
        "Overweight Permit Expiry Date": formatDateOnly(
          driver.overweightPermitExpiryDate
        ),
        "Doubles Permit Expiry Date": formatDateOnly(
          driver.doublesPermitExpiryDate
        ),
        "Insurance Expiry Date": formatDateOnly(driver.insuranceExpiryDate),
        "Policy Holder": driver.policyHolder,
        "Policy Number": driver.policyNumber,
        "Default Pay Type": driver.defaultPayType,
        "Default Pay Rate": driver.defaultPayRate,
        "FAST Card": driver.fastCard,
        "Fuel Card Number": driver.fuelCardNumber,
        Edit: { id: driver.driverId, btnClass: "btn-color-8" },
      });
    });
    setRowData(data);
  }, [driverList]);

  useEffect(() => {
    fillSelectedDriver(selectedDriverId);
  }, [selectedDriverId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getDriversData();
    dispatch(setDriversListData(response));
    setIsLoading(false);
  };

  const getDriversData = async () => {
    if (isSuperAdmin(userType)) {
      return await fetchDrivers();
    }

    if (isNTMSUSer(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchDriversFiltered(accountId)
        : await fetchDriversFiltered(selectedAccountState.accountId);
    }

    return await fetchDriversFiltered(accountId);
  };

  const fillSelectedDriver = (id) => {
    const driver = _.find(driverList, { driverId: id });
    setSelectedDriver(driver);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  const getUsersData = async () => {
    const response = await fetchUsersWithoutDrivers(accountId);
    // const data = await response.json()
    setUserOptions(
      response.map((user) => ({
        label: user.firstName + " " + user.lastName,
        value: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }))
    );
  };

  const removeUserFromOptions = (userId) => {
    const updatedUserOptions = userOptions.filter(
      (item) => item.value !== userId
    );
    setUserOptions(updatedUserOptions);
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Drivers"
                : isFormEdit
                  ? "Edit Driver"
                  : "New Driver"
            }
            subtitle={"drivers"}
            dataCount={driverList.length}
            filterData={filterData}
            onClickAdd={createDriverToggle}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(driverList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-8-grid color-8-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-8' content='driver' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <DriverForm
            isFormEdit={isFormEdit}
            selectedDriver={selectedDriver}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            userCreationInformation={userCreationInformation}
            setUserCreationInformation={setUserCreationInformation}
            removeUserFromOptions={removeUserFromOptions}
          />
        </div>
      </div>
      <DriverModal
        modal={createDriverModal}
        setModal={setCreateDriverModal}
        toggle={createDriverToggle}
        userOptions={userOptions}
        userCreationInformation={userCreationInformation}
        setUserCreationInformation={setUserCreationInformation}
        toggleFormCancelDisplay={toggleFormCancelDisplay}
      />
    </div>
  );
}

export default Drivers;
