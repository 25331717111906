import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchLegs = async () => {
  return get({ path: 'Leg' });
}

export const fetchLegsFiltered = async (accountId) => {
  return get({ path: `Leg/filtered` });
}

export const addLeg = async (payload) => {
  return post({ path: 'Leg', body: payload });
}

export const updateLeg = async (payload) => {
  return patch({ path: `Leg/${payload.legId}`, body: payload });
}

export const fetchLegsByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchLegsFiltered(accountId) : await fetchLegsFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchLegsFiltered(accountId);
  }
  
  return await fetchLegs();
}