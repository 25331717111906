import React from 'react';
import { DownOutlined } from '@ant-design/icons';

function PayMethodTypeRenderer({data, editable}) {
  const payMethodType = data["Pay Method Type"];
  var currentValue = "pay method type...";
  
  switch (parseInt(payMethodType)) {
    case 0:
      currentValue = 'Salary'
        break;
    case 1:
      currentValue = 'Hours'
      break;
    case 2:
      currentValue = 'Miles'
      break;
    case 3:
      currentValue = 'Other'
      break;
    case 4:
      currentValue = "Advance"
      break;
    default:
      currentValue = "Not set"
      break;
  }

  return (
    <div className={`d-flex ${editable ? "justify-content-between" : "justify-content-center"} align-items-center col-12`}>
      <div >{currentValue}</div> 
      <div >{editable && <DownOutlined/>}</div>
    </div>
  )
}

export default PayMethodTypeRenderer