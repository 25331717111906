import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_STATUS, COUNTRY, GRID_CONSTANTS, PROVINCE_STATE } from "../../constants";
import { setTerminalsListData } from "../../redux/terminal/terminalReducer";
import { fetchTerminals, fetchTerminalsByAccount } from "../../services/terminalServices";
import {
  isAccountAdmin,
  isAccountUser,
  isNTMSUSer,
  isSuperAdmin,
} from "../../utils/roleUtils";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import TerminalForm from "./TerminalForm";
import { enumValueFormatter } from "../../utils/formatUtils";
import ActiveStatusRenderer from "../Templates/CellRenderers/ActiveStatusRenderer";
import LoadingPage from "../Commons/Authorization/LoadingPage";
import NoData from "../Commons/Layouts/NoData";

function Terminals() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const terminalState = useSelector((state) => state.terminal);

  const toggleFormDisplay = (isEdit = false, terminalId = null) => {
    if (isFormHidden) {
      setSelectedTerminalId(terminalId);
    } else {
      setSelectedTerminalId(null);
    }

    if (modal) {
      setModal(!modal);
    }
    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    // setTimeout(() => {
    //   gridRef.current.api.sizeColumnsToFit();
    // }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Terminal Name",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Address Line 1",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "City",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Province/State",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      valueFormatter: (params) => {
        return enumValueFormatter(params, PROVINCE_STATE)
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Postal Code",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Country",
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column",
      valueFormatter: (params) => {
        return enumValueFormatter(params, COUNTRY)
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Status",
      cellRenderer: ActiveStatusRenderer,
      minWidth: 150,
      autoHeight: true,
      cellClass: "grid-column center",
      valueFormatter: (params) => {
        return enumValueFormatter(params, ACTIVE_STATUS)
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      headerName: "",
      minWidth: 150,
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      resizable: false,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [terminalList, setTerminalList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [selectedTerminalId, setSelectedTerminalId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setTerminalList(terminalState.data);
  }, [terminalState.data]);

  useEffect(() => {
    let data = [];
    _.each(terminalList, (terminal, index) => {
      data.push({
        "Terminal Name": terminal.terminalName,
        "Address Line 1": terminal.address1,
        "City": terminal.city,
        "Province/State": terminal.provinceState,
        "Postal Code": terminal.postalCode,
        "Country": terminal.country,
        "Status": terminal.active,
        Edit: { id: terminal.terminalId, btnClass: "btn-color-7" },
      });
    });
    setRowData(data);
  }, [terminalList]);

  useEffect(() => {
    fillSelectedTerminal(selectedTerminalId);
  }, [selectedTerminalId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef();

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    sizeColumnsToFit(gridRef.current);
  }, []);

  const init = async () => {
    const response = await getAccountsData();
    dispatch(setTerminalsListData(_.isArray(response) ? response : [response]));

    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      setIsFormEdit(true);
      setIsFormHidden(false);
    }

    setIsLoading(false);
  };

  const getAccountsData = async () => {
    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      return await fetchTerminalsByAccount(accountId);
    }

    return await fetchTerminals();
  };

  const fillSelectedTerminal = (id) => {
    const account = _.find(terminalList, { terminalId: id });
    setSelectedTerminal(account);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      sizeColumnsToFit(params);
    }
  };

  const sizeColumnsToFit = (params) => {
    if (isSuperAdmin(userType) || isNTMSUSer(userType)) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef?.current?.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Terminals"
                : isFormEdit
                  ? "Edit Terminal"
                  : "New Terminal"
            }
            subtitle={"terminals"}
            dataCount={terminalList?.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div
          className={`${isFormHidden ? "col-12" : "d-none"} ${isSuperAdmin(userType) || isNTMSUSer(userType) ? "" : "d-none"
            } mb-2`}
        >
          {(terminalList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-7-grid color-7-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-7' content='terminal' />
          }
        </div>
        <div
          className={`${isSuperAdmin(userType) || isNTMSUSer(userType) ? "col-12" : "col-12"
            } ${isFormHidden ? "d-none" : ""} mb-2`}
        >
          <TerminalForm
            isFormEdit={isFormEdit}
            selectedTerminal={selectedTerminal}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Terminals;
