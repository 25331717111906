import { Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { THEME_COLORS } from "../../../constants";
import { getThemeColor } from "../../../utils/formatUtils";

function FormTabs({
  items,
  tabClassName = "color-3",
  isFormHidden = null
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [themeColor, setThemeColor] = useState(THEME_COLORS.COLOR_3);

  useEffect(() => {
    setThemeColor(getThemeColor(tabClassName))
  }, [tabClassName]);

  const handleTabChange = (key) => {
    setActiveTab(parseInt(key, 10));
  };

  const operationsSlot = {
    left: (
      <>
        <button
          className={`btn btn-${tabClassName} btn-navigation btn-sm me-3 ${activeTab === 0 ? "d-none" : null
            }`}
          type="button"
          onClick={() => setActiveTab(activeTab - 1)}
        >
          <LeftOutlined />
        </button>
      </>
    ),
    right: (
      <>
        <button
          className={`btn btn-${tabClassName} btn-navigation btn-sm ${activeTab === items.length - 1 ? "d-none" : null
            }`}
          type="button"
          onClick={() => setActiveTab(activeTab + 1)}
        >
          <RightOutlined />
        </button>
      </>
    ),
  };

  const position = ["left", "right"];
  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: operationsSlot[direction],
      }),
      {}
    );
  }, [position]);

  useEffect(() => {
    if (isFormHidden != null && isFormHidden) {
      setActiveTab(parseInt(0, 10))
    }
  }, [isFormHidden])

  return (
    <Tabs
      tabBarExtraContent={slot}
      items={items}
      activeKey={activeTab}
      onChange={handleTabChange}
      className={tabClassName}
    />
  );
}

export default FormTabs;
