import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import {
  fetchExpensesFiltered,
  fetchExpensesFilteredWithBalances,
} from "../../services/expenseServices";
import { currencyRendererSelector } from "../../utils/quoteUtils";
import QuoteTaxableRenderer from "../Templates/CellRenderers/QuoteTaxableRenderer";
import ExpenseForm from "./ExpenseForm";
import { fetchAccountById } from "../../services/accountServices";
import { fetchReporTemplateByAccount } from "../../services/reportTemplateServices";
import parse from "html-react-parser";
import { formatTaxRate } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { isNTMSUSer } from "../../utils/roleUtils";
import { getReportValue } from "../../utils/reportTemplateUtils";
import { createExpensesReportFile } from "../../services/reportServices";
import { printPreviewReportWithBase64 } from "../../utils/payrollRunUtils";
import { processCSVExportCellCallback } from "../../utils/csvUtils";
import moment from "moment";
import { setNotificationData } from "../../redux/global/globalReducer";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";

function Expenses() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const addExpenseButtonRef = useRef(null);

  const toggleFormDisplay = (isEdit = false, expenseId = null) => {
    if (isFormHidden) {
      setSelectedExpenseId(expenseId);
    } else {
      setSelectedExpenseId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && expenseList?.length > 0) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const columnDefinitions = (isPrintableLayout = false) => {
    const defs = [
      {
        field: "#",
        width: 50,
        minWidth: 50,
        suppressSizeToFit: true,
        cellClass: "no-borders",
        cellStyle: { fontWeight: "bold" },
        maxWidth: isPrintableLayout ? 50 : null,
      },
      {
        field: "Description",
        minWidth: 120,
        maxWidth: isPrintableLayout ? 120 : null,
      },
      {
        field: "Company",
        minWidth: 100,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "QTY",
        minWidth: 100,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Unit Price",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Tax Type",
        minWidth: 110,
        headerName: "Tax Type",
        singleClickEdit: true,
        cellRendererSelector: (params) => {
          if (params.data["Tax Type"] !== undefined) {
            return {
              component: QuoteTaxableRenderer,
            };
          } else {
            return undefined;
          }
        },
        autoHeight: true,

        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Tax Rate",
        headerName: "Tax Rate",
        valueGetter: (params) => formatTaxRate(params.data["Tax Rate"]),
        autoHeight: true,
        minWidth: 100,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Discount",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Subtotal",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Total",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Total Tax",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Balance",
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: "Edit",
        minWidth: 150,
        headerName: "",
        cellClass: "d-flex flex-row-reverse non-printable",
        autoHeight: true,
        cellRenderer: EditFormRenderer,
        cellRendererParams: { toggleFormDisplay },
      },
    ];

    return defs;
  };

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columnDefinitions());
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [expenseList, setExpenseList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState({});
  const [selectedExpenseId, setSelectedExpenseId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const [accountProvince, setAccountProvince] = useState("");
  const [reportTemplate, setReportTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [printMode, setPrintMode] = useState(false);
  const [balanceFilter, setBalanceFilter] = useState(true);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    focusOnOpenOrCloseButton();
    fillAccountProvince();
  }, []);

  useEffect(() => {
    fillExpenseList();
  }, [balanceFilter]);

  useEffect(() => {
    fillReportTemplate();
  }, [accountId]);

  useEffect(() => {
    let data = [];
    _.each(expenseList, (expense, index) => {
      data.push({
        "#": index + 1,
        Description: expense.description,
        Company: expense.companyName,
        QTY: expense.quantity,
        "Unit Price": parseFloat(expense.rate),
        "Tax Type": expense.taxType,
        "Tax Rate": expense.taxRate,
        Discount: parseFloat(expense.discount),
        Subtotal: parseFloat(expense.subtotal),
        "Total Tax": parseFloat(expense.totalTax),
        Total: parseFloat(expense.total),
        Balance: parseFloat(expense.balance),
        Edit: { id: expense.expenseId, btnClass: "btn-color-4" },
      });
    });
    setRowData(data);
  }, [expenseList]);

  useEffect(() => {
    fillSelectedExpense(selectedExpenseId);
  }, [selectedExpenseId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillExpenseList = async () => {
    let response = null;

    if (isNTMSUSer(userType) && selectedAccountState.accountId !== null) {
      response = await fetchExpensesFiltered(balanceFilter);
    } else {
      response = await fetchExpensesFiltered(balanceFilter);
    }

    setExpenseList(response);
    setIsLoading(false);
  };

  //Update after user identity
  const fillAccountProvince = async () => {
    let response = null;
    response = await fetchAccountById(accountId);

    setAccountProvince(parseInt(response["provinceState"]));
  };

  const fillSelectedExpense = (id) => {
    const expense = _.find(expenseList, { expenseId: id });
    setSelectedExpense(expense);
  };

  const fillReportTemplate = async () => {
    const response = await fetchReporTemplateByAccount(
      userType,
      selectedAccountState.accountId,
      accountId,
      getReportValue("Expense").value
    ).catch((e) => {
      setReportTemplate({});
    });
    setReportTemplate(response);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const setGridPrintableLayout = () => {
    gridRef.current.api.setDomLayout("print");
    gridRef.current.api.sizeColumnsToFit();
  };

  const printExpenses = async () => {
    const payload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      filter: balanceFilter ? "unpaid" : null,
    };

    const response = await createExpensesReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    printPreviewReportWithBase64(data.base64);
  };

  const headerCheck = () => {
    return (
      printMode === true &&
      reportTemplate?.header !== undefined &&
      reportTemplate.useHeader === true
    );
  };

  const footerCheck = () => {
    return (
      printMode === true &&
      reportTemplate?.footer !== undefined &&
      reportTemplate.useFooter === true
    );
  };

  const defaultSpacerCheck = () => {
    return (
      printMode === true &&
      reportTemplate?.footer !== undefined &&
      reportTemplate.useDefaultSpacer === true
    );
  };

  const exportDataAsCSV = useCallback(() => {
    const params = {
      columnKeys: [
        "Description",
        "Company",
        "QTY",
        "Unit Price",
        "Tax Type",
        "Tax Rate",
        "Discount",
        "Subtotal",
        "Total Tax",
        "Total",
        "Balance",
      ],
      fileName: `expenses-${moment(new Date()).format("YYYY-MM-DD")}.csv`,
      processCellCallback: processCSVExportCellCallback,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  const handleBalanceFilterChange = (e) => {
    setBalanceFilter(e.target.checked);
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      {headerCheck() && <div>{parse(reportTemplate?.header)}</div>}
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Expenses"
                : isFormEdit
                  ? "Edit Expense"
                  : "New Expense"
            }
            subtitle={"expenses"}
            dataCount={rowData.length}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
            filterData={filterData}
            customButtons={[
              {
                label: "Export",
                onClick: exportDataAsCSV,
                icon: <DownloadOutlined />,
                disabled: false,
                shown: isFormHidden
              },
              {
                label: "Print",
                onClick: printExpenses,
                icon: <PrinterOutlined />,
                disabled: false,
                shown: isFormHidden
              },
            ]}
          />
        </div>
      </div>
      <div className={`col-12 d-flex justify-content-end form-container form-check ${isFormHidden ? "" : "d-none"}`}>
        <input
          className="form-check-input me-2"
          type="checkbox"
          value=""
          id="balanceCheckbox"
          defaultChecked={true}
          onChange={(e) => handleBalanceFilterChange(e)}
        />
        <label className="form-check-label" htmlFor="balanceCheckbox">
          With Balances
        </label>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(expenseList?.length > 0) ?
            <div
              id="expenseGrid"
              className={`ag-theme-alpine content-section-container color-4-grid color-4-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={70}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-4' content='expense' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <ExpenseForm
            isFormEdit={isFormEdit}
            selectedExpense={selectedExpense}
            isFormHidden={isFormHidden}
            fillExpenseList={fillExpenseList}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            accountProvince={accountProvince}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
      {defaultSpacerCheck() && (
        <>
          <br />
          <hr />
          <br />
        </>
      )}
      {footerCheck() && <div>{parse(reportTemplate?.footer)}</div>}
    </div>
  );
}

export default Expenses;
