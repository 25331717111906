import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU_ITEMS_ANTD, SUB_MENU_KEYS_ANTD, WARNING_MODAL_TEXTS } from "../../constants";
import { setFormPendingCompletion, setFormPendingFunction } from "../../redux/global/globalReducer";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import "./sidebar.css";
import { MenuFoldOutlined, MenuUnfoldOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import _ from "lodash";

function SidebarPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const formPendingCompletion = useSelector(
    (state) => state.global.formPendingCompletion
  );
  const formPendingFunction = useSelector(
    (state) => state.global.formPendingFunction
  );

  const authState = useSelector((state) => state.auth);

  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [navigationLink, setNavigationLink] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const findHighestParentKey = (searchString) => {
    const findParentKey = (items, key) => {
      for (const item of items) {
        if (item.key === key) {
          return item.key;
        } else if (item.children) {
          const parentKey = findParentKey(item.children, key);
          if (parentKey) {
            return item.key;
          }
        }
      }
      return null;
    };

    const highestParentKey = _.find(MENU_ITEMS_ANTD, (item) => {
      if (item.children) {
        return findParentKey(item.children, searchString);
      }
      return false;
    });

    return highestParentKey ? highestParentKey.key : null;
  };

  useEffect(() => {
    dispatch(setFormPendingCompletion(false));
    dispatch(setFormPendingFunction(null));

    // have menu items open after refresh
    const targetKey = location.pathname.substring(1);
    const parentKey = findHighestParentKey(targetKey);

    setOpenKeys([parentKey]);
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const pathKeyMap = {
      '/quotes/': 'quotes',
      '/invoices/': 'invoices',
      '/payroll-runs/': 'payroll-runs',
      '/settings/': 'settings'
    };
    let currentKey = Object.keys(pathKeyMap).find(key => path.includes(key));
    currentKey = currentKey ? pathKeyMap[currentKey] : path.substring(1);
    setSelectedKeys([currentKey]);
  }, [location.pathname.substring(1)]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formPendingCompletion) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formPendingCompletion]);

  const handleUnsaveChangesCancel = () => {
    setUnsavedChangesModal(false);
  };

  const handleUnsavedChangesConfirm = async (save = false) => {
    if (save && (formPendingCompletion && formPendingFunction !== null)) {
      await formPendingFunction();
    }
    setUnsavedChangesModal(false);
    dispatch(setFormPendingCompletion(false));
    dispatch(setFormPendingFunction(null));
    navigate(navigationLink);
  };


  const handleClick = async (item) => {
    if (formPendingCompletion) {
      if (formPendingFunction !== null) {
        const saveData =  await formPendingFunction();
        if(await saveData) {
          dispatch(setFormPendingCompletion(false));
          dispatch(setFormPendingFunction(null));
          navigate(`/${item.key}`);
        }
        
      } else {
        setUnsavedChangesModal(true);
        setNavigationLink(`/${item.key}`);
      }
    } else {
      navigate(`/${item.key}`);
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && SUB_MENU_KEYS_ANTD.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    authState?.isAuthenticated && (
      <>
        <div className="d-flex flex-column">
          <Menu
            style={{
              height: "95%",
              width: collapsed ? 70 : 256,
              transition: "width 0.2s",
            }}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            mode={collapsed ? "vertical" : "inline"}
            items={MENU_ITEMS_ANTD}
            onClick={async (item) => await handleClick(item)}
          />
          <div className="toggle-sidebar-container p-2">
            <button
              onClick={toggleSidebar}
              className={`btn btn-toggle-sidebar ${collapsed ? "text-center" : "text-start"
                }`}
              style={{
                width: "100%",
              }}
            >
              {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
              <span className={collapsed ? "" : "ms-2"}>
                {collapsed ? "" : "Toggle Sidebar"}
              </span>
            </button>
          </div>
        </div>

        <ConfirmationModal
          isOpen={unsavedChangesModal}
          toggle={handleUnsaveChangesCancel}
          onClick={() => handleUnsavedChangesConfirm(false)}
          headerText={WARNING_MODAL_TEXTS.headerText}
          bodyText={formPendingFunction !== null ? WARNING_MODAL_TEXTS.saveBodyText : WARNING_MODAL_TEXTS.bodyText}
          confirmButtonText={WARNING_MODAL_TEXTS.confirmButtonText}
          cancelButtonText={WARNING_MODAL_TEXTS.cancelButtonText}
          actionType={"delete"}
        />
      </>
    )
  );
}

export default SidebarPage;
