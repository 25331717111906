import React from 'react';

const NotesTooltip = ({ data }) => {

    return (
        <div
            className="ag-custom-tooltip"
        >
            <span>{data["Notes"]}</span>
        </div>
    );
};

export default NotesTooltip