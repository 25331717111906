import { get, post, patch } from "./apiService";

export const fetchItems = async (page) => {
  return get({ path: page });
  }

export const fetchAccount = async (url) => {
  return get({ path: url });
  }

export const fetchDefaultTaxCode = async () => {
  return get({ path: 'Account/defaultTaxCode' });
}

export const fetchCustomersDropdown = async () => {
  return get({ path: 'Customer/Dropdown' });
}

export const fetchCustomersFilteredDropdown = async (accountId) => {
    return get({ path: `Customer/Dropdown/${accountId}` });
}

export const addExpenseItem = async (payload, keepAlive = false) => {
  return post({ path: 'ExpenseCategory/ExpenseItem', body: payload, keepAlive: keepAlive });
}

export const updateExpenseItem = async (payload, keepAlive = false) => {
  return patch({ path: `ExpenseCategory/ExpenseItem/update/${payload.expenseItemId}`, body: payload, keepAlive: keepAlive });
}

export const addProductMaterialItem = async (payload, keepAlive = false) => {
  return post({ path: 'ExpenseCategory/productMaterial', body: payload, keepAlive: keepAlive });
}

export const updateProductMaterialItem = async (payload, keepAlive = false) => {
  return patch({ path: `ExpenseCategory/productMaterial/update/${payload.productMaterialId}`, body: payload, keepAlive: keepAlive });
}

export const addLabourItem = async (payload, keepAlive = false) => {
  return post({ path: 'ExpenseCategory/labour', body: payload, keepAlive: keepAlive });
}

export const updateLabourItem = async (payload, keepAlive = false) => {
  return patch({ path: `ExpenseCategory/labour/update/${payload.labourId}`, body: payload, keepAlive: keepAlive });
}