import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
// import { Input } from 'reactstrap';
import { Select, Space } from 'antd';
const QuoteItemCategoryEditor = forwardRef(({ value, values, api, editable }, ref) => {
  // const { value, values, api } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const refInput = useRef(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const handleBlur = () => {
    api.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue;
    },
  }));

  const onInputKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent default browser behavior
      const currentIndex = values.findIndex(option => option.value === selectedValue);
      let newIndex;
      if (e.key === 'ArrowUp') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : values.length - 1;
      } else {
        newIndex = currentIndex < values.length - 1 ? currentIndex + 1 : 0;
      }
      const newValue = values[newIndex].value;
      setSelectedValue(newValue);
    }
  };

  return (
    <div style={{width:'100%', height:'100%'}}>
        <Select
          // ref={refInput}
          popupClassName='color-3'
          onChange={handleChange}
          onBlur={handleBlur}
          className='antd-select grid-column'
          options={values}
          value={selectedValue}
          open
          // optionFilterProp='value'
          autoFocus
          onInputKeyDown={onInputKeyDown}
        />
    </div>
  );
});

export default QuoteItemCategoryEditor;