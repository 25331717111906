import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchDrivers = async () => {
  return get({ path: 'Driver' });
}

export const fetchDriversFiltered = async (accountId) => {
  return get({ path: `Driver/filtered` });
}

export const fetchUsersWithoutDrivers = async (accountId) => {
  return get({ path: `Driver/non-drivers` });
}

export const addDriver = async (payload, keepAlive = false) => {
  return post({ path: 'Driver', body: payload, keepAlive: keepAlive });
}

export const updateDriver = async (payload, keepAlive = false) => {
  return patch({ path: `Driver/${payload.driverId}`, body: payload, keepAlive: keepAlive });
}