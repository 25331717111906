import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTrailerMaintenance = async () => {
  return get({ path: 'TrailerMaintenance' });
}

export const fetchTrailerMaintenanceFiltered = async () => {
  return get({ path: `TrailerMaintenance/filtered` });
}

export const addTrailerMaintenance = async (payload, keepAlive = false) => {
  return post({ path: 'TrailerMaintenance', body: payload, keepAlive: keepAlive });
}

export const updateTrailerMaintenance = async (payload, keepAlive = false) => {
  return patch({ path: `TrailerMaintenance/${payload.trailerMaintenanceId}`, body: payload, keepAlive: keepAlive });
}

export const fetchTrailerMaintenanceByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchTrailerMaintenanceFiltered(accountId) : await fetchTrailerMaintenanceFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchTrailerMaintenanceFiltered(accountId);
  }
  
  return await fetchTrailerMaintenance();
}