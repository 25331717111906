import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import './../Customers/css/customers.css'
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import ExpenseItemForm from './ExpenseItemForm';
import LabourForm from './LabourForm'
import ProductMaterialForm from './ProductMaterialForm'
import QuoteTaxableRenderer from '../Templates/CellRenderers/QuoteTaxableRenderer';
import ItemsContentHeader from '../Commons/Layouts/ItemsContentHeader';
import { fetchItems, fetchAccount } from '../../services/expenseCategoryServices';
import { getUrl } from '../../utils/expenseCategoryUtils';
import _ from 'lodash';
import { isNTMSUSer, isSuperAdmin } from '../../utils/roleUtils';
import { formatTaxRate } from '../../utils/formatUtils';
import { useSelector } from 'react-redux';
import { fetchAccountById } from '../../services/accountServices';
import { GRID_CONSTANTS } from '../../constants';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import { PlusOutlined } from "@ant-design/icons";
import { Space } from 'antd';
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from '../Commons/Authorization/LoadingPage';

function ExpenseCategories() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [formType] = useState("none");
  const [province, setProvince] = useState(-1);
  const [buttonText, setButtonText] = useState("Add Expense Item")
  const [page, setPage] = useState("expense")
  const [title, setTitle] = useState({
    mainTitle: "Expense Items",
    addTitle: "New Expense Item",
    editTitle: "Edit Expense Item",
  })
  const [isFormHidden, setIsFormHidden] = useState(true);

  const toggleFormDisplay = (isEdit = false, itemId = null) => {
    if (isFormHidden) {
      setSelectedItemId(itemId);
    } else {
      setSelectedItemId(null)
    }
    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && itemList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay()
  }

  const toggleHeaderFormDisplay = (isEdit = false, itemId = null) => {
    if (isFormDirty) {
      toggle()
      return
    }
    if (isFormHidden) {
      setSelectedItemId(itemId);
    } else {
      setSelectedItemId(null)
    }
    if (modal) {
      setModal(false)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(true);
    setTimeout(() => {
      if (itemList?.length > 0 && !isLoading) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  }

  useEffect(() => {
    fillAccountData()
    fillItemList('expense')
  }, [])

  const fillItemList = async (page) => {
    let response = null;
    var selectedWorkingAccount = isNTMSUSer(userType) && selectedAccountState.accountId !== null
    if (selectedWorkingAccount) {
      response = await fetchItems(getUrl(selectedAccountState.accountId, page, userType, selectedWorkingAccount))
    } else {
      response = await fetchItems(getUrl(accountId, page, userType))
    }

    setItemList(response);
    setIsLoading(false);
  }

  const fillAccountData = async (page) => {
    // const data = await fetchAccount("Account/" + accountId)
    const data = await fetchAccountById("Account/" + accountId)

    setProvince(data['provinceState'])
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const fillSelectedItem = (id) => {
    var item = null
    switch (page) {
      case "expense":
        item = _.find(itemList, { expenseItemId: id });
        setSelectedItem(item);
        break;
      case "product":
        item = _.find(itemList, { productMaterialId: id });
        setSelectedItem(item);
        break;
      case "labour":
        item = _.find(itemList, { labourId: id });
        setSelectedItem(item);
        break;
      default:
        item = _.find(itemList, { expenseItemId: id });
        setSelectedItem(item);
        break;
    }
  }

  const clearFilter = () => {
    if (gridRef.current.api !== undefined) {
      gridRef.current.api.setQuickFilter(undefined);
    }
  }

  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColumnDefs = [
    {
      field: 'Title',
      minWidth: 150,
      cellClass: 'grid-column',
    },
    {
      field: 'Description',
      minWidth: 150,
      cellClass: 'grid-column gray',
    },
    {
      field: 'Tax Type',
      minWidth: 100,
      cellRenderer: QuoteTaxableRenderer,
      cellClass: 'grid-column gray',
    },
    {
      field: 'Default Rate',
      minWidth: 100,
      valueGetter: params => formatTaxRate(params.data["Default Rate"]),
      cellClass: 'grid-column gray',
    },
    {
      field: 'GL Code',
      minWidth: 150,
      cellClass: 'grid-column gray',
    },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
      resizable: false
    },
  ]

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [itemList, setItemList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    let data = [];


    _.each(itemList, (item, index) => {
      data.push({
        'index': index + 1,
        'Item': item.labourId != null ? item.labourId : item.productMaterialId != null ? item.productMaterialId : item.expenseItemId,
        'Title': item.labourTitle != null ? item.labourTitle : item.expenseTitle,
        'Description': item.labourDescription != null ? item.labourDescription : item.expenseDescription,
        'Tax Type': item.taxType,
        'Default Rate': item.defaultRate,
        'GL Code': item.glCode.description,
        'Account': item.accountId,
        'Notes': item.notes,
        'Active': item.active,
        'Edit': { id: item.labourId != null ? item.labourId : item.productMaterialId != null ? item.productMaterialId : item.expenseItemId, btnClass: "btn-color-6" }
      });
    });
    setRowData(data);
  }, [itemList])

  useEffect(() => {
    fillSelectedItem(selectedItemId);
  }, [selectedItemId]);

  function formSwitch() {
    const props = {
      isFormEdit,
      selectedItem,
      isFormHidden,
      fillItemList,
      toggleFormDisplay,
      setIsFormDirty,
      modal,
      setModal,
      toggle,
      province,
      focusOnOpenOrCloseButton,
    };

    switch (page) {
      case "expense":
        return <ExpenseItemForm  {...props} />
      case "product":
        return <ProductMaterialForm {...props} />
      case "labour":
        return <LabourForm {...props} />
      default:
        return <ExpenseItemForm {...props} />
    }
  }

  // Resizes the ag-grid columns to fit the component everytime the form component gets opened/closed
  useEffect(() => {
    if (typeof gridRef?.current?.api != "undefined" && itemList?.length > 0) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [formType])

  function setPageData(header, button, value) {
    setTitle({
      mainTitle: `${header}s`,
      addTitle: `New ${header}`,
      editTitle: `Edit ${header}`,
    })
    setPage(value)
    setButtonText(button)
    fillItemList(value)
  }

  function pageChange(pageName) {
    switch (pageName) {
      case "expense":
        setPageData("Expense Item", "Add Expense Item", pageName);
        break;
      case "product":
        setPageData("Product Material Item", "Add Product Material", pageName);
        break;
      case "labour":
        setPageData("Labour Item", "Add Labour Item", pageName);
        break;
      default:
        setPageData("Expense Item", "Add Expense Item", 'expense');
        break;
    }
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  const onPageChange = (page) => {
    setIsLoading(true)
    pageChange(page)
    toggleHeaderFormDisplay()
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div >
      <div className='row mb-0'>
        <div className='col-12 '>
          <ContentHeader
            title={
              isFormHidden
                ? title.mainTitle
                : isFormEdit
                  ? title.editTitle
                  : title.addTitle
            }
            subtitle={title?.mainTitle.toLowerCase()}
            dataCount={rowData?.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
            hideButton={isFormHidden}
            hideDropdownButton={!isFormHidden}
            actionFunction={toggleFormCancelDisplay}
            color='color-3'
            parentNavItem={parentNavItem}
            dropdownTitle={<Space>
              <PlusOutlined />
              Add New
            </Space>}
            dropdownList={[
              {
                label: "Expense Items",
                key: '1',
                onClick: (e) => onPageChange("expense"),
              },
              {
                label: "Product Material Items",
                key: '2',
                onClick: (e) => onPageChange("product"),
              },
              {
                label: "Labour Items",
                key: '3',
                onClick: (e) => onPageChange("labour"),
              },
            ]}
          />
        </div>

      </div>
      <div className="me-auto col-2">
      </div>
      <div className='content-body-container row'>
        <div className={`${isFormHidden ? 'col-12' : 'd-none'} mb-2`}>
          {(itemList?.length > 0) ?

            <div className={`ag-theme-alpine content-section-container color-6-grid color-6-section p-0`} >
              {/* <h4>{header}</h4> */}
              <AgGridReact
                className='no-header'
                // headerHeight={headerHeight}
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowSelection={'single'}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              >
              </AgGridReact>
            </div>
            :
            <NoData isFormHidden={isFormHidden} color='color-6' content={page} />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? 'd-none' : ''}`}>
          {formSwitch()}
        </div>

      </div>
    </div>
  )
}

export default ExpenseCategories
