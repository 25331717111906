import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { COUNTRY, PROVINCE_STATE } from "../../constants";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";

function FormCustomerDetails({
  customerOptions,
  selectedOrder = {},
  control,
  register,
  setValue,
  errors,
  checkPageEditable,
  selectedCustomerOption,
  setSelectedCustomerOption,
  selectedCustomerAddressValues,
  setSelectedCustomerAddressValues,
  temporaryCustomerDetails,
  setTemporaryCustomerDetails,
  copyAddressShipperCheckedValue,
  setCopyAddressShipperCheckedValue,
}) {
  const customerStateRedux = useSelector((state) => state.customer);

  // Customer Details
  const [selectedCustomerTemp, setSelectedCustomerTemp] = useState(null);

  const initialRender = useRef(true);

  const [changeConfirmationModal, setChangeConfirmationModal] = useState(false);
  const [clearConfirmationModal, setClearConfirmationModal] = useState(false);

  useEffect(() => {
    // Skip the effect on initial render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    // Handler if customer is getting changed
    if (!_.isNil(selectedCustomerTemp) && !_.isEmpty(selectedCustomerTemp)) {
      handleCustomerNameChange(selectedCustomerTemp);
    }

    // Handler if customer is getting cleared
    if (_.isNil(selectedCustomerTemp)) {
      setClearConfirmationModal(true);
    }
  }, [selectedCustomerTemp]);

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedCustomerProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedCustomerProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("customerAddress1", selectedSuggestion?.properties?.address_line1);
    setValue("customerAddress2", selectedSuggestion?.properties?.address_line2);
    setValue("customerCity", selectedSuggestion?.properties?.city);
    setValue("customerPostal", selectedSuggestion?.properties?.postcode);
    setValue("customerState", selectedCustomerProvinceState);
    setValue("customerCountry", selectedCountry);
    setValue("customerLatitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("customerLongitude", `${selectedSuggestion?.properties?.lon}`);

    const updatedDetails = {
      ...temporaryCustomerDetails,
      customerAddress1: selectedSuggestion?.properties?.address_line1,
      customerAddress2: selectedSuggestion?.properties?.address_line2,
      customerCity: selectedSuggestion?.properties?.city,
      customerPostal: selectedSuggestion?.properties?.postcode,
      customerState: selectedCustomerProvinceState,
      customerCountry: selectedCountry,
      customerLatitude: selectedSuggestion?.properties?.lat,
      customerLongitude: selectedSuggestion?.properties?.lon,
    };

    setTemporaryCustomerDetails(updatedDetails);
  };

  const handleCustomerNameChange = () => {
    if (selectedOrder?.customerId > 0) {
      setChangeConfirmationModal(true);
      return;
    }

    handleCustomerNameChangeConfirm();
  };

  const handleCustomerNameChangeConfirm = () => {
    setChangeConfirmationModal(false);
    setValue("customerId", selectedCustomerTemp?.value);
    setSelectedCustomerOption(selectedCustomerTemp);
    const selectedCustomerData = _.find(customerStateRedux.data, (customer) => {
      return customer.customerId === selectedCustomerTemp?.value;
    });

    if (_.isNil(selectedCustomerData)) {
      return;
    }

    const payload = {
      name: selectedCustomerData.customerName,
      contact: selectedCustomerData.primaryContact,
      email: selectedCustomerData.defaultEmail,
      phone: selectedCustomerData.phone,
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    };

    fillCustomerFields(payload);

    setSelectedCustomerAddressValues({
      addressLine1: selectedCustomerData.addressLine1,
      addressLine2: selectedCustomerData.addressLine2,
      provinceState: selectedCustomerData.provinceState,
      city: selectedCustomerData.city,
      country: selectedCustomerData.country,
      postalCode: selectedCustomerData.postalCode,
    });

    setValue("customerLatitude", selectedCustomerData.customerLatitude);
    setValue("customerLongitude", selectedCustomerData.customerLongitude);

    setTemporaryCustomerDetails({
      customerId: selectedCustomerData.customerId,
      customerName: selectedCustomerData.customerName,
      customerContactName: selectedCustomerData.primaryContact,
      customerDefaultEmail: selectedCustomerData.defaultEmail,
      customerPhoneNumber: selectedCustomerData.phone,
      customerAddress1: selectedCustomerData.addressLine1,
      customerAddress2: selectedCustomerData.addressLine2,
      customerCity: selectedCustomerData.city,
      customerPostal: selectedCustomerData.postalCode,
      customerState: selectedCustomerData.provinceState,
      customerCountry: selectedCustomerData.country,
      customerLatitude: selectedCustomerData.customerLatitude,
      customerLongitude: selectedCustomerData.customerLongitude,
    });
    
    if (copyAddressShipperCheckedValue === true) {
      setCopyAddressShipperCheckedValue(false);
    }
  };

  const handleCustomerNameChangeCancel = () => {
    setChangeConfirmationModal(false);
    setSelectedCustomerTemp({});
  };

  const handleCustomerNameClearConfirm = () => {
    setClearConfirmationModal(false);
    setSelectedCustomerOption(null);
    clearCustomerFields();
  };

  const handleCustomerNameClearCancel = () => {
    setClearConfirmationModal(false);
  };

  const fillCustomerFields = (payload) => {
    const selectedCustomerProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.value === payload.provinceState;
      }
    );

    const selectedCustomerCountry = _.find(COUNTRY, (country) => {
      return country.value === payload.country;
    });

    setValue("customerName", payload.name);
    setValue("customerContactName", payload.contact);
    setValue("customerDefaultEmail", payload.email);
    setValue("customerPhoneNumber", payload.phone);
    setValue("customerAddress1", payload.addressLine1);
    setValue("customerAddress2", payload.addressLine2);
    setValue("customerState", selectedCustomerProvinceState);
    setValue("customerCity", payload.city);
    setValue("customerCountry", selectedCustomerCountry);
    setValue("customerPostal", payload.postalCode);
  };

  const clearCustomerFields = () => {
    setValue("customerName", null);
    setValue("customerContactName", null);
    setValue("customerDefaultEmail", null);
    setValue("customerPhoneNumber", null);
    setValue("customerAddress1", null);
    setValue("customerAddress2", null);
    setValue("customerState", null);
    setValue("customerCity", null);
    setValue("customerCountry", null);
    setValue("customerPostal", null);
  };

  return (
    <>
      {/* Customer Details */}
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="customerName" className="form-label">
          Name <span className="required-asterisk">*</span>
        </label>
        <div className="custom-selector">
          <Controller
            name="customerName"
            control={control}
            register={register}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                onChange={setSelectedCustomerTemp}
                value={selectedCustomerOption}
                options={customerOptions}
                className="customer-name-select"
                classNamePrefix="react-select"
                placeholder={"Select Customer / Create new Customer"}
                id="customerName"
                styles={selectStyle}
                isClearable
                tabIndex={2}
                isDisabled={checkPageEditable()}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.customerName && errors.customerName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="customerContactName" className="form-label">
          Primary Contact <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="customerContactName"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("customerContactName", {
            required: "Primary Contact is required",
          })}
        />
        <small className="form-error-message">
          {errors?.customerContactName && errors.customerContactName.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="customerDefaultEmail" className="form-label">
          Default Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="customerDefaultEmail"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("customerDefaultEmail", {
            required: "Default Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.customerDefaultEmail && errors.customerDefaultEmail.message}
        </small>
      </div>
      <div className="col-6 col-sm-4 mb-4">
        <label htmlFor="customerPhoneNumber" className="form-label">
          Phone <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="customerPhoneNumber"
          tabIndex={1}
          disabled={checkPageEditable()}
          {...register("customerPhoneNumber", {
            required: "Phone Number is required",
          })}
        />
        <small className="form-error-message">
          {errors?.customerPhoneNumber && errors.customerPhoneNumber.message}
        </small>
      </div>
      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>

      <AutocompleteAddressForm
        setAddressValues={setAddressValues}
        selectedOrderAddressValues={selectedCustomerAddressValues}
      />

      <ConfirmationModal
        isOpen={changeConfirmationModal}
        toggle={handleCustomerNameChangeCancel}
        onClick={handleCustomerNameChangeConfirm}
        headerText="Change Customer"
        bodyText="Are you sure you want to change to this customer?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="primary"
      />

      <ConfirmationModal
        isOpen={clearConfirmationModal}
        toggle={handleCustomerNameClearCancel}
        onClick={handleCustomerNameClearConfirm}
        headerText="Clear Customer"
        bodyText="Clearing this would remove all the data in this section. Continue?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="primary"
      />
    </>
  );
}

export default FormCustomerDetails;
