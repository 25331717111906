import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import UserForm from "./UserForm";
// import './css/contacts.css'
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import { fetchUsers, fetchUsersFiltered } from "../../services/userServices";
import { formatPhoneNumber } from "../../utils/formatUtils";
import _ from "lodash";
import {
  isSuperAdmin,
  isNTMSUSer,
  isAccountAdmin,
  isAccountUser,
} from "../../utils/roleUtils";
import { useSelector } from "react-redux";
import AuthorizationError from "../Commons/Authorization/AuthorizationError";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Users() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );

  const toggleFormDisplay = (isEdit = false, userId = null) => {
    if (isFormHidden) {
      setSelectedUserId(userId);
    } else {
      setSelectedUserId(null);
    }

    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && userList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "ProfilePicture",
      minWidth: 70,
      headerName: "",
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      cellClass: "no-borders",
    },
    {
      field: "Name",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Account",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Phone",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Mobile",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Email",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [userList, setUserList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    fillUserList();
    if (!isAccountUser(userType)) {
      focusOnOpenOrCloseButton();
    }
  }, []);

  useEffect(() => {
    let data = [];
    _.each(userList, (user, index) => {
      data.push({
        "#": index + 1,
        ProfilePicture: { url: `/images/profile-picture-fallback.png` },
        Name: `${user.firstName} ${user.lastName}`,
        Email: user.email,
        Account: user.account.accountName,
        Phone: user.phone,
        Mobile: user.cell,
        // 'Customer': user.customer.customerName,
        PhoneAndMobile: { phone: user.phone, mobile: user.cell },
        NameAndEmail: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
        Edit: { id: user.userId, btnClass: "btn-color-8" },
      });
    });
    setRowData(data);
  }, [userList]);

  useEffect(() => {
    fillSelectedUser(selectedUserId);
  }, [selectedUserId]);
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    if (isAccountAdmin(userType)) {
      params.columnApi.setColumnVisible("Account", false);
    }
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillUserList = async () => {
    let response = null;
    if (isSuperAdmin(userType)) {
      response = await fetchUsers();
    } else if (isNTMSUSer(userType)) {
      if (selectedAccountState.accountId != null) {
        response = await fetchUsersFiltered(selectedAccountState.accountId);
      } else {
        response = await fetchUsersFiltered(accountId);
      }
    } else if (isAccountAdmin(userType)) {
      response = await fetchUsersFiltered(accountId);
    }
    setUserList(response);
    setIsLoading(false);
  };

  const fillSelectedUser = (id) => {
    const user = _.find(userList, { userId: id });
    setSelectedUser(user);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      {isAccountUser(userType) && <AuthorizationError />}
      {!isAccountUser(userType) && (
        <>
          <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title={
                  isFormHidden ? "Users" : isFormEdit ? "Edit User" : "New User"
                }
                subtitle={"users"}
                dataCount={userList.length}
                filterData={filterData}
                onClickAdd={toggleFormCancelDisplay}
                onClickCancel={toggleFormCancelDisplay}
                isFormHidden={isFormHidden}
                openOrCloseButtonRef={openOrCloseButtonRef}
              />
            </div>
          </div>
          <div className="content-body-container row mt-3">
            <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
              {(userList?.length > 0) ?
                <div
                  className={`ag-theme-alpine content-section-container color-8-grid color-8-section p-0`}
                >
                  <AgGridReact
                    className="no-header"
                    rowData={rowData}
                    columnDefs={columnDefs}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onColumnSizeChanged={onColumnsSizeChanged}
                    onGridSizeChanged={onGridSizeChanged}
                    rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                  ></AgGridReact>
                </div>
                :
                <NoData color='color-8' content='user' />
              }
            </div>
            <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
              <UserForm
                isFormEdit={isFormEdit}
                selectedUser={selectedUser}
                isFormHidden={isFormHidden}
                fillUserList={fillUserList}
                toggleFormDisplay={toggleFormDisplay}
                setIsFormDirty={setIsFormDirty}
                modal={modal}
                setModal={setModal}
                toggle={toggle}
                focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Users;
