import { AccessTime, CalendarMonth } from "@mui/icons-material";
import _ from "lodash";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { COUNTRY, PROVINCE_STATE } from "../../../constants";
import AutocompleteAddressForm from "./AutocompleteAddressForm";

function PickDropForm({ stopType, formId, control, register, setValue }) {
  const { formState } = useForm();
  const { errors } = formState;

  const [selectedPickDropAddressValues, setSelectedPickDropAddressValues] =
    useState({});

  const setPickDropAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedProvinceState = _.find(PROVINCE_STATE, (provinceState) => {
      return provinceState.label === selectedSuggestion?.properties?.state;
    });

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value === (selectedProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue(
      `pickDrop[${formId}].address1`,
      selectedSuggestion?.properties?.address_line1
    );
    setValue(
      `pickDrop[${formId}].address2`,
      selectedSuggestion?.properties?.address_line2
    );
    setValue(`pickDrop[${formId}].city`, selectedSuggestion?.properties?.city);
    setValue(
      `pickDrop[${formId}].postal`,
      selectedSuggestion?.properties?.postcode
    );
    setValue(`pickDrop[${formId}].state`, selectedProvinceState);
    setValue(`pickDrop[${formId}].country`, selectedCountry);
    setValue(
      `pickDrop[${formId}].latitude`,
      `${selectedSuggestion?.properties?.lat}`
    );
    setValue(
      `pickDrop[${formId}].longitude`,
      `${selectedSuggestion?.properties?.lon}`
    );
    setValue(`pickDrop[${formId}].stopType`, stopType);
  };

  const setPickupAddressNotes = (addressNotes) => {
    setValue("pickupAddressNotes", addressNotes);
  };

  return (
    <>
      <div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 mb-3">
              <label
                htmlFor={`pickDrop[${formId}].name`}
                className="form-label"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id={`pickDrop[${formId}].name`}
                tabIndex={1}
                value={"-"}
                {...register(`pickDrop[${formId}].name`, {
                  required: "Name is required",
                })}
              />
              <small className="form-error-message">
                {errors?.pickDrop?.[formId]?.name &&
                  errors.pickDrop[formId].name.message}
              </small>
            </div>
            <div className="col-12">
              <label className="form-label">
                {stopType === 1 ? "Pickup" : "Dropoff"} Details
              </label>
            </div>
          </div>

          <div className="row mb-4">
            <AutocompleteAddressForm
              setAddressValues={setPickDropAddressValues}
              setAddressNotes={setPickupAddressNotes}
              selectedOrderAddressValues={selectedPickDropAddressValues}
            />
          </div>

          {/* Start Date - Time */}
          <div className="row mb-2">
            <div className="col-12 mb-1">
              <div className="d-flex flex-row align-items-center">
                <label className="form-label simple mb-0">
                  Start Date / Start Time:
                </label>
                <div className="ms-3">
                  <Controller
                    control={control}
                    name={`pickDrop[${formId}].startDate`}
                    register={register}
                    rules={{
                      required: `${
                        stopType === 1 ? "Pickup" : "Dropoff"
                      } Start Date is required`,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="datepicker-field"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="yyyy-MM-dd"
                        id={`pickDrop[${formId}].startDate`}
                        tabIndex={8}
                      />
                    )}
                  />
                  <small className="form-error-message">
                    {errors?.pickDrop?.[formId]?.startDate &&
                      errors.pickDrop[formId].startDate.message}
                  </small>
                  <small className="form-error-message">
                    {errors?.pickDrop?.[formId]?.startTime &&
                      errors.pickDrop[formId].startTime.message}
                  </small>
                </div>
                <div className="ms-2">
                  <CalendarMonth />
                </div>
                <div className="ms-4">
                  <Controller
                    control={control}
                    name={`pickDrop[${formId}].startTime`}
                    register={register}
                    rules={{
                      required: `${
                        stopType === 1 ? "Pickup" : "Dropoff"
                      } Start Time is required`,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="datepicker-field"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        id={`pickDrop[${formId}].startTime`}
                        tabIndex={8}
                      />
                    )}
                  />
                </div>
                <div className="ms-2">
                  <AccessTime />
                </div>
              </div>
            </div>
          </div>

          {/* End Date - Time */}
          <div className="row">
            <div className="col-12 mb-1">
              <div className="d-flex flex-row align-items-center">
                <label className="form-label simple mb-0 me-2">
                  End Date / End Time:
                </label>
                <div className="ms-4">
                  <Controller
                    control={control}
                    name={`pickDrop[${formId}].endDate`}
                    register={register}
                    rules={{
                      required: `${
                        stopType === 1 ? "Pickup" : "Dropoff"
                      } End Date is required`,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="datepicker-field"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="yyyy-MM-dd"
                        id={`pickDrop[${formId}].endDate`}
                        tabIndex={8}
                      />
                    )}
                  />
                  <small className="form-error-message">
                    {errors?.pickDrop?.[formId]?.endDate &&
                      errors.pickDrop[formId].endDate.message}
                  </small>
                  <small className="form-error-message">
                    {errors?.pickDrop?.[formId]?.endTime &&
                      errors.pickDrop[formId].endTime.message}
                  </small>
                </div>
                <div className="ms-2">
                  <CalendarMonth />
                </div>
                <div className="ms-4">
                  <Controller
                    control={control}
                    name={`pickDrop[${formId}].endTime`}
                    register={register}
                    rules={{
                      required: `${
                        stopType === 1 ? "Pickup" : "Dropoff"
                      } End Time is required`,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="datepicker-field"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        id={`pickDrop[${formId}].endTime`}
                        tabIndex={8}
                      />
                    )}
                  />
                </div>
                <div className="ms-2">
                  <AccessTime />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PickDropForm;
