import React, { useState } from "react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import { isAccountUser } from "../../utils/roleUtils";
import { useSelector } from "react-redux";
import PayrollSettingsForm from "./PayrollSettingsForm";
import AuthorizationError from "../Commons/Authorization/AuthorizationError";

function PayrollSettings() {
  const userType = useSelector((state) => state.auth.user.userType);
  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  // useStates
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(null);
  return (
    <div>
      {isAccountUser(userType) && <AuthorizationError />}
      {!isAccountUser(userType) && (
        <>
          <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title="Payroll Settings"
                subtitle="payroll settings"
                hideSearch="true"
                hideTotal="true"
                hideButton={true}
                hideSubtitle={true}
                parentNavItem={parentNavItem}
              />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`col-12 mb-2`}>
              <PayrollSettingsForm
                isFormEdit={isFormEdit}
                setIsFormEdit={setIsFormEdit}
                setIsFormDirty={setIsFormDirty}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PayrollSettings;
