import React from 'react'
import { formatCurrency } from '../../utils/formatUtils';
function QuoteDetailFooter({
  updateNoteStatus,
  note,
  editable,
  subtotal,
  discount,
  total,
  tax,
  printMode,
  quoteData,
  register
}) {
  return (
    <div className="row quote-footer">
      <div className={`col-12 ${(printMode) ? 'd-none' : ''}`}>
        <p className='mb-0 fw-bold non-printable'>Notes:</p>
      </div>
      <div className="col-6">
        {editable ?
          <div className={`form-group non-printable ${(!editable || printMode) ? 'd-none' : ''}`}>
            <textarea
              className="form-control"
              id="notes"
              rows="5"
              onChange={updateNoteStatus}
              defaultValue={quoteData?.notes || ''}
              disabled={!editable}
              {...register("notes")}
            />
          </div>
          :
          <div className='notes-container'>
            {note || '-'}
          </div>
        }

      </div>
      {(printMode) && (
        <div className='col-12'>
          <p className='mb-0 fw-bold'>Notes:</p>
          <div className='notes-container'>
            {note || '-'}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuoteDetailFooter