import { setFormPendingCompletion, setFormPendingFunction } from "../redux/global/globalReducer";
import _ from 'lodash';


export const updateFormDataOnExit = async (
  dispatch,
  hasDirtyFields,
  setIsFormDirty,
  isFormEdit,
  emptyErrorFields,
  handleSubmit,
  onSubmit,
  keepAlive = false,
) => {
  
  if (hasDirtyFields) {
    dispatch(setFormPendingCompletion(true));
    setIsFormDirty(true);
    if (emptyErrorFields) {
      dispatch(setFormPendingFunction(async () => {
        var succesfulSubmit = false;
        await handleSubmit(async payload => {
          succesfulSubmit = await onSubmit(payload, keepAlive);
        })();
        return succesfulSubmit;
      }));
    } else {
      dispatch(setFormPendingFunction(null));
    }
  } else {
    dispatch(setFormPendingCompletion(false));
    setIsFormDirty(false);
  }
}

export const updateFormAndGridDataOnExit = async (
  dispatch,
  dirtyColumns,
  hasDirtyFields,
  setIsFormDirty,
  isFormEdit,
  emptyErrorFields,
  handleSubmit,
  onSubmit,
  keepAlive = false,
) => {
  if (dirtyColumns || hasDirtyFields) {
    dispatch(setFormPendingCompletion(true));
    setIsFormDirty(true);
    if (emptyErrorFields) {
      dispatch(setFormPendingFunction(async () => {
        var succesfulSubmit = false;
        await handleSubmit(async payload => {
          succesfulSubmit = await onSubmit(payload, keepAlive);
        })();
        return succesfulSubmit;
      }));
    } else {
      dispatch(setFormPendingFunction(null));
    }
  } else {
    dispatch(setFormPendingCompletion(false));
    setIsFormDirty(false);
  }
}