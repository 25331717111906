import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTruckMaintenance = async () => {
  return get({ path: 'TruckMaintenance' });
}

export const fetchTruckMaintenanceFiltered = async () => {
  return get({ path: `TruckMaintenance/filtered` });
}

export const addTruckMaintenance = async (payload, keepAlive = false) => {
  return post({ path: 'TruckMaintenance', body: payload, keepAlive: keepAlive });
}

export const updateTruckMaintenance = async (payload, keepAlive = false) => {
  return patch({ path: `TruckMaintenance/${payload.truckMaintenanceId}`, body: payload, keepAlive: keepAlive });
}

export const fetchTruckMaintenanceByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchTruckMaintenanceFiltered(accountId) : await fetchTruckMaintenanceFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchTruckMaintenanceFiltered(accountId);
  }
  
  return await fetchTruckMaintenance();
}