import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { fetchItemList } from '../../services/quoteServices';
import { addExpense, updateExpense } from '../../services/expenseServices';
import { fetchGLCodesFiltered } from '../../services/glCodeServices';
import { removeNumberFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { EXPENSE_ITEM_TYPES } from '../../constants';
import { isNTMSUSer } from '../../utils/roleUtils';
import { setNotificationData } from '../../redux/global/globalReducer';
import { removeNonDigits } from '../../utils/formatUtils';
import moment from 'moment';
import FormTabs from '../Commons/Layouts/FormTabs';
import PrimaryDetails from './PrimaryDetails';
import { setFormPendingCompletion } from '../../redux/global/globalReducer';
import dayjs from 'dayjs';
import { updateFormDataOnExit } from '../../utils/formUtils';


function ExpenseForm({
  isFormEdit = false,
  selectedExpense = {},
  isFormHidden,
  fillExpenseList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  accountProvince,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  var options = []
  const accountId = useSelector((state) => state.auth.user.accountId);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    getValues,
    setFocus,
    trigger,
    formState
  } = useForm({
    defaultValues: {
      expenseDate: dayjs()
    }
  });
  const { dirtyFields, errors, isDirty } = formState;
  const [isManualFormHidden] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [gLCode, setGLCode] = useState("");
  const [gLCodeList, setGLCodeList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [newOption, setNewOption] = useState('');
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null);
  const [selectedExpenseTemplate, setSelectedExpenseTemplate] = useState(null);
  const [isDropdownDisabled, setDropdownDisabled] = useState(true);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const userType = useSelector((state) => state.auth.user.userType);
  const customerState = useSelector((state) => state.customer);
  const isInitialRender = useRef(true);

  if (itemList !== undefined) {
    options = itemList.map((item, index) => {
      let value = "";
      let itemType = "";

      if (item.expenseItemId !== undefined) {
        value = EXPENSE_ITEM_TYPES[0].label + "-" + item.expenseItemId;
        itemType = EXPENSE_ITEM_TYPES[0].value;
      } else if (item.productMaterialId !== undefined) {
        value = EXPENSE_ITEM_TYPES[1].label + "-" + item.productMaterialId;
        itemType = EXPENSE_ITEM_TYPES[1].value;
      } else if (item.labourId !== undefined) {
        value = EXPENSE_ITEM_TYPES[2].label + "-" + item.labourId;
        itemType = EXPENSE_ITEM_TYPES[2].value;
      }

      return {
        index: index,
        value,
        itemType,
        label:
          item.labourDescription !== undefined
            ? item.labourDescription
            : item.expenseDescription,
      };
    });
  }

  const expenseDateRef = useRef(null);

  //Field watchers
  const quantity = watch("quantity");
  const quantityMobile = watch("quantityMobile");
  const rate = watch("rate");
  const taxRate = watch("taxRate");
  const discount = watch("discount");
  const taxType = watch("taxType");
  const expenseTemplate = watch("expenseTemplate");

  useEffect(() => {
    if (isFormEdit) {
      setGLCode(parseInt(selectedExpense["glCodeId"]));
      setValue("taxType", parseInt(selectedExpense["taxType"]));
      setValue("paymentType", parseInt(selectedExpense["paymentType"]));
      setValue("taxRate", parseFloat(selectedExpense["taxRate"]) * 100);
      setValue("glCodeId", parseInt(selectedExpense["glCodeId"]));
      setValue("quantity", parseFloat(selectedExpense["quantity"]));
      setValue("subtotal", parseFloat(selectedExpense["subtotal"]).toFixed(2));
      setValue("total", parseFloat(selectedExpense["total"]).toFixed(2));
      setValue("totalTax", selectedExpense["totalTax"].toFixed(2));
      setValue("rate", parseFloat(selectedExpense["rate"]).toFixed(2));
      setValue("discount", parseFloat(selectedExpense["discount"]).toFixed(2));
      setValue("expenseDate", dayjs(selectedExpense["expenseDate"]));
      setValue("description", (selectedExpense["description"]));
      setValue("referenceId", (selectedExpense["referenceId"]));
      setValue("company", (selectedExpense["companyName"]));
      setDropdownDisabled(selectedExpense["itemId"] > 0 ? false : true);


      if (selectedExpense["itemId"] > 0) {
        let value = "";
        const item = _.find(itemList, (item) => {
          switch (selectedExpense["itemType"]) {
            case 1:
              return item.expenseItemId === selectedExpense["itemId"];
            case 2:
              return item.productMaterialId === selectedExpense["itemId"];
            case 3:
              return item.labourId === selectedExpense["itemId"];
            default:
              setDropdownDisabled(true);
              return null;
          }
        });

        if (_.isNil(item)) {
          return;
        }

        if (item?.expenseItemId !== undefined) {
          value = item?.expenseItemId;
        } else if (item?.productMaterialId !== undefined) {
          value = item?.productMaterialId;
        } else if (item?.labourId !== undefined) {
          value = item?.labourId;
        }

        setSelectedExpenseTemplate({
          value,
          label:
            item?.labourDescription !== undefined
              ? item?.labourDescription
              : item?.expenseDescription,
        });
      }

      const selectedCompany = _.find(companyOptions, (companyOption) => {
        return companyOption.label === selectedExpense.companyName;
      });

      setSelectedCompanyOption({
        value: selectedCompany?.value || 0,
        label: selectedCompany?.label || selectedExpense.companyName,
      });
    } else {
      setGLCode("");
      setValue("glCodeId", "");
      setSelectedCompanyOption(null);
      setValue("company", "");
      reset();
      setValue("discount", 0);
      setValue("rate", 0);
      setValue("subtotal", 0);
      setValue("totalTax", 0.0);
    }
  }, [selectedExpense["expenseId"]]);

  /* useEffects */
  useEffect(() => {
    clearErrors();
    fillItemList();
    fillGLCode();
  }, []);

  // clears values on form type change
  useEffect(() => {
    reset();
    setValue("quoteItemId", 0);
    setValue("quantity", 0);
    setValue("quantityMobile", 0);
    setValue("discount", 0);
    setValue("rate", 0);
    setValue("taxRate", 0);
    setGLCode("");
    setValue("glCodeId", "");
    setSelectedCompanyOption(null);
    setValue("company", "");
    setDropdownDisabled(true);
    setSelectedExpenseTemplate(null);
  }, [isManualFormHidden])

  useEffect(() => {
    clearErrors();
    reset();
    setValue("taxType", 0);
    setValue("taxRate", 0);
    setValue("rate", 0);
    setValue("discount", 0);
    setValue("totalTax", 0);
    setValue("subtotal", 0);
    setValue("total", 0);
    setGLCode("");
    setValue("glCodeId", "");
    setSelectedCompanyOption(null);
    setValue("company", "");
    setDropdownDisabled(true);
    setSelectedExpenseTemplate(null);

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("expenseDate");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateForm();
  }, [formState])

  useEffect(() => {
    if (!_.isEmpty(customerState)) {
      const customers = customerState.data.map((customer, index) => ({
        value: customer.customerId,
        label: customer.customerName
      }));
      setCompanyOptions(customers);
    }
  }, [customerState]);

  const fillItemList = async () => {
    var currentUserId = accountId;
    if (isNTMSUSer(userType) && selectedAccountState.accountId !== null) {
      currentUserId = selectedAccountState.accountId
    }
    const data = await fetchItemList(currentUserId)
    const labour = data.labour
    const material = data.productMaterialItem
    const expense = data.expenseItem
    var items = {}
    items = [...labour, ...material, ...expense]
    setItemList(items)
  }

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD);
    const response = await fetchGLCodesFiltered();

    setGLCodeList(response);
  }

  const numberCheck = (value) => {
    return value > 0 ? value : 0;
  }

  // Calculations when creating an item manually
  useEffect(() => {
    // var taxRateCheck = 0
    const quantityCheck = numberCheck(quantity);
    const rateCheck = numberCheck(parseFloat(removeNumberFormat(rate)));
    const discountCheck = numberCheck(parseFloat(removeNumberFormat(discount)));
    const taxRateCheck = numberCheck(parseFloat(removeNumberFormat(taxRate) / 100));
    const subtotal = parseFloat(quantityCheck * rateCheck) - discountCheck;
    const totalTax = parseFloat(subtotal * taxRateCheck);
    const total = parseFloat(subtotal + totalTax);
    setValue("subtotal", numberCheck(subtotal).toFixed(2));
    setValue("total", numberCheck(total).toFixed(2));
    setValue("totalTax", numberCheck(totalTax).toFixed(2));
  }, [quantity, rate, taxRate, discount])

  useEffect(() => {
    setValue("quantity", quantityMobile);
  }, [quantityMobile])

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;
    const expenseTemplate = payload.expenseTemplate;
    _.set(payload, 'rate', removeNumberFormat(payload.rate));
    _.set(payload, 'discount', removeNumberFormat(payload.discount));
    _.set(payload, 'subtotal', removeNumberFormat(payload.subtotal));
    _.set(payload, 'total', removeNumberFormat(payload.total));
    _.set(payload, 'totalTax', removeNumberFormat(payload.totalTax));
    _.set(payload, 'taxRate', removeNumberFormat(payload.taxRate) / 100);
    _.set(payload, "expenseTemplate", null);
    _.set(payload, 'companyName', selectedCompanyOption.label);
    _.set(payload, 'customerId', selectedCompanyOption.value > 0 ? selectedCompanyOption.value : null);

    _.set(
      payload,
      "itemId",
      !isDropdownDisabled
        ? removeNonDigits(selectedExpenseTemplate?.value) || 0
        : 0
    );

    _.set(
      payload,
      "itemType",
      !isDropdownDisabled ? selectedExpenseTemplate?.itemType || 0 : 0
    );

    _.set(
      payload,
      "customerId",
      selectedCompanyOption.value > 0 ? selectedCompanyOption.value : null
    );
    var currentDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'currentDate', currentDate);

    if (isFormEdit) {
      _.set(payload, 'expenseId', selectedExpense.expenseId);
      _.set(payload, 'accountId', accountId);
      if (isNTMSUSer(userType) && selectedAccountState.accountId !== null) {
        _.set(payload, 'accountId', selectedAccountState.accountId);
      }

      var expenseDate = dayjs(payload.expenseDate).format('YYYY-MM-DD');
      _.set(payload, 'expenseDate', expenseDate);

      response = await updateExpense(payload, keepAlive);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } expense.`,
        })
      );

    } else {
      _.set(payload, 'balance', removeNumberFormat(payload.total));
      _.set(payload, 'expenseId', 0);

      var expenseDate = dayjs(payload.expenseDate).format('YYYY-MM-DD');
      _.set(payload, 'expenseDate', expenseDate);

      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addExpense(payload, keepAlive);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } expense.`,
        })
      );
    }
    if (response.ok) {
      fillExpenseList();
      reset({}, {keepValues: true});
      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      dispatch(setFormPendingCompletion(false));
      return true;
    }
    return false;
  }



  const handleCompanyNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // Check if it already exists
      const companyExists = _.some(companyOptions, item => _.includes(item.label.toLowerCase(), newOption.toLowerCase()));
      if (!companyExists) {
        // Create a new option from the typed characters
        const newOptionObject = { value: null, label: newOption };

        // Update the options state with the new option
        setCompanyOptions([...companyOptions, newOptionObject]);
        // Set the selected option to the newly added option
        setSelectedCompanyOption(newOptionObject);

        // Clear the input field
        setNewOption('');
      }
    }
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-4-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              accountProvince={accountProvince}
              isFormEdit={isFormEdit}
              selectedExpense={selectedExpense}
              trigger={trigger}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              options={options}
              itemList={itemList}
              gLCode={gLCode}
              setGLCode={setGLCode}
              companyOptions={companyOptions}
              setDropdownDisabled={setDropdownDisabled}
              isDropdownDisabled={isDropdownDisabled}
              selectedCompanyOption={selectedCompanyOption}
              setSelectedCompanyOption={setSelectedCompanyOption}
              taxRate={taxRate}
              selectedExpenseTemplate={selectedExpenseTemplate}
              gLCodeList={gLCodeList}
            />
          </div>
        </div>
      ),
    }
  ];

  return (
    <form id="account-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
      <FormTabs
        items={tabItems}
        tabClassName="color-4"
        isFormHidden={isFormHidden}
      />
      {!isFormEdit && 
      <div className="row mt-5">
        <div className="col-12">
          <div className="d-flex flex-row align-items-center">
            <button className={`ms-auto btn btn-primary`} type="submit">
              Save
            </button>
          </div>
        </div>
      </div>}
    </form>
  )
}

export default ExpenseForm