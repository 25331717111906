import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditPayrollRenderer from "../Templates/CellRenderers/PayrollRuns/EditPayrollRunRenderer";
import { fetchPayrollRunsFiltered } from "../../services/payrollRunServices";
import { formatDate, formatDateOnly } from "../../utils/formatUtils";
import _ from "lodash";
import StatusRenderer from "../Templates/CellRenderers/PayrollRuns/StatusRenderer";
import { isNTMSUSer, isAccountUser } from "../../utils/roleUtils";
import { useDispatch, useSelector } from "react-redux";
import PayrollRunForm from "./PayrollRunForm";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AuthorizationError from "../Commons/Authorization/AuthorizationError";
import { fetchPayrollSettingsCreation } from "../../services/payrollSettingsServices";
import LoadingPage from "../Commons/Authorization/LoadingPage";
import SetupSettings from "../Commons/Authorization/SetupSettings";
import { fetchAccountingSettings } from "../../services/accountingSettingsServices";
import { fetchVoidLastPayrollRun } from "../../services/payrollRunServices";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { GRID_CONSTANTS, PAYROLL_RUN_STATUS } from "../../constants";
import { setNotificationData } from "../../redux/global/globalReducer";
import NoData from "../Commons/Layouts/NoData";
import { enumValueFormatter, getQuickFilterValue } from "../../utils/formatUtils";


function PayrollRuns() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const [rowData, setRowData] = useState([]);
  const [disabledAddButton, setDisabledAddButton] = useState(true);
  const [disabledVoidButton, setDisabledVoidButton] = useState(true);
  const [voidPayrollRunModal, setVoidPayrollRunModal] = useState(false);
  const voidPayrollRunToggle = () =>
    setVoidPayrollRunModal(!voidPayrollRunModal);

  // useRefs
  const gridRef = useRef();

  const toggleFormDisplay = (isEdit = false, contactId = null) => {
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    if (!isFormHidden) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const fillPayrollRunList = async () => {
    let response = null;
    let hasDraft = false;
    if (!isAccountUser(userType)) {
      if (isNTMSUSer(userType)) {
        response =
          selectedAccountState.accountId === null
            ? await fetchPayrollRunsFiltered(accountId)
            : await fetchPayrollRunsFiltered(selectedAccountState.accountId);
      } else {
        response = await fetchPayrollRunsFiltered(accountId);
      }

      response.map((item, index) => {
        if (item.statusRun === false) {
          hasDraft = true;
        }
        return hasDraft;
      });
    }

    const payrollRunCount = await response.length;

    setDisabledAddButton(hasDraft);
    setDisabledVoidButton(!(!hasDraft && payrollRunCount > 0));
    setPayrollRunList(response);
    setListIsLoading(false);
  };

  const defaultColumnDefs = [
    {
      field: "Start Date",
      minWidth: 150,
      cellClass: "grid-column",
    },
    {
      field: "End Date",
      minWidth: 150,
      cellClass: "grid-column",
    },
    {
      field: "Run Date",
      minWidth: 150,
      cellClass: "grid-column gray",
    },
    {
      field: "Created",
      minWidth: 150,
      cellClass: "grid-column gray",
    },
    {
      field: "Status",
      minWidth: 150,
      cellRenderer: StatusRenderer,
      cellClass: "grid-column center",
      valueFormatter: (params) => {
        return enumValueFormatter(params, PAYROLL_RUN_STATUS);
      },
      getQuickFilterText: (params) => {
        const statusValue = params.value;
        return getQuickFilterValue(statusValue, PAYROLL_RUN_STATUS);
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditPayrollRenderer,
      resizable: false,
      cellRendererParams: {
        gridRef,
        rowData,
        setRowData,
        toggleFormDisplay,
        fillPayrollRunList,
      },
    },
  ];

  // useStates
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [payrollRunList, setPayrollRunList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [accountingSettings, setAccountingSettings] = useState();
  const [payrollSettings, setPayrollSettings] = useState();
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [listIsLoading, setListIsLoading] = useState(true);
  const [settingsIsLoading, setSettingsIsLoading] = useState(true);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    fillAccountingAndPayrollSettings();
    fillPayrollRunList();
  }, []);

  const openOrCloseButtonRef = useRef(null);

  useEffect(() => {
    let data = [];
    _.each(payrollRunList, (payrollRun, index) => {
      data.push({
        id: payrollRun.payrollRunId,
        index: index + 1,
        "Start Date": formatDateOnly(payrollRun.startDate),
        "End Date": formatDateOnly(payrollRun.endDate),
        "Run Date": formatDateOnly(payrollRun.payrollRunDate),
        'Type': payrollRun.payrollRunType,
        Status: payrollRun.statusRun,
        Created: formatDate(payrollRun.createdTimeStamp),
        // 'Edit': { id: contact.contactId }
      });
    });
    setRowData(data);
  }, [payrollRunList]);

  useEffect(() => {
    if (
      !listIsLoading &&
      (!_.isEmpty(payrollSettings) || !_.isEmpty(accountingSettings))
    ) {
      setTimeout(() => {
        focusOnOpenOrCloseButton();
      }, 150);
    }
  }, [listIsLoading]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillAccountingAndPayrollSettings = async () => {
    if (isNTMSUSer(userType)) {
      const response =
        selectedAccountState.accountId === null
          ? await fetchAccountingSettings(accountId)
          : await fetchAccountingSettings(selectedAccountState.accountId);
      setAccountingSettings(await response);

      const secondResponse =
        selectedAccountState.accountId === null
          ? await fetchPayrollSettingsCreation(accountId)
          : await fetchPayrollSettingsCreation(selectedAccountState.accountId);
      setPayrollSettings(await secondResponse);
    } else {
      const response = await fetchAccountingSettings(accountId);
      setAccountingSettings(await response);

      const secondResponse = await fetchPayrollSettingsCreation(accountId);
      setPayrollSettings(await secondResponse);
    }
    setSettingsIsLoading(false);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  };

  const voidLastPayrollRun = async () => {
    var response = null;

    if (isNTMSUSer(userType)) {
      response =
        selectedAccountState.accountId === null
          ? await fetchVoidLastPayrollRun({}, accountId)
          : await fetchVoidLastPayrollRun({}, selectedAccountState.accountId);
    } else {
      response = fetchVoidLastPayrollRun({});
    }

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully voided" : "Failed to void"
          } last payroll run.`,
      })
    );
    await fillPayrollRunList();
    voidPayrollRunToggle();
  };

  const loadingCheck = () => {
    return listIsLoading && settingsIsLoading;
  }

  const settingsCheck = () => {
    return (_.isEmpty(payrollSettings) || _.isEmpty(accountingSettings));
  }



  const parentNavItem = {
    title: "Payroll Runs",
    link: "/payroll-runs",
  };

  return (
    <div>
      {isAccountUser(userType) && <AuthorizationError />}
      {!isAccountUser(userType) && (
        <>
          <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title={isFormHidden ? "Payroll Runs" : "New Payroll Run"}
                subtitle={"payroll runs"}
                dataCount={payrollRunList.length}
                onClickAdd={toggleFormCancelDisplay}
                onClickCancel={toggleFormCancelDisplay}
                customAddButtonLabel="Add Payroll Run"
                isFormHidden={isFormHidden}
                openOrCloseButtonRef={openOrCloseButtonRef}
                filterData={filterData}
                hideTotal={settingsCheck()}
                hideSearch={settingsCheck()}
                hideButton={settingsCheck()}
                disabledAddButton={disabledAddButton}
                customButtons={[
                  {
                    label: "Void Last Payroll Run",
                    onClick: voidPayrollRunToggle,
                    className: "btn-danger",
                    disabled: disabledVoidButton,
                    shown: !settingsCheck()
                  },
                ]}
                parentNavItem={parentNavItem}
              />
            </div>
            {disabledAddButton && (
              <ReactTooltip
                anchorId="btn-add-new"
                className="tooltip-container"
                place="top"
                content="There can only be one drafted payroll run at a time"
              />
            )}
          </div>
          {(settingsIsLoading && listIsLoading) &&
            <LoadingPage/>
          }
          {(settingsCheck()) && !settingsIsLoading && !listIsLoading &&
            <SetupSettings
              payrollSettingsIsEmpty={_.isEmpty(payrollSettings)}
              accountingSettingsIsEmpty={_.isEmpty(accountingSettings)}
            />
          }
          {!settingsCheck() && !settingsIsLoading && !listIsLoading &&
            <div className="content-body-container row mt-3">
              <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
                {(payrollRunList?.length > 0) ?
                  <div
                    className={`ag-theme-alpine content-section-container color-4-grid color-4-section p-0`}
                  >
                    <AgGridReact
                      className="no-header"
                      rowData={rowData}
                      columnDefs={columnDefs}
                      ref={gridRef}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                      onColumnSizeChanged={onColumnsSizeChanged}
                      onGridSizeChanged={onGridSizeChanged}
                      rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                      rowSelection={"single"}
                    ></AgGridReact>
                  </div>
                  :
                  <NoData color='color-4' content='payroll run' />
                }
              </div>
              <div className={`col-12  ${isFormHidden ? "d-none" : ""}`}>
                <PayrollRunForm
                  isFormEdit={isFormEdit}
                  isFormHidden={isFormHidden}
                  toggleFormDisplay={toggleFormDisplay}
                  setIsFormDirty={setIsFormDirty}
                  modal={modal}
                  setModal={setModal}
                  toggle={toggle}
                  payrollRunList={payrollRunList}
                  payrollSettings={payrollSettings}
                />
              </div>
            </div>
          }
          <ConfirmationModal
            isOpen={voidPayrollRunModal}
            toggle={voidPayrollRunToggle}
            onClick={voidLastPayrollRun}
            headerText="Void Last Payroll Run"
            bodyText="Are you sure you want to void the last payroll run?"
            confirmButtonText="Yes"
            cancelButtonText="No"
            actionType="delete"
          />
        </>
      )}
    </div>
  );
}

export default PayrollRuns;
