
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Layout from "./Layout";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./ag-grid-custom-theme.css";
import './custom.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "react-tooltip/dist/react-tooltip.css";
import './scss/global.scss';
import "react-datepicker/dist/react-datepicker.css";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Auth0ProviderWithNavigate } from './providers/auth0-provider-with-navigate';
import Dashboard from './components/Dashboard/Dashboard';
import Quotes from './components/Quotes/Quotes';
import Customers from './components/Customers/Customers';
import Contacts from './components/Contacts/Contacts';
import Accounts from './components/Accounts/Accounts';
import ExpenseCategories from './components/ExpenseCategories/ExpenseCategories'
import EditQuotePage from './components/Quotes/EditQuotePage';
import ExpenseItemForm from './components/ExpenseCategories/ExpenseItemForm';
import GLCodes from './components/GLCodes/GLCodes';
import AccountingSettings from "./components/AccountingSettings/AccountingSettings";
import ChooseAccount from './components/Development/ChooseAccount';
import Settings from './components/Settings/Settings';
import Login from './components/Login/Login';
import Invoices from './components/Invoices/Invoices';
import EditInvoicePage from './components/Invoices/EditInvoicePage'
import Users from "./components/Users/Users";
import PayrollRuns from './components/PayrollRuns/PayrollRuns';
import EditPayrollPage from './components/PayrollRuns/EditPayrollPage'
import Expenses from './components/Expenses/Expenses';
import Payments from './components/Payments/Payments';
import LoginSuccess from "./components/LoginSuccess/LoginSuccess";
import ReportTemplates from './components/ReportTemplates/ReportTemplates';
import { store, persistor } from './redux/store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PayrollSettings from "./components/PayrollSettings/PayrollSettings";
import ClaimCodesSheet from "./components/ClaimCodesSheet/ClaimCodesSheet";
import ExpensePayments from "./components/ExpensePayments/ExpensePayments";
import ImageSettings from "./components/ImageSettings/ImageSettings";
import Drivers from "./components/Drivers/Drivers";
import Trucks from "./components/Trucks/Trucks";
import Trailers from "./components/Trailers/Trailers";
import TrucksMaintenance from "./components/TrucksMaintenance/TrucksMaintenance";
import TrailerMaintenance from "./components/TrailerMaintenance/TrailerMaintenance";
import Orders from "./components/Orders/Orders";
import Accessorials from "./components/Accessorials/Accessorials";
import Terminals from "./components/Terminals/Terminals";
import Legs from "./components/Legs/Legs";
import OrdersSettings from "./components/OrdersSettings/OrdersSettings";

export default function App() {
  const AccountGuard = () => {
    let isAuthenticated = false;
    const rawStorage = localStorage.getItem('persist:root');
    if (rawStorage) {
      const localStorage = JSON.parse(rawStorage);
      const authStorage = JSON.parse(localStorage.auth);
      isAuthenticated = authStorage?.isAuthenticated;
    }

    return (
      isAuthenticated ? <Outlet /> : <Navigate to="/login" />
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <Layout>
              <Routes>
                <Route element={<AccountGuard />}>
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/accounts' element={<Accounts />} />
                  <Route path='/customers' element={<Customers />} />
                  <Route path='/contacts' element={<Contacts />} />
                  <Route path='/expense-item-form' element={<ExpenseItemForm />} />
                  <Route path="/quotes">
                    <Route index element={<Quotes />} />
                    <Route path="/quotes/edit-quote" element={<EditQuotePage />} />
                  </Route>
                  <Route path="/invoices">
                    <Route index element={<Invoices />} />
                    <Route path="/invoices/edit-invoice" element={<EditInvoicePage />} />
                  </Route>
                  <Route path="/payroll-runs">
                    <Route index element={<PayrollRuns />} />
                    <Route path="/payroll-runs/edit-payroll-run" element={<EditPayrollPage />} />
                  </Route>
                  <Route path='/expenses' element={<Expenses />} />
                  <Route path='/terminals' element={<Terminals />} />
                  <Route path="/trucks">
                    <Route path='/trucks/trucks-assets' element={<Trucks />} />
                    <Route path='/trucks/trucks-maintenance' element={<TrucksMaintenance />} />
                  </Route>
                  <Route path="/trailers">
                    <Route path='/trailers/trailers-assets' element={<Trailers />} />
                    <Route path='/trailers/trailers-maintenance' element={<TrailerMaintenance />} />
                  </Route>
                  <Route path="/team">
                    <Route path='/team/users' element={<Users />} />
                    <Route path='/team/drivers' element={<Drivers />} />
                  </Route>
                  <Route path="/payments">
                    <Route path='/payments/invoice-payments' element={<Payments />} />
                    <Route path='/payments/expense-payments' element={<ExpensePayments />} />
                  </Route>
                  <Route path="/settings">
                    <Route index element={<Settings />} />
                    <Route path="/settings/gl-codes" element={<GLCodes />} />
                    <Route path='/settings/report-templates' element={<ReportTemplates />} />
                    <Route path='/settings/accounting-settings' element={<AccountingSettings />} />
                    <Route path='/settings/orders-settings' element={<OrdersSettings />} />
                    <Route path='/settings/payroll-settings' element={<PayrollSettings />} />
                    <Route path='/settings/expense-categories' element={<ExpenseCategories />} />
                    <Route path='/settings/image-settings' element={<ImageSettings />} />
                    <Route path='/settings/accessorials' element={<Accessorials />} />
                  </Route>
                </Route>
                <Route exact path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/login-success' element={<LoginSuccess />} />
                <Route path='/dev-choose-account' element={<ChooseAccount />} />
                <Route path='/claim-codes' element={<ClaimCodesSheet />} />
                <Route path='/orders' element={<Orders />} />
                <Route path='/legs' element={<Legs />} />
              </Routes>
            </Layout>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement)
root.render(<App />);

