import { get, post, patch } from "./apiService";

export const fetchPayrollSettings = async (accountId, setIsFormEdit) => {
  return get({ path: `PayrollSettings` }).catch(e => {
    setIsFormEdit(false);
  });
}

export const fetchPayrollSettingsEmployee = async (accountId, setPayrollSettings) => {
  const response =  get({ path: `PayrollSettings` }).catch(e => {
    setPayrollSettings();
  });

  return response;
}

export const fetchPayrollSettingsCreation = async (accountId, setIsFormEdit) => {
  return get({ path: `PayrollSettings` })
}

export const addPayrollSettings = async (payload, keepAlive = false) => {
  return post({ path: 'PayrollSettings', body: payload, keepAlive: keepAlive });
}

export const updatePayrollSettings = async (payload, keepAlive = false) => {
  return patch({ path: `PayrollSettings/update/${payload.payrollSettingsId}`, body: payload, keepAlive: keepAlive });
}