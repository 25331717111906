import { get, post, patch } from "./apiService";

export const fetchContacts = async () => {
  return get({ path: 'Contact' });
}

export const fetchContactsFiltered = async (accountId) => {
  return get({ path: `Contact/filtered` });
}

export const addContact = async (payload, keepAlive = false) => {
  return post({ path: 'Contact', body: payload, keepAlive: keepAlive });
}

export const updateContact = async (payload, keepAlive = false) => {
  return patch({ path: `Contact/update/${payload.contactId}`, body: payload, keepAlive: keepAlive });
}