import React from "react";
import { QUOTE_ITEM_CATEGORY } from "../../../../constants";
import { formatEnumValue } from "../../../../utils/formatUtils";
import { enumValueFormatter } from "../../../../utils/formatUtils";
import { DownOutlined } from "@ant-design/icons";
// Created to show ellipses when overflowing
const QuoteItemCategoryRenderer = ({ data, value, editable }) => {
  var currentValue = 0;
  var showIcon = false;
  if (data.Type === 1 || data.Type === 2) {
    currentValue = formatEnumValue(data.Category, QUOTE_ITEM_CATEGORY);
    if(editable) {
      showIcon = true;
    }
  }

  if (data.Type === 3) {
    currentValue = "Other";
  }

  if (data.Type === 4) {
    currentValue = "Accessorial";
  }

  return (
    <div className="d-flex justify-content-between align-items-center col-12">
      <div >{currentValue}</div> 
      <div >{showIcon && <DownOutlined/>}</div>
    </div>
  );
};

export default QuoteItemCategoryRenderer;