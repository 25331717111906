import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import StatusRenderer from "../Templates/CellRenderers/StatusRenderer";
import ActionRenderer from "../Templates/CellRenderers/ActionRenderer";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import { formatDateOnly, formatCurrency, enumValueFormatter, formatQID, formatEllipsis, getQuickFilterValue } from "../../utils/formatUtils";
import { fetchQuotes, fetchQuotesFiltered } from "../../services/quoteServices";
import { statusValueGetter, statusFilter } from "../../utils/quoteUtils";
import { isSuperAdmin, isNTMSUSer } from "../../utils/roleUtils";
import QuoteForm from "./QuoteForm";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setQuotesListData } from "../../redux/quote/quoteReducer";
import { fetchCustomersByAccount } from "../../services/customerServices";
import { setCustomersListData } from "../../redux/customer/customerReducer";
import { GRID_CONSTANTS, QUOTE_STATUS, } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";
import { fetchTMSQuotesFiltered } from "../../services/tmsQuoteServices";
import QuoteStatusRenderer from "../Templates/CellRenderers/Orders/QuoteStatusRenderer";
import QuoteActionRenderer from "../Templates/CellRenderers/Quotes/QuoteActionRenderer";
import NotesRenderer from "../Templates/CellRenderers/Commons/NotesRenderer";
import NotesTooltip from "../Commons/Tooltips/NotesTooltip";

function Quotes() {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const quoteState = useSelector((state) => state.quote);

  const toggleFormDisplay = (isEdit = false, quoteId = null) => {
    fillCustomerList();
    if (isFormHidden) {
      setSelectedQuoteId(quoteId);
    } else {
      setSelectedQuoteId(null);
    }

    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && quotesList?.length > 0) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = () => {
    if (isFormDirty) {
      toggle();
      return;
    }
    toggleFormDisplay();
  };

  const [rowData, setRowData] = useState([]);
  const defaultColumnDefs = [
    {
      field: "Order Id",
      minWidth: 150,
      cellClass: "grid-column bolded",
    },
    {
      field: "QID",
      minWidth: 150,
      valueFormatter: (params) => { return formatQID(params.value) },
      cellClass: "grid-column",
    },
    {
      field: "Quote Name",
      minWidth: 150,
      cellClass: "grid-column gray",
    },
    {
      field: "Date",
      minWidth: 150,
      cellClass: "grid-column gray",
    },
    {
      field: "Quote",
      minWidth: 150,
      valueFormatter: (params) => { return formatCurrency(params.value) },
      cellClass: "grid-column gray",
    },
    {
      field: "Status",
      minWidth: 150,
      cellClass: "grid-column gray center",
      cellRenderer: QuoteStatusRenderer,
      valueFormatter: (params) => {
        return enumValueFormatter(params, QUOTE_STATUS);
      },
      getQuickFilterText: (params) => {
        const categoryValue = params.value;
        return getQuickFilterValue(categoryValue, QUOTE_STATUS);
      },
    },
    {
      field: "Notes",
      minWidth: 150,
      cellClass: ['grid-column gray', 'blocked-ellipses left-aligned', 'd-flex justify-content-end'],
      tooltipValueGetter: (params) => params.value,
      valueFormatter: (params) => formatEllipsis(params, 40),
      cellRenderer: NotesRenderer,
      tooltipComponent: NotesTooltip,
      tooltipField: 'Notes',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'OrderStatus',
      headerName: 'Order Status',
      colId: 'OrderStatus',
      minWidth: 150,
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: QuoteActionRenderer,
      cellRendererParams: props =>
      ({
        checkPageEditable: () => {
          var statusCheck = false;
          props.api.forEachNode((rowNode) => {
            if (rowNode.data.Status === 1 && props.data.OrderId === rowNode.data.OrderId) {
              statusCheck = true;
            }
          })
          return statusCheck;
        }
      }),
    },
  ];

  // useStates
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [quotesList, setQuotesList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersFetched, setCustomersFetched] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setQuotesList(quoteState.data);
  }, [quoteState.data]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.onFilterChanged();
    }
  }, [rowData]);

  useEffect(() => {
    let data = [];
    _.each(quotesList, (quote, index) => {
      data.push({
        "#": index + 1,
        ProfilePicture: { url: `images/profile-picture-fallback.png` },
        "Order Id": quote.order.orderId,
        QID: quote.quoteSequenceId,
        "Quote Name": quote.quoteName,
        Date: formatDateOnly(quote.quoteDate),
        Quote: quote.total,
        Status: quote.status,
        Notes: quote.notes,
        QuoteId: quote.tmsQuoteId,
        Id: quote.tmsQuoteId,
        OrderId: quote.orderId,
        AccountId: quote.accountId,
        QuoteItems: quote.tmsQuoteItem,
        OrderStatus: quote.order.status
      });
    });
    setRowData(data);
  }, [quotesList]);

  useEffect(() => {
    fillSelectedQuote(selectedQuoteId);
  }, [selectedQuoteId]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
        lineHeigh: 0,
      },
      resizable: true,
    };
  }, []);

  const init = async () => {
    const response = await fetchTMSQuotesFiltered();
    dispatch(setQuotesListData(response));
    setIsLoading(false);
  };

  const getQuotesData = async () => {
    if (isSuperAdmin(userType)) {
      return await fetchQuotes();
    }

    if (isNTMSUSer(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchQuotesFiltered(accountId)
        : await fetchQuotesFiltered(selectedAccountState.accountId);
    }

    return await fetchQuotesFiltered(accountId);
  };

  const fillCustomerList = async () => {
    if (!customersFetched) {
      const response = await getCustomersData();
      dispatch(setCustomersListData(response));
      setCustomersFetched(true);
    }
  };

  const getCustomersData = async () => {
    return await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillSelectedQuote = (id) => {
    const quote = _.find(quotesList, { quoteId: id });
    setSelectedQuote(quote);
  };
  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isSuperAdmin(userType) && !isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  const isExternalFilterPresent = () => {
    return true;
  };

  const doesExternalFilterPass = (node) => {
    const name = node.data["Quote Name"];
    const orderId = node.data.OrderId;
    const orderStatus = node.data.OrderStatus;
    const quoteStatus = node.data.Status;

    // If the OrderStatus is 3 and QuoteStatus is 1 for the given OrderId, show the row
    if (orderStatus > 1   && quoteStatus === 1) {
      return true;
    }

    // Check if there are any rows with OrderStatus 3 for the same OrderId
    const hasOrderStatus3 = rowData.some(row => row.OrderId === orderId && row.OrderStatus === 3);

    // // If there are rows with OrderStatus 3 for the same OrderId, only show those with QuoteStatus 1
    if (hasOrderStatus3) {
      return quoteStatus === 1;
    }

    // // If no rows with OrderStatus 3 for the same OrderId, show all rows with that OrderId
    return true;
  };



  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden ? "Quotes" : isFormEdit ? "Edit Quote" : "New Quote"
            }
            subtitle={"quotes"}
            dataCount={rowData.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
            hideButton={true}
            color='color-3'
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {quotesList?.length > 0 ?
            <div
              className={`ag-theme-alpine content-section-container color-3-grid color-3-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                rowSelection={"single"}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-3' content='quote' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <QuoteForm
            isFormEdit={isFormEdit}
            selectedQuote={selectedQuote}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Quotes;
