import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formPendingCompletion: false,
  formPendingFunction: null,
  notification: {
    type: "",
    message: "",
    description: "",
  },
  coordinates: {
    origin: {
      latitude: 49.8953934,
      longitude: -97.1385589,
      isInitialData: true
    },
    destination: {
      latitude: 0,
      longitude: 0,
      isInitialData: true
    },
  },
};

const globalReducer = createSlice({
  name: "global",
  initialState,
  reducers: {
    setFormPendingCompletion: (state, action) => {
      return {
        ...state,
        formPendingCompletion: action.payload,
      };
    },
    setFormPendingFunction: (state, action) => {
      return {
        ...state,
        formPendingFunction: action.payload
      };
    },
    setNotificationData: (state, action) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
    setCoordinatesData: (state, action) => {
      const { property, data } = action.payload;

      return {
        ...state,
        coordinates: {
          ...state.coordinates,
          [property]: { ...state.coordinates[property], ...data },
        },
      };
    },
  },
});

export const {
  setFormPendingCompletion,
  setFormPendingFunction,
  setNotificationData,
  setCoordinatesData,
} = globalReducer.actions;

export default globalReducer.reducer;
