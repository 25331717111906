import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import CustomerForm from "./CustomerForm";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchCustomersByAccount } from "../../services/customerServices";
import { formatPhoneNumber } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { setCustomersListData } from "../../redux/customer/customerReducer";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Customers() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const customerState = useSelector((state) => state.customer);

  const toggleFormDisplay = (isEdit = false, customerId = null) => {
    if (isFormHidden) {
      setSelectedCustomerId(customerId);
    } else {
      setSelectedCustomerId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && customerList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "ProfilePicture",
      minWidth: 70,
      headerName: "",
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      resizable: false,
    },
    {
      field: "Customer Name",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Phone",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Mobile",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Email",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setCustomerList(customerState.data);
  }, [customerState.data]);

  useEffect(() => {
    let data = [];
    _.each(customerList, (customer, index) => {
      data.push({
        "#": index + 1,
        ProfilePicture: { url: `images/profile-picture-fallback.png` },
        "Customer Name": customer.customerName,
        Email: customer.defaultEmail,
        Phone: customer.phone,
        Mobile: customer.cell,
        PhoneAndMobile: { phone: customer.phone, mobile: customer.cell },
        NameAndEmail: { name: customer.customerName, email: customer.email },
        Edit: { id: customer.customerId, btnClass: "btn-color-1" },
      });
    });
    setRowData(data);
  }, [customerList]);

  useEffect(() => {
    fillSelectedCustomer(selectedCustomerId);
  }, [selectedCustomerId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getCustomersData();
    dispatch(setCustomersListData(response));
    setIsLoading(false);
  };

  const getCustomersData = async () => {
    return await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedCustomer = (id) => {
    const customer = _.find(customerList, { customerId: id });
    setSelectedCustomer(customer);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Customers"
                : isFormEdit
                  ? "Edit Customer"
                  : "New Customer"
            }
            subtitle={"customers"}
            dataCount={customerList.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">

        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(customerList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-1-grid color-1-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-1' content='customer' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <CustomerForm
            isFormEdit={isFormEdit}
            selectedCustomer={selectedCustomer}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Customers;
