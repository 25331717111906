import { get, post, patch } from "./apiService";

export const fetchExpenses = async () => {
  return get({ path: `Expense` });
}

export const fetchExpensesFiltered = async (balanceFilter) => {
  return get({ path: `Expense/filtered${balanceFilter ? '?withBalances=' + balanceFilter : ''}` });
}

export const addExpense = async (payload, keepAlive = false) => {
  return post({ path: 'Expense', body: payload, keepAlive: keepAlive });
}

export const updateExpense = async (payload, keepAlive = false) => {
  return patch({ path: `Expense/update/${payload.expenseId}`, body: payload, keepAlive: keepAlive });
}