import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import { fetchReporTemplatesFiltered } from "../../services/reportTemplateServices";
import _ from "lodash";
import ReportTemplateForm from "./ReportTemplateForm";
import { reportTypeRenderer } from "../../utils/formatUtils";
import DefaultTemplateRenderer from "../Templates/CellRenderers/ReportTemplates/DefaultTemplateRenderer";
import { useDispatch, useSelector } from "react-redux";
import { isNTMSUSer } from "../../utils/roleUtils";
import { setReportTemplatesListData } from "../../redux/reportTemplate/reportTemplateReducer";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function ReportTemplates() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const reportTemplateState = useSelector((state) => state.reportTemplate);

  const toggleFormDisplay = (isEdit = false, reportTemplateId = null) => {
    if (isFormHidden) {
      setSelectedReportTemplateId(reportTemplateId);
    } else {
      setSelectedReportTemplateId(null);
    }

    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && reportTemplateList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  const defaultColumnDefs = [
    {
      field: "Template Name",
      minWidth: 110,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Report Type",
      minWidth: 110,
      filter: "agTextColumnFilter",
      valueGetter: (params) => reportTypeRenderer(params.data["Report Type"]),
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Default",
      minWidth: 80,
      width: 80,
      cellClass: 'grid-column center',
      cellRenderer: DefaultTemplateRenderer,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      resizable: false,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [reportTemplateList, setReportTemplateList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState({});
  const [selectedReportTemplateId, setSelectedReportTemplateId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setReportTemplateList(reportTemplateState.data);
  }, [reportTemplateState.data]);

  useEffect(() => {
    let data = [];
    _.each(reportTemplateList, (report, index) => {
      data.push({
        "#": index + 1,
        "Template Name": report.templateName,
        Default: report.defaultTemplate,
        "Report Type": report.reportType,
        ReportTemplateId: report.reportTemplateId,
        Edit: { id: report.reportTemplateId, btnClass: "btn-color-6" },
      });
    });
    setRowData(data);
  }, [reportTemplateList]);

  useEffect(() => {
    fillSelectedReportTemplate(selectedReportTemplateId);
  }, [selectedReportTemplateId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getReportTemplatesData();
    dispatch(setReportTemplatesListData(response));
    setIsLoading(false);
  };

  const getReportTemplatesData = async () => {
    if (isNTMSUSer(userType)) {
      return (await selectedAccountState.accountId) === null
        ? await fetchReporTemplatesFiltered(accountId)
        : await fetchReporTemplatesFiltered(selectedAccountState.accountId);
    }

    return await fetchReporTemplatesFiltered(accountId);
  };

  const fillSelectedReportTemplate = (id) => {
    const reportTemplate = _.find(reportTemplateList, { reportTemplateId: id });
    setSelectedReportTemplate(reportTemplate);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden
                ? "Report Templates"
                : isFormEdit
                  ? "Edit Report Template"
                  : "New Report Template"
            }
            subtitle={"report templates"}
            dataCount={reportTemplateList.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            parentNavItem={parentNavItem}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row">
        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(reportTemplateList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-6-grid color-6-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData isFormHidden={isFormHidden} color='color-6' content='report template' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <ReportTemplateForm
            isFormEdit={isFormEdit}
            selectedReportTemplate={selectedReportTemplate}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ReportTemplates;
