import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchAccessorialsFiltered } from "../../services/accessorialServices";
import AccessorialForm from "./AccessorialForm";
import {
  currencyTypeValueGetter,
  defaultTaxCodeValueGetter,
} from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import AuthorizationError from "../Commons/Authorization/AuthorizationError";
import { isAccountUser, isNTMSUSer } from "../../utils/roleUtils";
import { setAccessorialsListData } from "../../redux/accessorial/accessorialReducer";
import { GRID_CONSTANTS } from "../../constants";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Accessorials() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accessorialState = useSelector((state) => state.accessorial);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const toggleFormDisplay = (isEdit = false, accessorialId = null) => {
    if (isFormHidden) {
      setSelectedAccessorialId(accessorialId);
    } else {
      setSelectedAccessorialId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && accessorialList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "Name",
      minWidth: 150,
      cellClass: "grid-column",
    },
    {
      field: "Description",
      minWidth: 150,
      cellClass: "grid-column gray",
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      resizable: false,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [accessorialList, setAccessorialList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedAccessorial, setSelectedAccessorial] = useState({});
  const [selectedAccessorialId, setSelectedAccessorialId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setAccessorialList(accessorialState.data);
  }, [accessorialState.data]);

  useEffect(() => {
    let data = [];
    _.each(accessorialList, (accessorial, index) => {
      data.push({
        "#": index + 1,
        Name: accessorial.accessorialName,
        Description: accessorial.description,
        Master: accessorial.master,
        Edit: { id: accessorial.accessorialId, btnClass: "btn-color-6" },
      });
    });
    setRowData(data);
  }, [accessorialList]);

  useEffect(() => {
    fillSelectedAccessorial(selectedAccessorialId);
  }, [selectedAccessorialId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const parentNavItem = {
    title: "Settings",
    link: "/settings",
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getAccessorialData();
    dispatch(setAccessorialsListData(response));
    setIsLoading(false);
  };

  const getAccessorialData = async () => {
    if (isNTMSUSer(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchAccessorialsFiltered(accountId)
        : await fetchAccessorialsFiltered(selectedAccountState.accountId);
    } else {
      return fetchAccessorialsFiltered(accountId);
    }
  };

  const fillSelectedAccessorial = (id) => {
    const accessorial = _.find(accessorialList, { accessorialId: id });
    setSelectedAccessorial(accessorial);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if(!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      {isAccountUser(userType) && <AuthorizationError />}
      {!isAccountUser(userType) && (
        <>
          <div className="row mb-0">
            <div className="col-12">
              <ContentHeader
                title={
                  isFormHidden
                    ? "Accessorials"
                    : isFormEdit
                      ? "Edit Accessorial"
                      : "New Accessorial"
                }
                subtitle={"accessorials"}
                dataCount={accessorialList.length}
                filterData={filterData}
                onClickAdd={toggleFormCancelDisplay}
                onClickCancel={toggleFormCancelDisplay}
                parentNavItem={parentNavItem}
                isFormHidden={isFormHidden}
                openOrCloseButtonRef={openOrCloseButtonRef}
              />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
              {(accessorialList?.length > 0) ?
                <div
                  className={`ag-theme-alpine content-section-container color-6-grid color-6-section p-0`}
                >
                  <AgGridReact
                    className="no-header"
                    rowData={rowData}
                    columnDefs={columnDefs}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onColumnSizeChanged={onColumnsSizeChanged}
                    onGridSizeChanged={onGridSizeChanged}
                    rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                  ></AgGridReact>
                </div>
                :
                <NoData color='color-6' content='accessorial' />
              }
            </div>
            <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
              <AccessorialForm
                isFormEdit={isFormEdit}
                selectedAccessorial={selectedAccessorial}
                isFormHidden={isFormHidden}
                toggleFormDisplay={toggleFormDisplay}
                setIsFormDirty={setIsFormDirty}
                modal={modal}
                setModal={setModal}
                toggle={toggle}
                focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Accessorials;
