import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { addCustomer, updateCustomer } from "../../services/customerServices";
import {
  isNTMSUSer,
} from "../../utils/roleUtils";
import { removePhoneFormat } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerData,
  setCustomerData,
} from "../../redux/customer/customerReducer";
import moment from "moment";
import PrimaryDetails from "../Customers/PrimaryDetails";
import NoteDetails from "../Customers/NoteDetails";
import {
  setFormPendingCompletion,
  setNotificationData,
} from "../../redux/global/globalReducer";
import FormTabs from "../Commons/Layouts/FormTabs";
import { updateFormDataOnExit } from "../../utils/formUtils";

function CustomerForm({
  isFormEdit = false,
  selectedCustomer = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    watch,
    control,
    trigger,
    formState,
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);

  const provinceStateRef = useRef("null");
  const isInitialRender = useRef(true);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateForm();
  }, [formState]);

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;

    _.set(payload, "phone", removePhoneFormat(payload.phone));
    _.set(payload, "fax", removePhoneFormat(payload.fax));
    _.set(payload, "cell", removePhoneFormat(payload.cell));
    _.set(payload, "provinceState", payload.provinceState.value);

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return false;
    }

    if (payload.openingHour !== "" && payload.openingHour !== undefined) {
      const openingHour = moment(payload.openingHour.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "openingHour", openingHour);
    } else {
      const openingHour = moment()
        .set({ hours: 8, minutes: 0, seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "openingHour", openingHour);
    }

    if (payload.closingHour !== "" && payload.closingHour !== undefined) {
      const closingHour = moment(payload.closingHour.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "closingHour", closingHour);
    } else {
      const closingHour = moment()
        .set({hours: 17, minutes: 0, seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "closingHour", closingHour);
    }

    if (isFormEdit) {
      _.set(payload, "customerId", selectedCustomer.customerId);
      _.set(payload, "accountId", selectedCustomer.accountId);
      response = await updateCustomer(payload, keepAlive);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } customer.`,
        })
      );
    } else {
      _.set(payload, "customerId", 0);
      if (isNTMSUSer(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      response = await addCustomer(payload, keepAlive);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${
            response.ok ? "Successfully saved" : "Failed to save"
          } customer.`,
        })
      );
    }

    if (response.ok) {
      const customerData = await response.json();
      dispatch(
        isFormEdit
          ? setCustomerData(customerData)
          : addCustomerData(customerData)
      );
      toggleFormDisplay();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      reset({}, {keepValues: true});
      dispatch(setFormPendingCompletion(false));
      return true;
    }

    return false;
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedCustomer={selectedCustomer}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Customer Notes",
      key: 1,
      forceRender: true,
      children: (
        <div className="content-section-container color-1-section">
          <div className="row form-container" id="address-details-form">
            <NoteDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedCustomer={selectedCustomer}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              provinceStateRef={provinceStateRef}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="customer-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
        <FormTabs
          items={tabItems}
          tabClassName="color-1"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CustomerForm;
