import { get, post, patch } from "./apiService";
import { isNTMSUSer } from '../utils/roleUtils';

export const fetchReportTemplates = async () => {
  return get({ path: 'ReportTemplate' });
}

export const fetchReporTemplatesFiltered = async (accountId) => {
  return get({ path: `ReportTemplate/filtered` });
}

export const fetchReportTemplate = async (id, type) => {
  return get({ path: `ReportTemplate/${id}/type/${type}` });
}


export const addReportTemplate = async (payload, keepAlive = false) => {
  return post({ path: 'ReportTemplate', body: payload, keepAlive: keepAlive });
}

export const updateReportTemplate = async (payload, keepAlive = false) => {
  return patch({ path: `ReportTemplate/update/${payload.reportTemplateId}`, body: payload, keepAlive: keepAlive });
}

export const fetchReporTemplateByAccount = async (userType, selectedAccountId, accountId, reportType) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchReportTemplate(accountId, reportType) : await fetchReportTemplate(selectedAccountId, reportType);
  }
  else {
    return fetchReportTemplate(accountId, reportType);
  }

}