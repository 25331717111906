import React, { useCallback, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { deleteQuote } from '../../../services/quoteServices';
import { isSuperAdmin } from '../../../utils/roleUtils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuoteData } from '../../../redux/quote/quoteReducer';
import { setNotificationData } from '../../../redux/global/globalReducer';
import { DeleteOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '../../Commons/Modals/ConfirmationModal';

function ActionRenderer(props, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const userType = useSelector((state) => state.auth.user.userType);

  
  const removeSelected = useCallback(async () => {
    const selectedNode = props.gridRef.current.api.getSelectedNodes();
    const quoteId = selectedNode[0].data["id"];

    const response = await deleteQuote(quoteId)

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully deleted" : "Failed to delete"
          } quote item.`,
      })
    );
    if (response.ok) {
      dispatch(deleteQuoteData(quoteId));
      setModal(false)
    }
  }, [props.rowData]);

  return (
    <div className='cell-renderer-content'>
      {/* Update to use link depending on what page it comes from. (Pass url and id as param) Url router is found in App.js */}
      <Link to="/quotes/edit-quote" style={{ textDecoration: 'none' }} state={{ quoteId: props?.data?.QuoteId }}>
        <button className={`btn btn-color-3 ${props.data['Status'] !== 0 || (isSuperAdmin(userType)) ? "btn-sm": "btn-edit"}`}>
          <i className={`fa-solid ${props.data['Status'] !== 0 || (isSuperAdmin(userType)) ? 'fa-eye' : 'fa-pen'}`}></i>
          {props.data['Status'] !== 0 || (isSuperAdmin(userType)) ? "" : "    Edit"}
        </button>
      </Link>
      {/* {
        !(props.data['Status'] !== 0 || (isSuperAdmin(userType))) && <button onClick={toggle} className={`btn btn-color-delete btn-sm`}><DeleteOutlined /></button>
      } */}
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Delete Quote</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={removeSelected}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmationModal
        isOpen={modal}
        toggle={toggle}
        onClick={removeSelected}
        headerText='Delete Quote'
        bodyText='Are you sure you want to delete this item?'
        confirmButtonText='Yes'
        cancelButtonText='No'
        actionType="delete"
      />
    </div>
  )
}

export default ActionRenderer