import { get, post, patch } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';

export const fetchTrucks = async () => {
  return get({ path: 'Truck' });
}

export const fetchTrucksFiltered = async () => {
  return get({ path: `Truck/filtered` });
}

export const addTruck = async (payload, keepAlive = false) => {
  return post({ path: 'Truck', body: payload, keepAlive: keepAlive });
}

export const updateTruck = async (payload, keepAlive = false) => {
  return patch({ path: `Truck/${payload.truckId}`, body: payload, keepAlive: keepAlive });
}

export const fetchTrucksByAccount = async (userType, selectedAccountId, accountId, ) => {
  if (isNTMSUSer(userType)) {
    return selectedAccountId === null ? await fetchTrucksFiltered(accountId) : await fetchTrucksFiltered(selectedAccountId);
  }

  if (isAccountAdmin(userType) || isAccountUser(userType)) {
    return fetchTrucksFiltered(accountId);
  }
  
  return await fetchTrucks();
}