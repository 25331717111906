import React, { useEffect, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { selectStyle } from '../../../utils/styleUtils';
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';
import _ from 'lodash';
import { setNotificationData } from '../../../redux/global/globalReducer';
import { useDispatch } from 'react-redux';

function AddPickDropModal({
  modal,
  setModal,
  toggle,
  confirmAddStop,
  cancelAddStop,
  args
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, reset, setFocus, control, formState: { errors, isDirty } } = useForm();

  const [stopType, setStopType] = useState(1);

  const confirm = () => {
    confirmAddStop(stopType);
    toggle();
  }

  const cancel = () => {
    cancelAddStop();
    toggle();
  }

  return (
    <>
      <Modal isOpen={modal} toggle={cancel} {...args}>
        <ModalHeader toggle={cancel}>Add Stop</ModalHeader>
        <ModalBody>
          <form className="row form-container justify-content-center align-items-center" id="driver-form" >
            {/* <div className="col-6 col-sm-4 mb-2"></div> */}
            <div className="col-12 mb-2">
              <label htmlFor="userId" className="form-label">Please select type:</label>
              <div className="custom-selector">
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        {
                          label: "Pickup",
                          value: 1,
                        },
                        {
                          label: "Dropoff",
                          value: 2,
                        }
                      ]}
                      onChange={(e) => setStopType(e.value)}
                      className="company-select"
                      classNamePrefix="react-select"
                      placeholder={'Pickup/Dropoff'}
                      id="user"
                      styles={selectStyle}
                      tabIndex={11}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-secondary me-2' onClick={cancel}>
            Cancel
          </button>
          <button className={`btn btn-primary`} onClick={confirm}>
            Confirm
          </button>
        </div>
        </ModalFooter>
      </Modal>
    </>

  )
}

export default AddPickDropModal