import React, { useEffect, useState, useRef, useMemo, useCallback, forwardRef } from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import { SearchOutlined } from "@mui/icons-material";
import { AgGridReact } from 'ag-grid-react';
import DeleteItemActionRenderer from "../../Templates/CellRenderers/Commons/DeleteItemActionRenderer";
import { formatCurrency } from "../../../utils/formatUtils";
import {
  QUOTE_ITEM_CATEGORY,
  QUOTE_STATUS,
  GRID_CONSTANTS
} from "../../../constants";
import { enumValueFormatter } from "../../../utils/formatUtils";
import { calculateQuoteItem, quantityEditableSettings } from "../../../utils/quoteUtils";
import QuoteItemCategoryEditor from "../../Templates/Editor/QuoteItemCategoryEditor";
import QuoteItemPercentEditor from "../../Templates/Editor/QuoteItemPercentEditor";
import DollarEditor from "../../Templates/Editor/DollarEditor";
import { addTMSQuoteItem, updateTMSQuoteItem, deleteTMSQuoteItem, updateTMSQuoteItems } from "../../../services/tmsQuoteItemServices";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderTMSQuoteItemData,
  updateOrderTMSQuoteItemData,
  deleteOrderTMSQuoteItemData,
  updateOrderTMSQuoteItemsData
} from "../../../redux/order/orderReducer";
import { setNotificationData } from "../../../redux/global/globalReducer";
import { Modal, Space } from 'antd';
import { ConfirmationModal } from "./ConfirmationModal";
import { WARNING_MODAL_EXIT_TEXTS } from "../../../constants";
import { deleteQuoteItem } from "../../../services/quoteServices";
import QuoteItemCategoryRenderer from "../../Templates/CellRenderers/Quotes/QuoteItemCategoryRenderer";

function QuoteItemFormModal({
  modal,
  setModal,
  toggle,
  isQuoteEdit,
  selectedOrder,
  selectedQuote,
  quoteItemGridRef,
  selectedQuoteItemList,
  register,
  handleSubmit,
  setValue,
  errors,
  reset,
  checkPageEditable,
  handleAddQuoteItem,
  handleUpdateQuoteItems,
  handleDeleteQuoteItem,
  dirtyQuoteItemColumns,
  setDirtyQuoteItemColumns,
  dirtyQuoteItemFields,
  setDirtyQuoteItemFields,
  dirtyLoadOrderColumns,
  setSelectedQuoteId,
  quoteItemRowData,
  setQuoteItemRowData,
  args,
}) {
  const dispatch = useDispatch();

  const columnDefinitions = (isEditable) => {
    const defs = [
      {
        field: 'Category',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column",
            `${params.data.UpdateStatus === 1 && 'bold'}`,
          ],
        cellEditor: QuoteItemCategoryEditor,
        cellEditorParams: (params) => {
          if (params.data && (params.data.Type === 1 || params.data.Type === 2)) {
            const filteredCategory = params.data.Type === 1
              ? QUOTE_ITEM_CATEGORY.filter(item => item.value !== 0 && item.value !== 3)
              : QUOTE_ITEM_CATEGORY.filter(item => item.value !== 0);

            return {
              values: filteredCategory,
              api: params.api,
            };
          } else {
            return {};
          }
        },
        valueSetter: (params) => {
          const selectedValue = parseInt(params.newValue, 10);
          params.data.Category = selectedValue;
          params.data.Quantity = params.data.Category === 3 ? 0 : 1;
          calculateQuoteItem(params)
          return true;
        },
        cellRenderer: QuoteItemCategoryRenderer,
        cellRendererParams: {editable: !isEditable},
        editable: (params) => (params.data.Type === 1 || params.data.Type === 2) && !isEditable,
      },
      {
        field: 'Description',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        editable: (params) => params.data.Type === 3 && !isEditable,
      },
      {
        field: 'Quantity',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        cellEditor: QuoteItemPercentEditor,
        editable: !isEditable,
        valueFormatter: (params) => {
          return params.data.Category === 3 ? params.value + "%" : params.value
        },
        valueSetter: (params) => calculateQuoteItem(params),
      },
      {
        field: 'Rate',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        cellEditor: DollarEditor,
        editable: !isEditable,
        valueFormatter: (params) => { return formatCurrency(params.value) },
        valueSetter: (params) => calculateQuoteItem(params),
      },
      {
        field: 'Total',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        valueFormatter: (params) => { return formatCurrency(params.value) }
      },
      {
        field: 'UpdateStatus',
        headerName: 'Update Status',
        colId: 'UpdateStatus',
        minWidth: 150,
        filter: "agNumberColumnFilter",
         hide: true,
      },
      {
        field: 'Edit',
        minWidth: 150,
        headerName: '',
        cellClass: 'd-flex flex-row-reverse',
        autoHeight: true,
        hide: isEditable,
        cellRenderer: DeleteItemActionRenderer,
        cellRendererParams: props => ({
          header: 'Quote Item',
          parentType: "Quote",
          handleDeleteFunction: handleDeleteQuoteItem,
        }),
      },
    ]
    return defs;
  }

  useEffect(() => {
    setQuoteItemRowData(selectedQuoteItemList.reverse())
  }, [selectedQuoteItemList])

  useEffect(() => {
    setValue("tmsQuoteId", selectedQuote?.QuoteId);
    setValue("accountId", selectedQuote?.AccountId);
    setValue("orderId", selectedQuote?.OrderId);
    setValue("quoteName", selectedQuote?.Name);
    setValue("date", selectedQuote?.Date);
    setValue("notes", selectedQuote?.Notes);
    setValue("status", selectedQuote?.Status);
    setValue("notes", selectedQuote?.Notes);
    setValue("total", selectedQuote?.Quote);
    setValue("quoteDate", selectedQuote?.Date);
  }, [selectedQuote?.QuoteId])

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columnDefinitions(false));
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const toggleUnsavedChangesModal = () => {
    if (dirtyQuoteItemColumns || !_.isEmpty(dirtyQuoteItemFields)) {
      setUnsavedChangesModal(!unsavedChangesModal)
    } else {
      toggle();
      setSelectedQuoteId(null);
    }
  };
  const handleUnsavedChanges = async (save = false) => {
    if(save && (!_.isEmpty(dirtyQuoteItemFields) || dirtyQuoteItemColumns)) {
      await handleSubmit(async payload => await handleUpdateQuoteItems(payload))();
    }
    reset();
    setDirtyQuoteItemColumns(false);
    setDirtyQuoteItemFields({});
    setUnsavedChangesModal(false);
    setModal(false);
    setSelectedQuoteId(null);
  };


  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    quoteItemGridRef.current.api.sizeColumnsToFit();

    quoteItemGridRef.current.api.setFilterModel({
      UpdateStatus: {
        type: 'notEqual',
        filter: 3,
      },
    })
  }, []);

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    if (params.clientHeight > 0 && params.clientWidth > 0) {
      params.api.sizeColumnsToFit();
    }
  }

  useEffect(() => {
    setColumnDefs(columnDefinitions(checkPageEditable()));
  }, [checkPageEditable()])

  const quoteDatesValue = () => {
    return `${selectedOrder?.pickupStartDate} to ${selectedOrder?.dropoffEndDate}`;
  }

  const onCellValueChanged = (params) => {
    const itemsToUpdate = [];
    const data = params.data;
    if (data["UpdateStatus"] === 0) {
      data["UpdateStatus"] = 2;
      itemsToUpdate.push(data);
      quoteItemGridRef.current.api.applyTransaction({ update: itemsToUpdate });
      setDirtyQuoteItemColumns(true);
    }
  };

  const updateNoteStatus = () => {
    setDirtyQuoteItemColumns(true);
  }

  return (
    <>
      <Modal
        open={modal}
        className={`ant-modal-quote`}
        title={
          <Space>
            {checkPageEditable() ? 'View' : 'Edit'} Quote
          </Space>
        }
        maskClosable={false}
        onCancel={() => handleUnsavedChanges(true)}
        footer={
          <>
            <Button
              className=" btn btn-modal-cancel me-2"
              color="primary"
              type="button"
              onClick={() => handleUnsavedChanges(true)}
            >
              Close
            </Button>
            {!checkPageEditable() &&
              <Button
                color="primary"
                form="quote-item-form"
                // type="submit"
                disabled={!(!_.isEmpty(dirtyQuoteItemFields) || dirtyQuoteItemColumns)}
                onClick={handleSubmit(async payload => await handleUpdateQuoteItems(payload))}
                tabIndex={10}
              >
                Save and Stay On Page
              </Button>}
          </>
        }
      >
        <form
          className="row form-container align-items-center"
          id="quote-item-form"
        >
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="tmsQuoteId" className="form-label">Quote ID</label>
            <input type="text" className="form-control" id="tmsQuoteId" tabIndex={1}
              defaultValue={isQuoteEdit ? selectedQuote?.tmsQuoteId || '' : ''}
              autoComplete='on'
              disabled={true}
              {...register("tmsQuoteId", { required: "Quote Id is required" })}
            />
            <small className='form-error-message'>
              {/* {errors?.tmsQuoteId && errors.tmsQuoteId.message} */}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="orderId" className="form-label">Order ID</label>
            <input type="text" className="form-control" id="orderId" tabIndex={2}
              defaultValue={selectedQuote?.OrderId}
              autoComplete='on'
              disabled={true}
              {...register("orderId", { required: "Order Id is required" })}
            />
            <small className='form-error-message'>
              {errors?.orderId && errors.orderId.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="quoteName" className="form-label">Quote Name</label>
            <input type="text" className="form-control" id="quoteName" tabIndex={5}
              defaultValue={isQuoteEdit ? selectedQuote?.Name || '' : ''}
              autoComplete='on'
              disabled={checkPageEditable()}
              {...register("quoteName", { required: "Quote Name is required" })}
            />
            <small className='form-error-message'>
              {errors?.quoteName && errors.quoteName.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="quoteDate" className="form-label">Quote Date</label>
            <input type="text" className="form-control" id="quoteDate" tabIndex={6}
              defaultValue={isQuoteEdit ? selectedQuote?.Date || '' : ''}
              autoComplete='on'
              disabled={true}
              {...register("quoteDate", { required: "Date is required" })}
            />
            <small className='form-error-message'>
              {errors?.date && errors.date.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="dates" className="form-label">Shipping Dates</label>
            <input type="text" className="form-control" id="dates" tabIndex={7}
              disabled={true}
              // defaultValue={isQuoteEdit ? selectedQuote?.dates || '' : ''}
              value={quoteDatesValue()}
              autoComplete='on'
            // {...register("dates", { required: "Dates is required" })}
            />
            <small className='form-error-message'>
              {/* {errors?.dates && errors.dates.message} */}
            </small>
          </div>
          {!checkPageEditable() &&
            <div className="col-12 mb-2 d-flex flex-row-reverse mb-2">
              <button
                className="btn btn-md btn-color-3 me-2"
                form="quote-item-form"
                onClick={handleAddQuoteItem}
                type="button"
                tabIndex={8}
              >
                Add Quote Item
              </button>
            </div>
          }

          <div className={`test col-12 mb-2`}>
            <div className={`ag-theme-alpine form-content-section-container color-3-grid color-3-section p-0`} >
              <AgGridReact
                className='no-header'
                rowData={quoteItemRowData}
                columnDefs={columnDefs}
                ref={quoteItemGridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                domLayout='autoHeight'
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
                onCellValueChanged={onCellValueChanged}
                rowSelection={'single'}
              >
              </AgGridReact>
            </div>
          </div>
          <div className="col-12 mb-2">
            <label htmlFor="notes" className="form-label">Notes:</label>
            <textarea
              className="form-control"
              id="notes"
              rows="5"
              tabIndex={9}
              {...register("notes")}
              defaultValue={isQuoteEdit ? selectedQuote?.Notes || '' : ''}
              onChange={updateNoteStatus}
              disabled={checkPageEditable()}
            />
            <small className='form-error-message'>
              {/* {errors?.notes && errors.notes.message} */}
            </small>
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        isOpen={unsavedChangesModal}
        toggle={toggleUnsavedChangesModal}
        onClick={() => handleUnsavedChanges(false)}
        onSave={() => handleUnsavedChanges(true)}
        headerText={WARNING_MODAL_EXIT_TEXTS.headerText}
        bodyText={WARNING_MODAL_EXIT_TEXTS.bodyText}
        saveButtonText={"Save Changes"}
        confirmButtonText={WARNING_MODAL_EXIT_TEXTS.confirmButtonText}
        cancelButtonText={WARNING_MODAL_EXIT_TEXTS.cancelButtonText}
        actionType="delete"
      />
    </>
  )
};

export default QuoteItemFormModal;
