import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { TimePicker } from 'antd';
import * as dayjs from 'dayjs';

function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedCustomer = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  const userType = useSelector((state) => state.auth.user.userType);
  const [provinceState, setProvinceState] = useState("")
  const [defaultTax, setDefaultTax] = useState("")
  const [country, setCountry] = useState("")
  const provinceStateOptions = PROVINCE_STATE;

  useEffect(() => {
    if (isFormEdit) {
      const provinceOrState = PROVINCE_STATE.find((provinceState) => provinceState.value === selectedCustomer["provinceState"]);
      setProvinceState(provinceOrState);
      setDefaultTax(parseInt(selectedCustomer['defaultTaxCode']));
      setCountry(parseInt(selectedCustomer['country']));
      setValue("provinceState", provinceOrState);
      setValue("defaultTaxCode", parseInt(selectedCustomer['defaultTaxCode']));
      setValue("active", selectedCustomer['active']);
      setValue("phone", selectedCustomer['phone']);
      setValue("fax", selectedCustomer['fax']);
      setValue("cell", selectedCustomer['cell']);
      setValue("notes", selectedCustomer['notes']);
      setValue("country", parseInt(selectedCustomer['country']));

      if (selectedCustomer['openingHour'] != null) {
        const today = dayjs();
        const openingHourMoment = dayjs(`${today.format('YYYY-MM-DD')}T${selectedCustomer['openingHour']}`);
        setValue("openingHour", openingHourMoment);
      }

      if (selectedCustomer['closingHour'] != null) {
        const today = dayjs();
        const closingHourMoment = dayjs(`${today.format('YYYY-MM-DD')}T${selectedCustomer['closingHour']}`);
        setValue("closingHour", closingHourMoment);
      }

    } else {
      setProvinceState("");
      setDefaultTax("");
      setCountry("");
      reset()
    }

  }, [selectedCustomer["customerId"]])

  const getActiveDefaultValue = () => {
    if (selectedCustomer.length <= 0) {
      return 'true';
    }

    return selectedCustomer.active ? 'true' : '';
  }

  const setCountryValue = (e) => {
    const provinceStateValue = e?.value;
    if (provinceStateValue != null) {
      const firstUSState = PROVINCE_STATE.find((provinceState) => provinceState.label === "Alabama").value;
      setValue("country", provinceStateValue >= firstUSState ? 2 : 1);
    }
  }

  return (
    <>
      <div className="col-12 mb-2">
        <label htmlFor="name" className="form-label">Name <span className="required-asterisk">*</span></label>
        <input type="text" className="form-control" id="name" tabIndex={1}
          defaultValue={isFormEdit ? selectedCustomer?.customerName || '' : ''}
          autoComplete='on'
          {...register("customerName", { required: "Name is required" })}
        />
        <small className='form-error-message'>
          {errors?.customerName && errors.customerName.message}
        </small>
      </div>
    </>
  );
}

export default PrimaryDetails;
