import React, { useState, useRef, useEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { Input } from 'reactstrap';
import { PAY_METHOD_TYPE } from '../../../constants';
import { Select, Space } from 'antd';

export default memo(forwardRef(({value, api, editable}, ref) => {

  const refInput = useRef(null);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const handleBlur = () => {
    api.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue;
    },
  }));

  const onInputKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent default browser behavior
      const currentIndex = PAY_METHOD_TYPE.findIndex(option => option.value === selectedValue);
      let newIndex;
      if (e.key === 'ArrowUp') {
        newIndex = currentIndex > 0 ? currentIndex - 1 : PAY_METHOD_TYPE.length - 1;
      } else {
        newIndex = currentIndex < PAY_METHOD_TYPE.length - 1 ? currentIndex + 1 : 0;
      }
      const newValue = PAY_METHOD_TYPE[newIndex].value;
      setSelectedValue(newValue);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Select
        popupClassName='color-3'
        onChange={handleChange}
        onBlur={handleBlur}
        className='antd-select grid-column'
        options={PAY_METHOD_TYPE}
        value={selectedValue}
        open
        autoFocus
        onInputKeyDown={onInputKeyDown}
      />
    </div>
  )
}));
