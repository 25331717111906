import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import FormTabs from '../Commons/Layouts/FormTabs';
import { addExpenseItem, updateExpenseItem } from '../../services/expenseCategoryServices';
import { fetchGLCodesByUserType } from '../../services/glCodeServices';
import { removeNumberFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import ExpenseItemPrimaryDetails from './ExpenseItemPrimaryDetails';
import { setFormPendingCompletion, setFormPendingFunction, setNotificationData } from "../../redux/global/globalReducer";
import { updateFormDataOnExit } from '../../utils/formUtils';

function ExpenseItemForm({
  isFormEdit = false,
  selectedItem = {},
  isFormHidden,
  fillItemList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  province,
  focusOnOpenOrCloseButton
}) {

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const [gLCodeList, setGLCodeList] = useState([]);
  const isInitialRender = useRef(true);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode(accountId);
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("expenseTitle");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    updateForm();
  }, [formState])

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD);
    let response = await fetchGLCodesByUserType(userType, selectedAccountState.accountId, accountId, accountTypeId)
    setGLCodeList(response);
  }

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;

    _.set(payload, 'defaultRate', removeNumberFormat(payload.defaultRate) / 100);

    if (isFormEdit) {
      _.set(payload, 'expenseItemId', selectedItem.expenseItemId);
      _.set(payload, 'accountId', selectedItem.accountId);
      response = await updateExpenseItem(payload, keepAlive);

      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } expense item.`,
        })
      );
    } else {
      _.set(payload, 'expenseItemId', 0);
      if (isNTMSUSer(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addExpenseItem(payload, keepAlive);
      // showToast({
      //   type: `${response.ok ? 'success' : 'error'}`,
      //   message: `${response.ok ? 'Successfully added' : 'Failed to add'} expense item.`
      // });
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } expense item.`,
        })
      );
    }

    if (response.ok) {
      fillItemList("expense");
      if (isDirty) {
        setModal(false)
      }
      dispatch(setFormPendingCompletion(false));
      setIsFormDirty(false)
      reset({}, {keepValues: true});
      return true;
    }
    return false;
  }

  useEffect(() => {
    if ((isSuperAdmin || isNTMSUSer(userType)) && isFormEdit && !isFormHidden && selectedItem["accountId"] !== undefined) {
      fillGLCode(selectedItem["accountId"]);
    }
  }, [selectedItem])

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-6-section">
          <div className="row form-container" id="primary-details-form">
            <ExpenseItemPrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedItem={selectedItem}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              province={province}
              gLCodeList={gLCodeList}
              userType={userType}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="expense-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
        <FormTabs
          items={tabItems}
          tabClassName="color-6"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ExpenseItemForm