import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setFormPendingCompletion, setNotificationData } from "../../../redux/global/globalReducer";

import moment from "moment";
import { useForm } from "react-hook-form";
import { addCustomerData, setCustomerData } from "../../../redux/customer/customerReducer";
import { addCustomer } from "../../../services/customerServices";
import { removePhoneFormat } from "../../../utils/formatUtils";
import PrimaryDetails from "../../Customers/PrimaryDetails";
import { isNTMSUSer } from "../../../utils/roleUtils";

function CreateCustomerModal({
  modal,
  setModal,
  toggle,
  handleSelectCustomer,
  selectedFieldType,
  args,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    errors,
    control,
  } = useForm();

  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, "phone", removePhoneFormat(payload.phone));
    _.set(payload, "fax", removePhoneFormat(payload.fax));
    _.set(payload, "cell", removePhoneFormat(payload.cell));
    _.set(payload, "provinceState", payload.provinceState.value);

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return false;
    }

    if (payload.openingHour !== "" && payload.openingHour !== undefined) {
      const openingHour = moment(payload.openingHour.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "openingHour", openingHour);
    } else {
      const openingHour = moment()
        .set({ hours: 8, minutes: 0, seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "openingHour", openingHour);
    }

    if (payload.closingHour !== "" && payload.closingHour !== undefined) {
      const closingHour = moment(payload.closingHour.$d)
        .set({ seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "closingHour", closingHour);
    } else {
      const closingHour = moment()
        .set({ hours: 17, minutes: 0, seconds: 0 })
        .format("HH:mm:ss");
      _.set(payload, "closingHour", closingHour);
    }

    _.set(payload, "customerId", 0);
    if (isNTMSUSer(userType)) {
      _.set(
        payload,
        "accountId",
        accountState.accountId != null ? accountState.accountId : accountId
      );
    } else {
      _.set(payload, "accountId", accountId);
    }
    response = await addCustomer(payload);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } customer.`,
      })
    );

    if (response.ok) {
      const customerData = await response.json();
      dispatch(addCustomerData(customerData));
      toggle();

      reset({}, { keepValues: true });
      handleSelectCustomer({ value: customerData?.customerId, label: customerData?.customerName }, selectedFieldType);
      dispatch(setFormPendingCompletion(false));
      return true;
    }

    return false;
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size={"lg"} {...args}>
        <ModalHeader toggle={toggle}>Create New Customer</ModalHeader>
        <ModalBody>
          <form id="customer-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload))}>
            <div className="content-section-container color-1-section">
              <div className="row form-container" id="primary-details-form">
                <PrimaryDetails
                  isFormEdit={false}
                  control={control}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  reset={reset}
                />
              </div>

              <div className="row mt-3">
                <div className="col-12">
                  <div className="d-flex flex-row align-items-center">
                    <button className={`ms-auto btn btn-secondary`}>
                      Cancel
                    </button>
                    <button className={`ms-2 btn btn-primary`} type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal >
    </>
  );
}

export default CreateCustomerModal;
