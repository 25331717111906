import { get, post, patch, remove } from "./apiService";
import { isNTMSUSer, isAccountAdmin, isAccountUser } from '../utils/roleUtils';
import { statusFilter } from "../utils/quoteUtils";

export const fetchTMSQuotes = async () => {
  return get({ path: 'TMSQuote' });
}

export const fetchTMSQuotesFiltered = async (accountId) => {
  return get({ path: `TMSQuote/filtered` });
}

export const fetchTMSQuote = async (state) => {
  return get({ path: `TMSQuote/${state.quoteId}` });
}

export const fetchTMSQuoteData = async (state) => {
  return get({ path: `TMSQuote/data/${state.quoteId}` });
}

export const fetchTMSQuoteByOrder = async (state) => {
  return get({ path: `TMSQuote/${state.quoteId}` });
}

export const addTMSQuote = async (payload) => {
  return post({ path: `TMSQuote`, body: payload });
}

export const updateTMSQuote = async (payload) => {
  return patch({ path: `TMSQuote/update/${payload.tmsQuoteId}`, body: payload });
}

export const updateTMSQuoteStatus = async (payload) => {
  return patch({ path: `TMSQuote/updateStatus/${payload.tmsQuoteId}`, body: payload });
}

export const deleteTMSQuote = async (tmsQuoteId) => {
    return remove({ path: `TMSQuote/${tmsQuoteId}` });
}

export const updateQuoteNote = async (payload, quoteId) => {
  return patch({ path: `TMSQuote/note/${quoteId}`, body: payload });
}