import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { addTruckMaintenance, updateTruckMaintenance } from "../../services/truckMaintenanceServices";
import { isNTMSUSer } from "../../utils/roleUtils";
import { useDispatch, useSelector } from "react-redux";
import FormTabs from "../Commons/Layouts/FormTabs";
import { setFormPendingCompletion, setFormPendingFunction, setNotificationData } from "../../redux/global/globalReducer";
import {
  addTruckMaintenanceData,
  setTruckMaintenanceData,
} from "../../redux/truckMaintenance/truckMaintenanceReducer";
import moment from "moment";
import PrimaryDetails from "./PrimaryDetails";
import dayjs from "dayjs";
import { updateFormDataOnExit } from "../../utils/formUtils";

function TruckMaintenanceForm({
  isFormEdit = false,
  selectedTruckMaintenance = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  setModal,
  focusOnOpenOrCloseButton,
  truckCreationInformation,
  setTruckCreationInformation
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [truckMaintenanceType, setTruckMaintenanceType] = useState(1);
  const [compliances, setCompliances] = useState(1);
  const isInitialRender = useRef(true);

  const [truckVIN, setTruckVIN] = useState("");
  const [truckMake, setTruckMake] = useState("");
  const [truckModel, setTruckModel] = useState("");
  const [truckYear, setTruckYear] = useState();
  const [truckId, setTruckId] = useState("");



  // form values
  setValue("truckMaintenanceId", 0);
  setValue("accountId", accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("vin");
      }, 50);

      if (truckCreationInformation !== null) {
        setTruckVIN(truckCreationInformation.label);
        setTruckMake(truckCreationInformation.make);
        setTruckModel(truckCreationInformation.model);
        setTruckYear(moment(truckCreationInformation.year).clone().hour(7).minute(0).second(0).toDate());
        setTruckId(truckCreationInformation.value);
        setValue("vin", truckCreationInformation.label)
        setTruckCreationInformation(null);
      }
    } else {
      setTruckVIN("");
      setTruckMake("");
      setTruckModel("");
      setTruckYear("");
      setTruckId("");
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateForm();
  }, [formState])

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;
    if (truckVIN !== payload.vin) {
      setError("vin", { message: "Invalid Vehicle Insurance Number" });
      return false;
    }

    _.set(payload, "date", dayjs(payload.date).format("YYYY-MM-DD"));
    _.set(payload, "lastMaintenanceDate", dayjs(payload.lastMaintenanceDate).format("YYYY-MM-DD"));
    _.set(payload, "nextMaintenanceDate", dayjs(payload.nextMaintenanceDate).format("YYYY-MM-DD"));
    _.set(payload, "lastInspectionDate", dayjs(payload.lastInspectionDate).format("YYYY-MM-DD"));
    _.set(payload, "nextInspectionDate", dayjs(payload.nextInspectionDate).format("YYYY-MM-DD"));

    if (isFormEdit) {
      _.set(payload, "truckMaintenanceId", selectedTruckMaintenance.truckMaintenanceId);
      _.set(payload, "accountId", selectedTruckMaintenance.accountId);
      _.set(payload, "truckId", selectedTruckMaintenance.truck.truckId);

      response = await updateTruckMaintenance(payload, keepAlive);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } Truck Maintenance.`,
        })
      );
    } else {
      _.set(payload, "truckMaintenanceId", 0);

      if (isNTMSUSer(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      _.set(payload, 'truckId', truckId);
      response = await addTruckMaintenance(payload, keepAlive);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } Truck Maintenance.`,
        })
      );
    }

    if (response.ok) {
      const truckMaintenanceData = await response.json();
      dispatch(
        isFormEdit ? setTruckMaintenanceData(truckMaintenanceData) : addTruckMaintenanceData(truckMaintenanceData)
      );

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
      reset({}, {keepValues: true});
      return true;
    }
    return false;
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-7-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedTruckMaintenance={selectedTruckMaintenance}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
              setTruckVIN={setTruckVIN}
              setTruckMake={setTruckMake}
              setTruckModel={setTruckModel}
              setTruckYear={setTruckYear}
              setTruckId={setTruckId}
              setTruckMaintenanceType={setTruckMaintenanceType}
              setCompliances={setCompliances}
              truckVIN={truckVIN}
              truckMake={truckMake}
              truckModel={truckModel}
              truckYear={truckYear}
              compliances={compliances}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <>
        <form id="truck-maintenance-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
          <FormTabs
            items={tabItems}
            tabClassName="color-7"
            isFormHidden={isFormHidden}
          />

          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button className={`ms-auto btn btn-primary`} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    </>
  );
}

export default TruckMaintenanceForm;
