import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import QuoteTaxableEditor from '../Templates/Editor/QuoteTaxableEditor';
import ActionRemoveRowRenderer from '../Templates/CellRenderers/ActionRemoveRowRenderer';
import QuoteItemForm from './QuoteItemForm';
import { useLocation } from 'react-router-dom';
import QuoteDetails from './QuoteDetails';
import QuoteDetailFooter from './QuoteDetailFooter';
import QuoteTaxableRenderer from '../Templates/CellRenderers/QuoteTaxableRenderer';
import QuoteContentHeader from '../Commons/QuoteContentHeader'
import { displayProvince } from '../../utils/provinceUtils';
import { fetchQuoteData, fetchTableData, fetchItemList, fetchUpdateQuoteItem, fetchUpdateQuoteStatus, fetchUpdateQuoteNote } from '../../services/quoteServices'
import { currencyRendererSelector, calculateQuoteItem } from '../../utils/quoteUtils';
import { isSuperAdmin, isNTMSUSer } from '../../utils/roleUtils';
import { fetchGLCodeFilteredByAccountAndType } from '../../services/glCodeServices';
import { formatCurrency, enumValueFormatter, getQuickFilterValue, formatEnumValue } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { createQuoteItemsReportFile, emailQuoteItemsReport } from '../../services/reportServices';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import { setNotificationData, setFormPendingCompletion, setFormPendingFunction } from '../../redux/global/globalReducer';
import moment from 'moment';
import { fetchTMSQuoteData, fetchTMSQuoteByOrder, updateTMSQuoteStatus, updateQuoteNote } from '../../services/tmsQuoteServices';
import { addTMSQuoteItem, deleteTMSQuoteItem, updateTMSQuoteItem, updateTMSQuoteItems } from '../../services/tmsQuoteItemServices';
import { fetchOrdersByQuoteId } from '../../services/orderServices';
import { QUOTE_ITEM_CATEGORY, QUOTE_STATUS, PROVINCE_STATE } from '../../constants';
import QuoteItemCategoryEditor from '../Templates/Editor/QuoteItemCategoryEditor';
import QuoteItemPercentEditor from '../Templates/Editor/QuoteItemPercentEditor';
import DollarEditor from '../Templates/Editor/DollarEditor';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import DeleteItemActionRenderer from '../Templates/CellRenderers/Commons/DeleteItemActionRenderer';
import { DownOutlined } from '@ant-design/icons';
import QuoteItemCategoryRenderer from '../Templates/CellRenderers/Quotes/QuoteItemCategoryRenderer';
import { updateFormAndGridDataOnExit } from '../../utils/formUtils';

function EditQuotePage(props, args) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState,
  } = useForm({});
  const { dirtyFields, errors, isDirty } = formState;

  const userType = useSelector((state) => state.auth.user.userType);
  const { state } = useLocation()
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [quoteData, setQuoteData] = useState({});
  const [orderName, setOrderName] = useState("");


  const [loadId, setLoadId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [shipperName, setShipperName] = useState("");
  const [receiverName, setReceiverName] = useState("");

  const [orderDate, setOrderDate] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [estimateNo, setEstimateNo] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [tax, setTax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [province, setProvince] = useState("");
  const [gLCodeList, setGLCodeList] = useState([]);
  const [status, setStatus] = useState(0);
  const [note, setNote] = useState("");
  const [accountId, setAccountId] = useState("")
  const [customerId, setCustomerId] = useState("");
  const [rowData, setRowData] = useState()
  const [inputRow, setInputRow] = useState({ 'Action': 'action', 'Item': 'Add item:' });
  const [itemList, setItemList] = useState([]);
  const [editable, setEditable] = useState(true);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [oldValues, setOldValues] = useState({
    subtotal: 0,
    discount: 0,
    total: 0,
    tax: 0,
  })

  const [printMode, setPrintMode] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dirtyQuoteItemColumns, setDirtyQuoteItemColumns] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const taxRef = useRef()
  const taxSelectRef = useRef()
  const currentProvince = useRef()
  const currentInputRow = useRef()

  //Loads data on render
  useEffect(() => {
    fillQuoteData()
  }, [])

  // useEffect

  useEffect(() => {
    // if (dirtyQuoteItemColumns || !_.isEmpty(dirtyFields)) {
    //   dispatch(setFormPendingCompletion(true));
    //   dispatch(setFormPendingFunction(handleSubmit(async (payload) => await handleUpdateQuoteItems(payload, true))));
    //   setHasChanges(true)
    // } else {
    //   dispatch(setFormPendingCompletion(false));
    //   dispatch(setFormPendingFunction(null));
    //   setHasChanges(false)
    // }

    const updateForm = async () => {
      await updateFormAndGridDataOnExit(
        dispatch,
        dirtyQuoteItemColumns,
        !_.isEmpty(dirtyFields),
        setHasChanges,
        false,
        _.isEmpty(errors),
        handleSubmit,
        handleUpdateQuoteItems
      )
    }

    updateForm();
  }, [dirtyQuoteItemColumns, formState]);

  // Fetch after getting the accountId
  useEffect(() => {
    if (accountId !== "") {
      fillItemList()
      fillGLCode()
    }

  }, [accountId])

  useEffect(() => {
    currentInputRow.current = inputRow
    if (gridApi != null) {
      gridApi.refreshCells({ columns: ['Action'] })
    }
  }, [inputRow])

  //Fetch for the customer data
  const fillQuoteData = async () => {
    const data = await fetchTMSQuoteData(state);

    setQuoteData(data);
    // quoteDataRef.current.data = data;
  }

  useEffect(() => {
    if (!_.isEmpty(quoteData)) {
      fillPageData(quoteData);
    }

  }, [quoteData])

  //Fetch for the table data
  const fillPageData = (data) => {

    setLoadId(data?.order?.load?.loadId);
    setOrderStatus(data?.order?.status);

    setShipperName(data?.order?.shipperName);
    setReceiverName(data?.order?.receiverName);

    setName(data?.order?.customerName);
    setEmail(data.order?.customerDefaultEmail);
    setPhone(data.order?.customerPhoneNumber);
    setAddress(data?.order?.customerAddress1 + ", " + data?.order?.customerCity + ", " + formatEnumValue(data?.order?.customerState, PROVINCE_STATE));

    setEstimateNo(state?.quoteId);
    setEstimateName(data?.quoteName);
    setEstimateDate(data?.quoteDate);
    setTax(data?.total);
    setSubtotal(data?.total);
    setTotal(data?.total);
    setDiscount(data?.discount);
    setNote(data?.notes);
    setAccountId(data?.accountId);
    setCustomerId(data?.customerId);
    setStatus(data?.status)
    setOldValues({
      subtotal: parseFloat(data?.subtotal),
      discount: parseFloat(data?.discount),
      total: parseFloat(data?.totalQuote),
      tax: parseFloat(data?.totalTax)
    })


    setValue("orderId", data?.orderId);
    setValue("quoteName", data?.quoteName);
    setValue("date", data?.quoteDate);
    setValue("notes", data?.notes);
    setValue("status", data?.status);
    setValue("total", data?.quote);
    setValue("quoteDate", data?.quoteDate);

    // setValue("notes", data?.notes)
    setValue("accountId", data?.accountId)
    setValue("tmsQuoteId", data?.tmsQuoteId)

    if (parseInt(data?.status) !== 0 || (isSuperAdmin(userType))) {
      setEditable(false);
      setColumnDefs(columnDefinitions(false));
    }

    setRowData(data?.tmsQuoteItem?.map((quoteItem, index) => ({
      '#': index + 1,
      Type: quoteItem.type,
      Category: quoteItem.category,
      Description: quoteItem.description,
      Quantity: quoteItem.quantity,
      Rate: quoteItem.rate,
      Total: quoteItem.cost,
      Notes: quoteItem.notes,
      QuoteItemId: quoteItem.tmsQuoteItemId,
      Id: quoteItem.tmsQuoteItemId,
      QuoteId: quoteItem.tmsQuoteId,
      OrderId: quoteItem.orderId,
      AccountId: quoteItem.accountId,
      UpdateStatus: 0,
    })).reverse())
  }

  const fillGLCode = async (id) => {
    let response = null
    if (isNTMSUSer(userType)) {
      response = selectedAccountState.accountId === null ? await fetchGLCodeFilteredByAccountAndType(accountId, 5) : await fetchGLCodeFilteredByAccountAndType(selectedAccountState.accountId, 5);
    }
    else {
      response = await fetchGLCodeFilteredByAccountAndType(accountId, 5);
    }
    setGLCodeList(response);
  }

  const fillItemList = async () => {
    const data = await fetchItemList(accountId);
    const labour = data.labour;
    const material = data.productMaterialItem;
    const expense = data.expenseItem;
    var items = {};
    items = [...labour, ...material, ...expense];
    setItemList(items);
  }



  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  //Resizes the columns to fit the whole grid
  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    setGridApi(params.api);

    gridRef.current.api.setFilterModel({
      UpdateStatus: {
        type: 'notEqual',
        filter: 3,
      },
    })
  }, []);

  const columnDefinitions = (isEditable) => {
    
    const defs = [
      {
        field: 'Category',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column",
            `${params.data.UpdateStatus === 1 && 'bold'}`,
          ],
        cellEditor: QuoteItemCategoryEditor,
        cellEditorParams: (params) => {
          if (params.data && (params.data.Type === 1 || params.data.Type === 2)) {
            const filteredCategory = params.data.Type === 1
              ? QUOTE_ITEM_CATEGORY.filter(item => item.value !== 0 && item.value !== 3)
              : QUOTE_ITEM_CATEGORY.filter(item => item.value !== 0);

            return {
              values: filteredCategory,
              api: params.api,
            };
          } else {
            return {};
          }
        },
        valueSetter: (params) => {
          const selectedValue = parseInt(params.newValue, 10);
          params.data.Category = selectedValue;
          params.data.Quantity = params.data.Category === 3 ? 0 : 1;
          calculateQuoteItem(params)
          return true;
        },
        cellRenderer: QuoteItemCategoryRenderer,
        cellRendererParams: {editable: isEditable},
        editable: (params) => (params.data.Type === 1 || params.data.Type === 2) && isEditable,
        getQuickFilterText: (params) => {
          const categoryValue = params.value;
          return getQuickFilterValue(categoryValue, QUOTE_ITEM_CATEGORY);
        },
      },
      {
        field: 'Description',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        editable: (params) => params.data.Type === 3 && isEditable,
      },
      {
        field: 'Quantity',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        cellEditor: QuoteItemPercentEditor,
        editable: isEditable,
        valueFormatter: (params) => {
          return params.data.Category === 3 ? params.value + "%" : params.value
        },
        valueSetter: (params) => calculateQuoteItem(params),
      },
      {
        field: 'Rate',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        cellEditor: DollarEditor,
        editable: isEditable,
        valueFormatter: (params) => { return formatCurrency(params.value) },
        valueSetter: (params) => calculateQuoteItem(params),
        getQuickFilterText: params => { return formatCurrency(params.value); },
      },
      {
        field: 'Total',
        minWidth: 150,
        cellClass: (params) =>
          [
            "grid-column gray",
            `${params.data.UpdateStatus === 1 && 'bolded'}`
          ],
        valueFormatter: (params) => { return formatCurrency(params.value) },
        getQuickFilterText: params => { return formatCurrency(params.value); },

      },
      {
        field: 'UpdateStatus',
        headerName: 'Update Status',
        colId: 'UpdateStatus',
        minWidth: 150,
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: 'Edit',
        minWidth: 150,
        headerName: '',
        cellClass: 'd-flex flex-row-reverse',
        autoHeight: true,
        hide: !isEditable,
        cellRenderer: DeleteItemActionRenderer,
        cellRendererParams: props => ({
          header: 'Quote Item',
          parentType: "Quote",
          quoteData: quoteDataRef,
          props: props,
          note: note,
          handleSubmit: handleSubmit,
          handleDeleteFunction: handleDeleteQuoteItem
        }),

      },
    ]

    return defs
  }

  const quoteDataRef = useRef()
  quoteDataRef.current = note;

  const [columnDefs, setColumnDefs] = useState(columnDefinitions(true))

  useEffect(() => {
    if (gridRef.current.columnApi !== undefined) {
      gridRef.current.columnApi.setColumnVisible("Action", editable);
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs])


  const updateNoteStatus = async (event) => {
    if (!dirtyQuoteItemColumns) {
      var value = event.target.value.trim()
      setNote(value);
      setDirtyQuoteItemColumns(true);
    }
  }

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' };
    }
  }, []);

  const sendQuoteItemsReport = async (payload) => {
    setIsLoading(true);

    const emailPayload = {
      quoteId: state.quoteId,
      toEmail: payload.emailAddress,
      subject: 'Quote Items Report',
      body: 'Report attached here',
      attachmentFileName: 'Quote Items Report'
    };

    const response = await emailQuoteItemsReport(emailPayload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    setIsLoading(false);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } quote items report.`,
      })
    );
  }

  const printQuoteItems = async () => {
    const payload = {
      quoteId: state.quoteId,
    };

    const response = await createQuoteItemsReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      dispatch(
        setNotificationData({
          type: `error`,
          message: `Error!`,
          description: data.errorMessage,
        })
      );
      return;
    }

    printPreviewReportWithBase64(data.base64);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const scrollToLastRow = () => {
    const api = gridRef.current.api;
    const lastRowIndex = api.getDisplayedRowCount() - 1;
    api.ensureIndexVisible(lastRowIndex, 'bottom');
  }
  const focusOnLastRow = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const lastRowIndex = api.getDisplayedRowCount() - 1;
      api.ensureIndexVisible(lastRowIndex, 'bottom');
      api.startEditingCell({
        rowIndex: lastRowIndex,
        colKey: 'Unit Price', // Replace with the actual column key for "unit price"
      });
      // Get the last row node
      const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);
      // Deselect all other nodes
      api.deselectAll();
      // Select the last row node
      lastRowNode.setSelected(true);
    }
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const selectList = QUOTE_STATUS.map((status) => ({
    label: status.label,
    value: status.value,
  }));

  const onChangeStatus = async (value) => {
    let response = null;

    if(hasChanges) {
      await handleSubmit(async quotePayload => await handleUpdateQuoteItems(quotePayload) )
    }

    const payload = {
      status: value,
      accountId: quoteData?.accountId,
      tmsQuoteId: quoteData?.tmsQuoteId,
      orderId: quoteData?.orderId
    }

    response = await updateTMSQuoteStatus(payload, quoteData?.tmsQuoteId);
    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully update" : "Failed to update"
          } quote status.`,
      })
    );

    if (response.ok) {
      const newData = await response.json();
      fillQuoteData();
      if (newData?.status === 1) {
        setEditable(false);
        setColumnDefs(columnDefinitions(false));
      } else {
        setEditable(true);
        setColumnDefs(columnDefinitions(true));
      }
      return true;
    }
    return false;
  }

  const handleAddQuoteItem = async () => {
    const newItem = {
      '#': rowData.length + 1,
      Type: 3,
      Category: 0,
      Description: "Other",
      Quantity: 1,
      Rate: 0,
      Total: 0,
      Notes: "",
      QuoteItemId: 0,
      Id: 0,
      QuoteId: quoteData?.tmsQuoteId,
      OrderId: quoteData?.orderId,
      AccountId: quoteData?.accountId,
      UpdateStatus: 1
    };

    const transaction = {
      add: [newItem],
      addIndex: 0
    };

    gridRef.current.api.applyTransaction(transaction);
    setDirtyQuoteItemColumns(true);

    setTimeout(() => {
      gridRef.current.api.startEditingCell({
        rowIndex: 0,
        colKey: 'Description'
      });
    }, 200);
  }

  const handleUpdateQuoteItems = async (payload, keepAlive = false) => {
    var quoteItems = [];
    if (gridRef.current.api.getDisplayedRowCount() === 0) {
      dispatch(
        setNotificationData({
          type: "error",
          message: "Error!",
          description: "Please add a load item.",
        })
      );
      return false;
    }

    gridRef.current.api.forEachNode(node => {
      const quoteItem = node.data;
      var payloadData = {
        accountId: quoteItem?.AccountId,
        orderId: quoteItem?.OrderId,
        description: quoteItem?.Description,
        quantity: parseFloat(quoteItem?.Quantity),
        rate: parseFloat(quoteItem?.Rate),
        cost: parseFloat(quoteItem?.Total),
        notes: quoteItem?.notes,
        "type": quoteItem?.Type,
        "category": quoteItem?.Category,
        "tmsQuoteId": quoteItem?.QuoteId,
        "tmsQuoteItemId": quoteItem?.QuoteItemId,
        updateStatus: quoteItem?.UpdateStatus,
      }
      quoteItems.push(payloadData);
    });

    _.set(payload, 'tmsQuoteItem', quoteItems);

    let response = await updateTMSQuoteItems(payload, keepAlive);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } quote.`,
      })
    );

    if (response.ok) {
      const returnData = await response.json();
      fillPageData(returnData);
      setDirtyQuoteItemColumns(false);
      setHasChanges(false);
      reset({}, { keepValues: true, });
      return true;
    }
    return false;
  }

  const handleDeleteQuoteItem = async (currentData) => {
    const itemsToUpdate = [];
    const selectedRows = gridRef.current.api.getSelectedRows();
    const currentRow = selectedRows[0];

    if (currentRow["UpdateStatus"] === 0 || currentRow["UpdateStatus"] === 2) {
      currentRow["UpdateStatus"] = 3;
      itemsToUpdate.push(currentRow);
      gridRef.current.api.applyTransaction({ update: itemsToUpdate });
      setDirtyQuoteItemColumns(true);

    }

    if (currentRow["UpdateStatus"] === 1) {
      gridRef.current.api.applyTransaction({ remove: [currentRow] });
      setDirtyQuoteItemColumns(true);
    }

    updateTotal(gridRef.current.api);
  }

  const onCellValueChanged = (params) => {
    const itemsToUpdate = [];
    const data = params.data;
    if (data["UpdateStatus"] === 0) {
      data["UpdateStatus"] = 2;
      itemsToUpdate.push(data);
      gridRef.current.api.applyTransaction({ update: itemsToUpdate });
      setDirtyQuoteItemColumns(true);
    }
    updateTotal(params.api);
  };

  const parentNavItem = {
    title: "Quotes",
    link: "/quotes",
  };

  const updateTotal = (api) => {
    var currentTotals = 0;
    api.forEachNode(node => {
      if (node?.data?.UpdateStatus !== 3) {
        currentTotals += (node.data.Quantity * node.data.Rate);
      }
    });

    setTotal(currentTotals);
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title='Edit Quote'
            subtitle={"quotes"}
            dataCount={rowData?.length}
            hideButton={true}
            hideSubtitle={true}
            hideSelectDropdown={false}
            filterData={filterData}
            selectList={selectList}
            color='color-3'
            hideStatus={editable}
            hideTotal={true}
            selectDropdownFunction={onChangeStatus}
            selectDropdownValue={quoteData?.status}
            disabledSelectDropdown={!editable}
            parentNavItem={parentNavItem}
          />
        </div>
      </div>
      <div id='printableContent'>
        <QuoteDetails
          shipperName={shipperName}
          receiverName={receiverName}
          orderStatus={orderStatus}
          name={name}
          email={email}
          phone={phone}
          address={address}
          estimateNo={estimateNo}
          estimateName={estimateName}
          estimateDate={estimateDate}
          subtotal={subtotal}
          discount={discount}
          total={total}
          tax={tax}
          printMode={printMode}
          printQuoteItems={printQuoteItems}
          setIsOpen={setIsOpen}
        />
        {editable && <div className="col-12 mb-2 d-flex flex-row-reverse mb-2">
          <button
            className="btn btn-md btn-color-3 me-2"
            form="quote-item-form"
            onClick={handleAddQuoteItem}
            type="button"
            tabIndex={8}
          >
            Add Quote Item
          </button>
        </div>}
        <div className='row content-body-container quote-body-container'>
          <div className='col-12'>
            <div id='quoteGrid' className='ag-theme-alpine content-section-container quote-container color-3-grid color-3-section p-0 shadow-none'>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                onCellValueChanged={onCellValueChanged}
                rowSelection={'single'}
                getRowStyle={getRowStyle}
                rowHeight={70}
              >
              </AgGridReact>
            </div>
          </div>
        </div>
        <div className='row content-body-container quote-footer-container'>
          <div className='col-12'>
            <div className='content-section-bottom'>
              <QuoteDetailFooter
                updateNoteStatus={updateNoteStatus}
                note={note}
                editable={editable}
                subtotal={subtotal}
                discount={discount}
                total={total}
                tax={tax}
                printMode={printMode}
                quoteData={quoteData}
                register={register}
              />
            </div>
            {editable &&
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex flex-row align-items-center">
                    <button
                      className={`ms-auto btn btn-primary`}
                      onClick={handleSubmit(async payload => await handleUpdateQuoteItems(payload))}
                      type='button'
                      disabled={!hasChanges}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
      </div>
      {/* <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} {...args}>
        <ModalHeader toggle={() => setIsOpen(false)}>Send Email</ModalHeader>
        <ModalBody>
          <form className="row form-container" id="send-email-form" onSubmit={handleSubmit(sendQuoteItemsReport)}>
            <div className="col-12 my-3">
              <label htmlFor="emailAddress" className="form-label">Email Address</label>
              <input type="text" className="form-control" id="emailAddress"
                defaultValue={email}
                {...register("emailAddress", { required: "Email Address is required" })}
              />
              <small className='form-error-message'>
                {errors?.emailAddress && errors.emailAddress.message}
              </small>
            </div>

            <div className='d-flex flex-row-reverse align-items-center mt-3'>
              <button className="btn btn-primary ms-2" form='send-email-form' type="submit" disabled={isLoading}>Send</button>
              <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => setIsOpen(false)}>Cancel</button>
            </div>
          </form>
        </ModalBody>
      </Modal> */}

    </div>
  )
}

export default EditQuotePage