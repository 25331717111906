import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { addTruck, updateTruck } from "../../services/truckServices";
import {
  isNTMSUSer,
} from "../../utils/roleUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  addTruckData,
  setTruckData,
} from "../../redux/truck/truckReducer";
import PrimaryDetails from "./PrimaryDetails";
import FormTabs from "../Commons/Layouts/FormTabs";
import { setFormPendingCompletion, setFormPendingFunction, setNotificationData } from "../../redux/global/globalReducer";
import dayjs from "dayjs";
import { updateFormDataOnExit } from "../../utils/formUtils";

function TruckForm({
  isFormEdit = false,
  selectedTruck = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const isInitialRender = useRef(true);

  // form values
  setValue("truckId", 0);
  setValue("accountId", accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("vin");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateForm();
  }, [formState])

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;

    _.set(payload, "year", dayjs(payload.year).format("YYYY-MM-DD"));
    _.set(
      payload,
      "truckFinancials.purchaseDate",
      dayjs(payload.truckFinancials.purchaseDate).format("YYYY-MM-DD")
    );
    _.set(payload, "truckFinancials.accountId", accountId);

    if (isFormEdit) {
      _.set(payload, "truckId", selectedTruck.truckId);
      _.set(payload, "accountId", selectedTruck.accountId);
      _.set(
        payload,
        "truckFinancials.truckFinancialsId",
        selectedTruck.truckFinancials.truckFinancialsId
      );

      response = await updateTruck(payload, keepAlive);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } truck.`,
        })
      );
    } else {
      _.set(payload, "truckId", 0);
      _.set(payload, "truckFinancials.truckFinancialsId", 0);

      if (isNTMSUSer(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      response = await addTruck(payload, keepAlive);
      dispatch(
        setNotificationData({
          type: `${response.ok ? "success" : "error"}`,
          message: `${response.ok ? "Success!" : "Error!"}`,
          description: `${response.ok ? "Successfully saved" : "Failed to save"
            } truck.`,
        })
      );
    }

    if (response.ok) {
      const truckData = await response.json();
      dispatch(
        isFormEdit ? setTruckData(truckData) : addTruckData(truckData)
      );

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      dispatch(setFormPendingCompletion(false));
      reset({}, {keepValues: true});
      return true;
    }
    return false;
  };

  const tabItems = [
    {
      label: "Primary Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-7-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              setIsFormDirty={setIsFormDirty}
              selectedTruck={selectedTruck}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <>
        <form id="truck-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
          <FormTabs
            items={tabItems}
            tabClassName="color-7"
            isFormHidden={isFormHidden}
          />

          <div className="row mt-5">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <button className={`ms-auto btn btn-primary`} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    </>
  );
}

export default TruckForm;
