import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash';
import { addPayrollRun } from '../../services/payrollRunServices';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nextPayrollDateRange } from '../../utils/payrollRunUtils';
import { isNTMSUSer } from '../../utils/roleUtils';
import PrimaryDetails from './PrimaryDetails';
import FormTabs from '../Commons/Layouts/FormTabs';
import { setFormPendingCompletion, setNotificationData } from "../../redux/global/globalReducer";
import ModalRender from '../Commons/ModalRender';
import moment from 'moment';
import dayjs from 'dayjs';
import { updateFormDataOnExit } from '../../utils/formUtils';

function PayrollRunForm({
  isFormEdit = false,
  isFormHidden,
  payrollRunList,
  payrollSettings,
  setIsFormDirty,
  setModal,
  toggleFormDisplay,
  modal,
  toggle
}) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState
  } = useForm();
  const dispatch = useDispatch();
  const { dirtyFields, errors, isDirty } = formState;
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [minDate, setMinDate] = useState([]);
  const isInitialRender = useRef(true);

  // // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    fillDateRange();
  }, [payrollSettings])

  useEffect(() => {
    clearErrors();
    reset();
    fillDateRange();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("payrollRunDate");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const updateForm = async () => {
      await updateFormDataOnExit(
        dispatch,
        !_.isEmpty(dirtyFields),
        setIsFormDirty,
        isFormEdit,
        _.isEmpty(errors),
        handleSubmit,
        onSubmit
      )
    }
    
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateForm();
  }, [formState])

  useEffect(() => {
    fillMinDate()
  }, [payrollRunList])

  const onSubmit = async (payload, keepAlive = false) => {
    let response = null;

    var startDate = moment(payload.startDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var endDate = moment(payload.endDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var payrollRunDate = dayjs(payload.payrollRunDate).format('YYYY-MM-DD');
    var processingDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'startDate', startDate);
    _.set(payload, 'endDate', endDate);
    _.set(payload, 'payrollRunDate', payrollRunDate);
    _.set(payload, 'processingDate', processingDate);
    _.set(payload, 'payrollRunType', 1);

    _.set(payload, 'payrollRunId', 0);
    _.set(payload, 'accountId', isNTMSUSer(userType) && selectedAccountState.accountId !== null ? selectedAccountState.accountId : accountId);

    response = await addPayrollRun(payload, keepAlive);

    dispatch(
      setNotificationData({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Success!" : "Error!"}`,
        description: `${response.ok ? "Successfully saved" : "Failed to save"
          } payroll run.`,
      })
    );


    if (response.ok) {
      if (!isFormEdit) {
        const data = await response.json();
        const newPayrollRunId = data.payrollRunId;
        if (isDirty) {
          setModal(false);
        }
        setIsFormDirty(false);
        dispatch(setFormPendingCompletion(false));
        navigate("/payroll-runs/edit-payroll-run", { state: { payrollRunId: newPayrollRunId, status: false } });
      }
      return true;
    }
    return false;
  }

  const fillMinDate = async () => {
    const payrollSettingsCheck = payrollSettings !== null && payrollSettings !== undefined && !_.isEmpty(payrollSettings);
    const payrollRunListCheck = payrollRunList !== null && payrollRunList !== undefined && payrollRunList.length > 0;
    if (payrollRunListCheck) {
      const lastPayroll = payrollRunList.reduce((prev, current) => {
        return (prev.endDate > current.endDate) ? prev : current
      });
      const minDate = new Date(lastPayroll.endDate);
      setMinDate(minDate.setDate(minDate.getDate() + 1))
    }
    else if (payrollSettingsCheck) {
      setMinDate(new Date(payrollSettings.payrollStartDate));
    }
  }

  const fillDateRange = () => {
    const payrollSettingsCheck = payrollSettings !== null && payrollSettings !== undefined && !_.isEmpty(payrollSettings);
    const payrollRunListCheck = payrollRunList !== null && payrollRunList !== undefined && payrollRunList.length > 0;
    var dateRange = [null, null]
    if (payrollRunListCheck) {

      const lastPayroll = payrollRunList.reduce((prev, current) => {
        return (prev.endDate > current.endDate) ? prev : current
      });
      dateRange = nextPayrollDateRange(moment(lastPayroll.endDate).format('YYYY-MM-DD'), payrollSettings.payrollPeriod);
      setValue('startDate', new Date(dateRange[0]));
      setValue('endDate', dateRange[1]);
    }
    else if (payrollSettingsCheck) {
      const payrollStartDate = moment(payrollSettings.payrollStartDate).format('YYYY-MM-DD');
      dateRange = nextPayrollDateRange(payrollStartDate, payrollSettings.payrollPeriod, true);
      setValue('startDate', dateRange[0]);
      setValue('endDate', dateRange[1]);
    }
  }

  const tabItems = [
    {
      label: "Payroll Run Details",
      key: 0,
      forceRender: true,
      children: (
        <div className="content-section-container color-4-section">
          <div className="row form-container" id="primary-details-form">
            <PrimaryDetails
              isFormEdit={isFormEdit}
              isFormHidden={isFormHidden}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              reset={reset}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <form id="payroll-run-form" onSubmit={handleSubmit(async (payload) => onSubmit(payload, false))}>
        <FormTabs
          items={tabItems}
          tabClassName="color-4"
          isFormHidden={isFormHidden}
        />

        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center">
              <button className={`ms-auto btn btn-primary`} type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <ModalRender
        modal={modal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        toggle={toggle}
        isFormEdit={isFormEdit}
        formType={"payroll-run"}
        toggleFormDisplay={toggleFormDisplay}
      />
    </>
  )
}

export default PayrollRunForm
