import { AccessTime, CalendarMonth } from "@mui/icons-material";
import { Checkbox, Tabs } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRY,
  PROVINCE_STATE,
  WARNING_MODAL_OVERWRITE_DETAILS_TEXTS,
} from "../../constants";
import { setLoadItemsListData } from "../../redux/loadItems/loadItemsReducer";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";
import AddPickDropModal from "../Commons/Modals/AddPickDropModal";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PickDropForm from "../Commons/Forms/PickDropForm";

const DraggableTabNode = ({ className, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
  };
  return React.cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

const NonDraggableTabNode = ({ className, ...props }) => {
  const style = {
    ...props.style,
    cursor: "default", // Set cursor to default (not draggable)
  };
  return React.cloneElement(props.children, {
    ref: props.innerRef, // Forward the ref if provided
    style,
    ...props,
  });
};

const OrderDetails = (
  {
    isFormHidden,
    selectedOrder = {},
    checkPageEditable,
    setValue,
    control,
    register,
    errors,
    setDirtyLoadOrderColumns,
    selectedPickupAddressValues,
    setSelectedPickupAddressValues,
    copyAddressPickupCheckedValue,
    setCopyAddressPickupCheckedValue,
    fillPickupDetails,
    selectedDropoffAddressValues,
    setSelectedDropoffAddressValues,
    copyAddressDropoffCheckedValue,
    setCopyAddressDropoffCheckedValue,
    fillDropoffDetails,
  },
  ref
) => {
  const dispatch = useDispatch();

  const coordinatesState = useSelector((state) => state.global.coordinates);
  const [tabItems, setTabItems] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [overwritePickupModal, setOverwritePickupModal] = useState(false);
  const [overwriteDropoffModal, setOverwriteDropoffModal] = useState(false);
  const [createDriverModal, setCreateDriverModal] = useState(false);
  const [pickDropData, setPickDropData] = useState([]);
  const createDriverToggle = () => setCreateDriverModal(!createDriverModal);

  useEffect(() => {
    if (isFormHidden) {
      setDirtyLoadOrderColumns(false);
      dispatch(setLoadItemsListData([]));
    }
  }, [isFormHidden]);

  useEffect(() => {
    if (!_.isEmpty(selectedOrder)) {
      setValue("instructions", selectedOrder.instructions);
      setValue(
        "pickupStartDate",
        moment(selectedOrder.pickupStartDate)
          .clone()
          .hour(7)
          .minute(0)
          .second(0)
          .toDate()
      );
      setValue(
        "pickupEndDate",
        moment(selectedOrder.pickupEndDate)
          .clone()
          .hour(7)
          .minute(0)
          .second(0)
          .toDate()
      );
      setValue(
        "dropoffStartDate",
        moment(selectedOrder.dropoffStartDate)
          .clone()
          .hour(7)
          .minute(0)
          .second(0)
          .toDate()
      );
      setValue(
        "dropoffEndDate",
        moment(selectedOrder.dropoffEndDate)
          .clone()
          .hour(7)
          .minute(0)
          .second(0)
          .toDate()
      );

      setValue(
        "pickupStartTime",
        moment(selectedOrder.pickupStartTime, "HH:mm:ss").toDate()
      );
      setValue(
        "pickupEndTime",
        moment(selectedOrder.pickupEndTime, "HH:mm:ss").toDate()
      );
      setValue(
        "dropoffStartTime",
        moment(selectedOrder.dropoffStartTime, "HH:mm:ss").toDate()
      );
      setValue(
        "dropoffEndTime",
        moment(selectedOrder.dropoffEndTime, "HH:mm:ss").toDate()
      );
    }
  }, [selectedOrder]);

  useEffect(() => {
    const defaultTabItems = [
      {
        key: "1",
        label: "Pickup",
        children: (
          <PickDropForm
            stopType={1}
            formId={1}
            control={control}
            register={register}
            setValue={setValue}
          />
        ),
      },
      {
        key: "2",
        label: "Dropoff",
        children: (
          <PickDropForm
            stopType={2}
            formId={2}
            control={control}
            register={register}
            setValue={setValue}
          />
        ),
      },
      {
        key: "0",
        label: "+",
        children: null,
      },
    ];

    setTabItems(defaultTabItems);
  }, []);

  // (
  //   <div className="col-12">
  //     <div className="row">
  //       <div className="col-12 mb-4">
  //         <Checkbox
  //           checked={copyAddressDropoffCheckedValue}
  //           onChange={(e) =>
  //             handleDropoffCheckboxOnChange(e.target.checked)
  //           }
  //           className="checkbox-copy-address"
  //         >
  //           Copy address from receiver details
  //         </Checkbox>
  //       </div>
  //       <div className="col-12 mb-3">
  //         <label htmlFor="dropoffName" className="form-label">
  //           Name
  //         </label>
  //         <input
  //           type="text"
  //           className="form-control"
  //           id="dropoffName"
  //           tabIndex={1}
  //           value={"-"}
  //           disabled={checkPageEditable()}
  //           {...register("dropoffName", {
  //             required: "Name is required",
  //           })}
  //         />
  //         <small className="form-error-message">
  //           {errors?.dropoffName && errors.dropoffName.message}
  //         </small>
  //       </div>
  //       <div className="col-12">
  //         <label className="form-label">Dropoff Details</label>
  //       </div>
  //     </div>

  //     <div className="row mb-4">
  //       <AutocompleteAddressForm
  //         setAddressValues={setDropoffAddressValues}
  //         setAddressNotes={setDropoffAddressNotes}
  //         selectedOrderAddressValues={selectedDropoffAddressValues}
  //       />
  //     </div>

  //     {/* Dropoff Start Date - Time */}
  //     <div className="row mb-2">
  //       <div className="col-12 mb-1">
  //         <div className="d-flex flex-row align-items-center">
  //           <label className="form-label simple mb-0">
  //             Start Date / Start Time:
  //           </label>
  //           <div className="ms-3">
  //             <Controller
  //               control={control}
  //               name="dropoffStartDate"
  //               register={register}
  //               rules={{ required: "dropoff Start Date is required" }}
  //               render={({ field: { onChange, onBlur, value, ref } }) => (
  //                 <ReactDatePicker
  //                   className="datepicker-field"
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   selected={value}
  //                   dateFormat="yyyy-MM-dd"
  //                   id="dropoffStartDate"
  //                   tabIndex={8}
  //                   disabled={checkPageEditable()}
  //                 />
  //               )}
  //             />
  //             <small className="form-error-message">
  //               {errors?.pickupStartDate &&
  //                 errors.pickupStartDate.message}
  //             </small>
  //             <small className="form-error-message">
  //               {errors?.pickupStartTime &&
  //                 errors.pickupStartTime.message}
  //             </small>
  //           </div>
  //           <div className="ms-2">
  //             <CalendarMonth />
  //           </div>
  //           <div className="ms-4">
  //             <Controller
  //               control={control}
  //               name="dropoffStartTime"
  //               register={register}
  //               rules={{ required: "dropoff Start Time is required" }}
  //               render={({ field: { onChange, onBlur, value, ref } }) => (
  //                 <ReactDatePicker
  //                   className="datepicker-field"
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   selected={value}
  //                   showTimeSelect
  //                   showTimeSelectOnly
  //                   timeIntervals={15}
  //                   timeCaption="Time"
  //                   timeFormat="HH:mm"
  //                   dateFormat="HH:mm"
  //                   id="dropoffStartTime"
  //                   tabIndex={8}
  //                   disabled={checkPageEditable()}
  //                 />
  //               )}
  //             />
  //           </div>
  //           <div className="ms-2">
  //             <AccessTime />
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //     {/* dropoff End Date - Time */}
  //     <div className="row">
  //       <div className="col-12 mb-1">
  //         <div className="d-flex flex-row align-items-center">
  //           <label className="form-label simple mb-0 me-2">
  //             End Date / End Time:
  //           </label>
  //           <div className="ms-4">
  //             <Controller
  //               control={control}
  //               name="dropoffEndDate"
  //               register={register}
  //               rules={{ required: "dropoff End Date is required" }}
  //               render={({ field: { onChange, onBlur, value, ref } }) => (
  //                 <ReactDatePicker
  //                   className="datepicker-field"
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   selected={value}
  //                   dateFormat="yyyy-MM-dd"
  //                   id="dropoffEndDate"
  //                   tabIndex={8}
  //                   disabled={checkPageEditable()}
  //                 />
  //               )}
  //             />
  //             <small className="form-error-message">
  //               {errors?.pickupEndDate && errors.pickupEndDate.message}
  //             </small>
  //             <small className="form-error-message">
  //               {errors?.pickupEndTime && errors.pickupEndTime.message}
  //             </small>
  //           </div>
  //           <div className="ms-2">
  //             <CalendarMonth />
  //           </div>
  //           <div className="ms-4">
  //             <Controller
  //               control={control}
  //               name="dropoffEndTime"
  //               register={register}
  //               rules={{ required: "dropoff End Time is required" }}
  //               render={({ field: { onChange, onBlur, value, ref } }) => (
  //                 <ReactDatePicker
  //                   className="datepicker-field"
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   selected={value}
  //                   showTimeSelect
  //                   showTimeSelectOnly
  //                   timeIntervals={15}
  //                   timeCaption="Time"
  //                   timeFormat="HH:mm"
  //                   dateFormat="HH:mm"
  //                   id="dropoffEndTime"
  //                   tabIndex={8}
  //                   disabled={checkPageEditable()}
  //                 />
  //               )}
  //             />
  //           </div>
  //           <div className="ms-2">
  //             <AccessTime />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  // useEffect(() => {
  //   if (
  //     !coordinatesState?.origin?.isInitialData &&
  //     !selectedPickupAddressValues?.isPreFilled
  //   ) {
  //     setSelectedPickupAddressValues({
  //       addressLine1: coordinatesState?.origin?.addressLine1,
  //       addressLine2: coordinatesState?.origin?.addressLine2,
  //       provinceState: coordinatesState?.origin?.provinceState,
  //       city: coordinatesState?.origin?.city,
  //       country: coordinatesState?.origin?.country,
  //       postalCode: coordinatesState?.origin?.postalCode,
  //     });
  //   }

  //   if (
  //     !coordinatesState?.destination?.isInitialData &&
  //     !selectedDropoffAddressValues?.isPreFilled
  //   ) {
  //     setSelectedDropoffAddressValues({
  //       addressLine1: coordinatesState?.destination?.addressLine1,
  //       addressLine2: coordinatesState?.destination?.addressLine2,
  //       provinceState: coordinatesState?.destination?.provinceState,
  //       city: coordinatesState?.destination?.city,
  //       country: coordinatesState?.destination?.country,
  //       postalCode: coordinatesState?.destination?.postalCode,
  //     });
  //   }
  // }, [coordinatesState]);

  const setPickupAddressValues = (selectedSuggestion) => {
    handlePickupFieldsOnChange();
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedPickupProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedPickupProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("pickupAddress1", selectedSuggestion?.properties?.address_line1);
    setValue("pickupAddress2", selectedSuggestion?.properties?.address_line2);
    setValue("pickupCity", selectedSuggestion?.properties?.city);
    setValue("pickupPostal", selectedSuggestion?.properties?.postcode);
    setValue("pickupState", selectedPickupProvinceState);
    setValue("pickupCountry", selectedCountry);
    setValue("pickupLatitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("pickupLongitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const setPickupAddressNotes = (addressNotes) => {
    setValue("pickupAddressNotes", addressNotes);
  };

  const setDropoffAddressValues = (selectedSuggestion) => {
    handleDropoffFieldsOnChange();
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedDropoffProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedDropoffProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("dropoffAddress1", selectedSuggestion?.properties?.address_line1);
    setValue("dropoffAddress2", selectedSuggestion?.properties?.address_line2);
    setValue("dropoffCity", selectedSuggestion?.properties?.city);
    setValue("dropoffPostal", selectedSuggestion?.properties?.postcode);
    setValue("dropoffState", selectedDropoffProvinceState);
    setValue("dropoffCountry", selectedCountry);
    setValue("dropoffLatitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("dropoffLongitude", `${selectedSuggestion?.properties?.lon}`);
  };

  const setDropoffAddressNotes = (addressNotes) => {
    setValue("dropoffAddressNotes", addressNotes);
  };

  // Pickup Handlers
  const handleOverwritePickupCancel = () => {
    setOverwritePickupModal(false);
  };

  const handleOverwritePickupConfirm = () => {
    setCopyAddressPickupCheckedValue(true);
    fillPickupDetails(true);
    setOverwritePickupModal(false);
  };

  const handlePickupCheckboxOnChange = (value) => {
    if (value) {
      setOverwritePickupModal(true);
    } else {
      setCopyAddressPickupCheckedValue(value);
    }
  };

  const handlePickupFieldsOnChange = () => {
    setCopyAddressPickupCheckedValue(false);
  };

  // Dropoff Handlers

  const handleOverwriteDropoffCancel = () => {
    setOverwriteDropoffModal(false);
  };

  const handleOverwriteDropoffConfirm = () => {
    setCopyAddressDropoffCheckedValue(true);
    fillDropoffDetails(true);
    setOverwriteDropoffModal(false);
  };

  const handleDropoffCheckboxOnChange = (value) => {
    if (value) {
      setOverwriteDropoffModal(true);
    } else {
      setCopyAddressDropoffCheckedValue(value);
    }
  };

  const handleDropoffFieldsOnChange = () => {
    setCopyAddressDropoffCheckedValue(false);
  };

  const onTabChange = (e) => {
    setActiveTab(e);

    if (e == 0) {
      setCreateDriverModal(true);
    }
  };

  const confirmAddStop = (stopType) => {
    const newKey = `${tabItems.length}`;
    const newStop = {
      key: newKey,
      label: stopType === 1 ? "Pickup" : "Dropoff",
      children: (
        <PickDropForm
          stopType={stopType}
          formId={newKey}
          control={control}
          register={register}
          setValue={setValue}
        />
      ),
    };

    // Spread the existing tabItems array
    const updatedItems = [...tabItems];

    // Find the index of the item with key 0 (if it exists)
    const key0Index = updatedItems.findIndex((item) => item.key == 0);

    // If key 0 item exists, remove it from the array
    if (key0Index > -1) {
      updatedItems.splice(key0Index, 1);
    }

    // Add the new item at the end
    updatedItems.push(newStop);

    // Add the key 0 item back at the end
    if (key0Index > -1) {
      updatedItems.push(tabItems[key0Index]);
    }

    // Update the state with the modified array
    setTabItems(updatedItems);
    setActiveTab(newKey);
  };

  const cancelAddStop = () => {
    setActiveTab(`${tabItems.length - 1}`);
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (_.isNull(over)) {
      return;
    }

    if (active.id !== over?.id) {
      setTabItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <div className="row mb-3">
        <Tabs
          items={tabItems}
          onChange={onTabChange}
          activeKey={activeTab}
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext
                items={tabItems.map((i) => i.key)}
                strategy={horizontalListSortingStrategy}
              >
                <DefaultTabBar {...tabBarProps}>
                  {(node) =>
                    node.key === "0" ? (
                      <NonDraggableTabNode {...node.props} key={node.key}>
                        {node}
                      </NonDraggableTabNode>
                    ) : (
                      <DraggableTabNode {...node.props} key={node.key}>
                        {node}
                      </DraggableTabNode>
                    )
                  }
                </DefaultTabBar>
              </SortableContext>
            </DndContext>
          )}
        />
      </div>

      {/* Order Instructions */}

      <div className="col-12 mb-2">
        <label className="form-label">Instructions</label>
      </div>

      <div className="col-12">
        <textarea
          className="form-control"
          id="instructions"
          rows="3"
          {...register("instructions")}
          maxLength={250}
          tabIndex={19}
          disabled={checkPageEditable()}
        />
        <small className="form-error-message">
          {errors?.pickupStartDate && errors.pickupStartDate.message}
        </small>
      </div>

      <ConfirmationModal
        isOpen={overwritePickupModal}
        toggle={handleOverwritePickupCancel}
        onClick={handleOverwritePickupConfirm}
        headerText={WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.headerText}
        bodyText={WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.bodyTextShipperDetails}
        confirmButtonText={
          WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.confirmButtonText
        }
        cancelButtonText={
          WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.cancelButtonText
        }
        actionType="delete"
      />

      <ConfirmationModal
        isOpen={overwriteDropoffModal}
        toggle={handleOverwriteDropoffCancel}
        onClick={handleOverwriteDropoffConfirm}
        headerText={WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.headerText}
        bodyText={WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.bodyTextReceiverDetails}
        confirmButtonText={
          WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.confirmButtonText
        }
        cancelButtonText={
          WARNING_MODAL_OVERWRITE_DETAILS_TEXTS.cancelButtonText
        }
        actionType="delete"
      />

      <AddPickDropModal
        modal={createDriverModal}
        setModal={setCreateDriverModal}
        toggle={createDriverToggle}
        confirmAddStop={confirmAddStop}
        cancelAddStop={cancelAddStop}
      />
    </>
  );
};

export default OrderDetails;
