import { get, post, patch } from "./apiService";

export const fetchUsers = async () => {

  return get({ path: 'User' });
}

export const fetchUsersFiltered = async (accountId) => {
  return get({ path: `User/filtered` });
}

export const fetchUsersDropdown = async () => {
  return get({ path: `User/filtered` });
}

export const fetchUserByEmail = async (email) => {
  return get({ path: `User/getAuthUser/${email}` });
}

export const addUser = async (payload, keepAlive = false) => {
  return post({ path: 'User', body: payload, keepAlive: keepAlive });
}

export const updateUser = async (payload, keepAlive = false) => {
  return patch({ path: `User/update/${payload.userId}`, body: payload, keepAlive: keepAlive });
}