import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchTrucksByAccount } from "../../services/truckServices";
import { useDispatch, useSelector } from "react-redux";
import { setTrucksListData } from "../../redux/truck/truckReducer";
import TruckForm from "./TruckForm";
import { enumValueFormatter } from "../../utils/formatUtils";
import {
  ENGINE_TYPES,
  OWNERSHIP_STATUS_TYPES,
  TRANSMISSION_TYPES,
  VEHICLE_CLASSES,
  VEHICLE_TYPES,
} from "../../constants";
import { GRID_CONSTANTS } from "../../constants";
import moment from "moment";
import NoData from "../Commons/Layouts/NoData";
import LoadingPage from "../Commons/Authorization/LoadingPage";

function Trucks() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const truckState = useSelector((state) => state.truck);

  const toggleFormDisplay = (isEdit = false, truckId = null) => {
    if (isFormHidden) {
      setSelectedTruckId(truckId);
    } else {
      setSelectedTruckId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      if (!isFormHidden && truckList?.length > 0) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "VIN",
      minWidth: 150,
      cellClass: "grid-column",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Make",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Model",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Year",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Vehicle Type",
      minWidth: 150,
      cellClass: "grid-column gray",
      valueFormatter: (params) => enumValueFormatter(params, VEHICLE_TYPES),
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Vehicle Class",
      minWidth: 200,
      cellClass: "grid-column gray",
      valueFormatter: (params) => enumValueFormatter(params, VEHICLE_CLASSES),
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Axles",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Truck Length",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Truck Width",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Truck Height",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "GVW",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Payload Capacity",
      minWidth: 150,
      cellClass: "grid-column gray",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      resizable: false,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [truckList, setTruckList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState({});
  const [selectedTruckId, setSelectedTruckId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setTruckList(truckState.data);
  }, [truckState.data]);

  useEffect(() => {
    let data = [];
    _.each(truckList, (truck, index) => {
      data.push({
        "#": index + 1,
        "Truck ID": truck.truckId,
        VIN: truck.vin,
        Make: truck.make,
        Model: truck.model,
        Year: moment(truck.year).format("yyyy"),
        "Ownership Status": truck.ownershipStatus,
        "Vehicle Type": truck.vehicleType,
        "Vehicle Class": truck.vehicleClass,
        Axles: truck.axles,
        "Engine Type": truck.engineType,
        "Fuel Capacity": truck.fuelCapacity,
        Displacement: truck.displacement,
        "Horse Power": truck.horsepower,
        Torque: truck.torque,
        "Engine Spec": truck.engineSpec,
        Transmission: truck.transmissionType,
        "Truck Length": truck.truckLength,
        "Truck Width": truck.truckWidth,
        "Truck Height": truck.truckHeight,
        GVW: truck.gvw,
        "Tare Weight": truck.tareWeight,
        "Payload Capacity": truck.payloadCapacity,
        "Vehicle Features": truck.vehicleFeatures,
        Edit: { id: truck.truckId, btnClass: "btn-color-7" },
      });
    });

    setRowData(data);
  }, [truckList]);

  useEffect(() => {
    fillSelectedTruck(selectedTruckId);
  }, [selectedTruckId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getTrucksData();
    dispatch(setTrucksListData(response));

    setIsLoading(false);
  };

  const getTrucksData = async () => {
    return await fetchTrucksByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedTruck = (id) => {
    const truck = _.find(truckList, { truckId: id });
    setSelectedTruck(truck);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (isFormHidden) {
      params.api.sizeColumnsToFit();
    }
  };

  const focusOnOpenOrCloseButton = () => {
    if (!isLoading) {
      openOrCloseButtonRef.current.focus();
    }
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <div>
      <div className="row mb-0">
        <div className="col-12">
          <ContentHeader
            title={
              isFormHidden ? "Trucks" : isFormEdit ? "Edit Truck" : "New Truck"
            }
            subtitle={"trucks"}
            dataCount={truckList.length}
            filterData={filterData}
            onClickAdd={toggleFormCancelDisplay}
            onClickCancel={toggleFormCancelDisplay}
            isFormHidden={isFormHidden}
            openOrCloseButtonRef={openOrCloseButtonRef}
          />
        </div>
      </div>
      <div className="content-body-container row mt-3">

        <div className={`${isFormHidden ? "col-12" : "d-none"} mb-2`}>
          {(truckList?.length > 0) ?
            <div
              className={`ag-theme-alpine content-section-container color-7-grid color-7-section p-0`}
            >
              <AgGridReact
                className="no-header"
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={GRID_CONSTANTS.ROW_HEIGHT}
              ></AgGridReact>
            </div>
            :
            <NoData color='color-7' content='truck' />
          }
        </div>
        <div className={`col-12 ${isFormHidden ? "d-none" : ""}`}>
          <TruckForm
            isFormEdit={isFormEdit}
            selectedTruck={selectedTruck}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Trucks;
