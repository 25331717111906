import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { selectStyle } from "../../utils/styleUtils";
import { ConfirmationModal } from "../Commons/Modals/ConfirmationModal";
import { PatternFormat } from "react-number-format";
import { PROVINCE_STATE, COUNTRY, TAX_TYPES } from '../../constants';
import { isSuperAdmin, isAccountAdmin } from "../../utils/roleUtils";
import { TimePicker, Select } from "antd";
import AutocompleteAddressForm from "../Commons/Forms/AutocompleteAddressForm";
import moment from "moment";
import * as dayjs from "dayjs";


function PrimaryDetails({
  isFormEdit,
  isFormHidden,
  setIsFormDirty,
  selectedAccount = {},
  control,
  register,
  errors,
  setValue,
  reset,
  provinceStateRef
}) {

  const { Option } = Select;

  const today = dayjs();

  const userType = useSelector((state) => state.auth.user.userType);
  const [defaultTax, setDefaultTax] = useState("");
  const [selectedAccountAddressValues, setSelectedAccountAddressValues] =
    useState({});

  useEffect(() => {
    if (!_.isEmpty(selectedAccount)) {
      const provinceOrState = PROVINCE_STATE.find(
        (provinceState) =>
          provinceState.value === selectedAccount["provinceState"]
      );
      setDefaultTax(parseInt(selectedAccount["defaultTaxCode"]));
      setValue("provinceState", provinceOrState);
      setValue("defaultTaxCode", parseInt(selectedAccount["defaultTaxCode"]));
      setValue("active", selectedAccount["active"]);
      setValue("phone", selectedAccount["phone"]);
      setValue("fax", selectedAccount["fax"]);
      setValue("cell", selectedAccount["cell"]);
      setValue("notes", selectedAccount["notes"]);
      setValue("addressLine2", selectedAccount["addressLine2"]);
      setValue("postalCode", selectedAccount["postalCode"]);
      setValue("country", parseInt(selectedAccount["country"]));

      setValue(
        "openingHour",
        dayjs(
          `${dayjs().format("YYYY-MM-DD")}T${selectedAccount.openingHour || "08:00:00"
          }`
        )
      );
      setValue(
        "closingHour",
        dayjs(
          `${dayjs().format("YYYY-MM-DD")}T${selectedAccount.closingHour || "17:00:00"
          }`
        )
      );

      setSelectedAccountAddressValues({
        addressLine1: selectedAccount.addressLine1,
        addressLine2: selectedAccount.addressLine2,
        provinceState: selectedAccount.provinceState,
        city: selectedAccount.city,
        country: selectedAccount.country,
        postalCode: selectedAccount.postalCode,
        addressNotes: selectedAccount.notes,
      });
    } else {
      setSelectedAccountAddressValues({
        addressLine1: "",
        addressLine2: "",
        provinceState: "",
        city: "",
        country: "",
        postalCode: "",
        addressNotes: "",
      });
      setDefaultTax("");
      reset();

      setValue(
        "openingHour",
        dayjs(
          `${dayjs().format("YYYY-MM-DD")}T08:00:00`
        )
      );
      setValue(
        "closingHour",
        dayjs(
          `${dayjs().format("YYYY-MM-DD")}T${"17:00:00"}`
        )
      );
    }
  }, [selectedAccount["accountId"]]);

  const getActiveDefaultValue = () => {
    if (selectedAccount.length <= 0) {
      return "true";
    }

    return selectedAccount.active ? "true" : "";
  };

  const setAddressValues = (selectedSuggestion) => {
    const firstUSState = PROVINCE_STATE.find(
      (provinceState) => provinceState.label === "Alabama"
    ).value;

    const selectedShipperProvinceState = _.find(
      PROVINCE_STATE,
      (provinceState) => {
        return provinceState.label === selectedSuggestion?.properties?.state;
      }
    );

    const selectedCountry = _.find(COUNTRY, (country) => {
      return (
        country.value ===
        (selectedShipperProvinceState?.value >= firstUSState ? 2 : 1)
      );
    });

    setValue("addressLine1", selectedSuggestion?.properties?.address_line1);
    setValue("addressLine2", selectedSuggestion?.properties?.address_line2);
    setValue("city", selectedSuggestion?.properties?.city);
    setValue("postalCode", selectedSuggestion?.properties?.postcode);
    setValue("provinceState", selectedShipperProvinceState);
    setValue("country", selectedCountry?.value);
    setValue("latitude", `${selectedSuggestion?.properties?.lat}`);
    setValue("longitude", `${selectedSuggestion?.properties?.lon}`);
  };

  return (
    <>
      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="name" className="form-label">
          Name <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          tabIndex={1}
          defaultValue={isFormEdit ? selectedAccount?.accountName || "" : ""}
          autoComplete="on"
          {...register("accountName", { required: "Name is required" })}
        />
        <small className="form-error-message">
          {errors?.accountName && errors.accountName.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="primaryContact" className="form-label">
          Primary Contact <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="primaryContact"
          tabIndex={2}
          defaultValue={
            isFormEdit ? selectedAccount?.primaryContact || "" : ""
          }
          autoComplete="on"
          {...register("primaryContact", {
            required: "Primary Contract is required",
          })}
        />
        <small className="form-error-message">
          {errors?.primaryContact && errors.primaryContact.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultEmail" className="form-label">
          Default Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="defaultEmail"
          className="form-control"
          id="defaultEmail"
          tabIndex={3}
          defaultValue={isFormEdit ? selectedAccount?.defaultEmail || "" : ""}
          autoComplete="on"
          {...register("defaultEmail", {
            required: "defaultEmail is required",
          })}
        />
        <small className="form-error-message">
          {errors?.defaultEmail && errors.defaultEmail.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="billingEmail" className="form-label">
          Billing Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="billingEmail"
          className="form-control"
          id="billingEmail"
          tabIndex={4}
          defaultValue={isFormEdit ? selectedAccount?.billingEmail || "" : ""}
          autoComplete="on"
          {...register("billingEmail", {
            required: "Billing Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.billingEmail && errors.billingEmail.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="notificationEmail" className="form-label">
          Notification Email <span className="required-asterisk">*</span>
        </label>
        <input
          type="notificationEmail"
          className="form-control"
          id="notificationEmail"
          tabIndex={5}
          defaultValue={
            isFormEdit ? selectedAccount?.notificationEmail || "" : ""
          }
          autoComplete="on"
          {...register("notificationEmail", {
            required: "Notification Email is required",
          })}
        />
        <small className="form-error-message">
          {errors?.notificationEmail && errors.notificationEmail.message}
        </small>
      </div>

      {/* <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultTaxCode" className="form-label">
          Default Tax Code: <span className="required-asterisk">*</span>
        </label>
        <select
          className="form-select"
          value={defaultTax}
          tabIndex={6}
          id="defaultTaxCode"
          {...register("defaultTaxCode", {
            valueAsNumber: true,
            onChange: (e) => setDefaultTax(e.target.value),
            required: "Default Tax Code is required",
          })}
        >
          <option key="none" value="" hidden>
            Default Tax Code
          </option>
          {TAX_TYPES.map((tax, index) => (
            <option key={index} value={tax.value}>
              {tax.label}
            </option>
          ))}
        </select>
        <small className="form-error-message">
          {errors?.defaultTaxCode && errors.defaultTaxCode.message}
        </small>
      </div> */}

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="defaultTaxCode" className="form-label">
          Default Tax Code: <span className="required-asterisk">*</span>
        </label>
        <div>
          <Controller
            control={control}
            name="defaultTaxCode"
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (

              <Select
                value={value}
                tabIndex={6}
                id="defaultTaxCode"
                onChange={onChange}
              >
                <Option key="none" value="" hidden>
                  Default Tax Code
                </Option>
                {TAX_TYPES.map((tax, index) => (
                  <Option key={index} value={tax.value}>
                    {tax.label}
                  </Option>
                ))}
              </Select>
            )}
          />
          <small className="form-error-message">
            {errors?.defaultTaxCode && errors.defaultTaxCode.message}
          </small>
        </div>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="taxId" className="form-label">
          Tax ID: <span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="taxId"
          tabIndex={7}
          defaultValue={isFormEdit ? selectedAccount?.taxId || "" : ""}
          {...register("taxId", { required: "Tax ID is required" })}
        />
        <small className="form-error-message">
          {errors?.taxId && errors.taxId.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="phone" className="form-label">
          Phone <span className="required-asterisk">*</span>
        </label>
        <Controller
          control={control}
          name="phone"
          register={register}
          rules={{ required: "Phone is required" }}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              value={value === undefined ? "" : value}
              onChange={onChange}
              onBlur={onBlur}
              getInputRef={ref}
              className="form-control"
              tabIndex={8}
              format="(###) ###-####"
              id="phone"
              mask="_"
              allowEmptyFormatting
              autoComplete="on"
            />
          )}
        />
        <small className="form-error-message">
          {errors?.phone && errors.phone.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="fax" className="form-label">
          Fax
        </label>
        <Controller
          control={control}
          name="fax"
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedAccount?.fax || "" : ""}
              value={isFormEdit ? selectedAccount?.fax || "" : ""}
              onChange={onChange}
              tabIndex={9}
              onBlur={onBlur}
              className="form-control"
              format="(###) ###-####"
              id="fax"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className="form-error-message">
          {errors?.fax && errors.fax.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label">
          Mobile
        </label>
        <Controller
          control={control}
          name="cell"
          register={register}
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <PatternFormat
              defaultValue={isFormEdit ? selectedAccount?.cell || "" : ""}
              value={isFormEdit ? selectedAccount?.cell || "" : ""}
              onChange={onChange}
              tabIndex={10}
              onBlur={onBlur}
              className="form-control"
              format="(###) ###-####"
              id="mobile"
              mask="_"
              allowEmptyFormatting
            />
          )}
        />
        <small className="form-error-message">
          {errors?.cell && errors.cell.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="openingHour" className="form-label">
          Opening Hour
        </label>
        <div>
          <Controller
            control={control}
            name="openingHour"
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <TimePicker
                className="antd-time-picker"
                format="H:mm"
                name={name}
                tabIndex={11}
                use12Hours={false}
                value={value}
                onChange={onChange}
                onSelect={onChange}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.openingHour && errors.openingHour.message}
        </small>
      </div>

      <div className="col-6 col-sm-4 mb-2">
        <label htmlFor="closingHour" className="form-label">
          Closing Hour
        </label>
        <div>
          <Controller
            control={control}
            name="closingHour"
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <TimePicker
                className="antd-time-picker"
                name={name}
                tabIndex={12}
                format="H:mm"
                value={value}
                onChange={onChange}
                onSelect={onChange}
              />
            )}
          />
        </div>
        <small className="form-error-message">
          {errors?.closingHour && errors.closingHour.message}
        </small>
      </div>

      <div className="col-12 p-0 mb-3">
        <hr></hr>
      </div>

      <AutocompleteAddressForm
        setAddressValues={setAddressValues}
        selectedOrderAddressValues={selectedAccountAddressValues}
      />

      {
        (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
        <div className="col-12 mb-2 mt-3">
          <div className="form-check">
            <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={14}
              defaultChecked={getActiveDefaultValue}
              {...register("active")}
            />
            <label className="form-check-label align-middle " htmlFor="active"> Active</label>
            <small className='form-error-message'>
              {errors?.active && errors.active.message}
            </small>
          </div>
        </div>
      }
    </>
  );
}

export default PrimaryDetails;
