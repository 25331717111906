import { get, post, patch, remove } from "./apiService";

export const fetchImageSettings = async (accountId) => {
  return get({ path: `ImageSettings` });
}

export const addImageSettings = async (payload, keepAlive = false) => {
  return post({ path: 'ImageSettings', body: payload, keepAlive: keepAlive });
}

export const updateImageSettings = async (payload, keepAlive = false) => {
  return patch({ path: `ImageSettings/update/${payload.imageSettingsId}`, body: payload, keepAlive: keepAlive });
}

export const deleteImageSetting = async (imageSettingsId) => {
  return remove({ path: `ImageSettings/${imageSettingsId}` });
}