import { get, post, patch } from "./apiService";

export const fetchAccountingSettings = async (accountId, setIsFormEdit) => {
  return get({ path: `AccountingSettings` }).catch(e => {
    setIsFormEdit(false);
  });
}

export const addAccountingSettings = async (payload, keepAlive = false) => {
  return post({ path: 'AccountingSettings', body: payload, keepAlive: keepAlive });
}

export const updateAccountingSettings = async (payload, keepAlive = false) => {
  return patch({ path: `AccountingSettings/update/${payload.accountingSettingsId}`, body: payload, keepAlive: keepAlive });
}